import React, { useEffect, useCallback, useState, useRef } from "react";
import t from "../../Actions/ParkingPermitActions/types";
import { Card, Container, Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  cancelWaitListedPermit,
  getUserWaitListPriorityPermit,
  getWaitListPermit,
  updateDriverWaitPriorityOrder,
} from "../../Actions/ParkingPermitActions/actions";
import { isEmpty } from "lodash";
import CustomContentAlert, { findBySearch, scroolToTop, tableHeightScrollBars, utcToSpecificTimeZone } from "../Helper/DesignHelper";
import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";
import { getUserProfileDetails, getLoginDetails } from "../../selectors/LoginDetails";
import { getAPIResponse } from "../../selectors/APIResponseReducer";

import DatatableHeader from "../DatatableHeader/DatatableHeader";
import Loader from "../Loader/Loader";
import PropertDetails from "../PropertyDetail/PropertySelection";
import useProperty from "../../../hooks/property";
import ConfirmationModal from "../Common/ConfirmationModal";
import DriverWaitlistPriorityModel from "../Modal/DriverWaitlistPriorityModel";

export const ParkingPermitWaitlist = () => {
  const dispatch = useDispatch();
  const { properties } = useProperty();
  const [searchValue, setSearchValue] = useState("");
  const [filtered, setFiltered] = useState("");
  const [formData, setFormData] = useState({
    showDriverPriorityModal: false,
    show_cancel_modal: false,
    selectedPermitId: "",
    isCancel: false,
    show_error: false,
  });

  const columns = useRef([]);

  const [finalTableHeight, setFinalTableHeight] = useState(0);
  const [childcheckbox, setChildcheckbox] = useState(true);
  const { api_response, api_success_message, api_error_message } = useSelector(getAPIResponse);
  console.log("api_response:::::::: waitlist", api_response, api_success_message, api_error_message);
  const { waitListPermit, loader } = useSelector(getParkingPermitDetail);
  console.log("waitListPermit::::::::::::;111111111111", waitListPermit);
  const { userProfileDetails, login_info } = useSelector(getLoginDetails);
  const userProfileDetail = useSelector(getUserProfileDetails);
  const [selectedPropertyObj, setselectedPropertyObj] = useState({
    value: login_info?.id,
    label: login_info?.property_name,
  });
  const user_id = userProfileDetails?.id;
  const timeZone = userProfileDetail?.timezone;

  console.log("user_id:::: waitlist::::::", user_id);
  const customStyles = useRef({
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  });

  const ShowCell = useCallback((row, _, column) => {
    const renderTooltipCell = (value, tooltipId) =>
      value ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={<Tooltip id={tooltipId}>{value}</Tooltip>}>
          <div className="text-truncate text-nowrap pr-3">{value}</div>
        </OverlayTrigger>
      ) : (
        "-"
      );
    const renderTimeTooltipCell = (time, tooltipId) =>
      time ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={<Tooltip id={tooltipId}>{utcToSpecificTimeZone(timeZone, time)}</Tooltip>}>
          <div className="text-truncate text-nowrap pr-3">{utcToSpecificTimeZone(timeZone, time)}</div>
        </OverlayTrigger>
      ) : (
        "-"
      );

    const renderClickableTooltipCell = (value, tooltipId, onClick) =>
      value ? (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={<Tooltip id={tooltipId}>{value}</Tooltip>}>
          <div className="curserPointer text-truncate text-nowrap pr-3" onClick={onClick}>
            {value}
          </div>
        </OverlayTrigger>
      ) : (
        ""
      );

    const renderContent = () => {
      switch (column.name) {
        case "Domain":
          return renderTooltipCell(row?.domain, `tooltip-domain`);
        case "LPN":
          return renderTooltipCell(row?.lpn, `tooltip-domain`);
        case "LOT":
          return renderTooltipCell(row?.lot_name, `tooltip-lot`);
        case "Package Name":
          return renderTooltipCell(row?.package_name, `tooltip-package-name`);
        case "Cancel":
          return cancelButton(row);
        case "Auto Renew":
          return autoRenewButton(row);
        case "Permit Class":
          return renderTooltipCell(row?.permit_class, `tooltip-permit-class`);
        case "Wait List Position":
          return renderTooltipCell(row?.waitlist_order, `tooltip-wait-order`);
        case "Preference Order":
          return renderClickableTooltipCell(row?.priority_order, `tooltip-priority-order`, () => showDriverPriorityFormModel(row));
        case "Unit Id":
          return renderTooltipCell(row?.suite_id, `tooltip-unit-id`);
        case "Created At":
          return renderTimeTooltipCell(row.created_at, `tooltip-created-at`);
        default:
          return null;
      }
    };
    return <CellDisplay data={renderContent()} />;
  }, []);

  useEffect(() => {
    if (api_response) {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: true,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_alert: false,
        }));
      }, 4000);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        show_error: true,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_error: false,
        }));
      }, 4000);
    }
  }, [api_response, api_error_message]);

  useEffect(() => {
    const init = async () => {
      const propertyId = selectedPropertyObj.value;
      setselectedPropertyObj({
        value: login_info?.id,
        label: login_info?.property_name,
      });
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: true,
      });
      const waitlistData = {
        childcheckbox,
        user_id,
        property_id: propertyId,
        type: "driver",
      };
      dispatch(getWaitListPermit(waitlistData));
      columns.current = [
        {
          name: "Domain",
          sortable: true,
          minWidth: "120px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.domain,
        },
        {
          name: "LPN",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.lpn,
        },
        {
          name: "LOT",
          minWidth: "140px",
          maxWidth: "250px",
          sortable: true,
          cell: ShowCell,
          selector: (row) => row?.lot_name,
        },
        {
          name: "Package Name",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.package_name,
        },
        {
          name: "Cancel",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.id,
        },
        {
          name: "Auto Renew",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.auto_renew,
        },
        {
          name: "Permit Class",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.permit_class,
        },
        {
          name: "Wait List Position",
          sortable: true,
          minWidth: "180px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.waitlist_order,
        },
        {
          name: "Preference Order",
          sortable: true,
          minWidth: "180px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.priority_order,
        },
        {
          name: "Unit Id",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row.suite_id,
        },
        {
          name: "Created At",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row.created_at,
        },
      ];
    };
    init();
  }, [user_id]);

  const cancelButton = (row) => {
    if (row.active && row.permit_type === "monthly_permits") {
      return (
        <>
          <Button className="btn-fill text-nowrap" variant="danger" size="xs" onClick={() => handleShowCancelPermitModal(row)}>
            {"Cancel"}
          </Button>
        </>
      );
    } else {
      return "-";
    }
  };

  const autoRenewButton = (row) => {
    if (row.active && row.permit_type === "monthly_permits") {
      return (
        <>
          <div>
            <input
              type="checkbox"
              checked={row?.auto_renew}
              onChange={() => {
                handleAutoRenewActivateDeactivate(row);
              }}
            />
          </div>
        </>
      );
    } else {
      return "-";
    }
  };

  const showDriverPriorityFormModel = (row) => {
    if (!formData.showDriverPriorityModal && row) {
      const userWaitlistData = {
        property_id: row.property_id,
        user_id,
        lpn: row.lpn,
      };
      dispatch(getUserWaitListPriorityPermit(userWaitlistData));
    }

    setFormData({
      ...formData,
      showDriverPriorityModal: !formData.showDriverPriorityModal,
    });
  };

  const updateDriverPriorityOrder = (data) => {
    const propertyId = selectedPropertyObj.value;
    const waitlistData = {
      childcheckbox,
      user_id,
      property_id: propertyId,
      type: "driver",
    };
    dispatch(updateDriverWaitPriorityOrder(data, waitlistData));
    setFormData({
      ...formData,
      showDriverPriorityModal: !formData.showDriverPriorityModal,
    });
  };

  const handleAutoRenewActivateDeactivate = async (row) => {
    const propertyId = selectedPropertyObj.value;
    const waitlistData = {
      childcheckbox,
      user_id,
      property_id: propertyId,
      type: "driver",
    };
    dispatch(cancelWaitListedPermit(row.id, "driver", "auto_renew", waitlistData));
  };

  const handleShowCancelPermitModal = (row) => {
    console.log("handleShowCancelPermitModal data::::::::::", row);
    setFormData({
      ...formData,
      show_cancel_modal: !formData.show_cancel_modal,
      selectedPermitId: row.id,
    });
  };

  const handleCancelPermit = async () => {
    const propertyId = selectedPropertyObj.value;
    setFormData({
      ...formData,
      isCancel: true,
      show_cancel_modal: !formData.show_cancel_modal,
    });
    const waitlistData = {
      childcheckbox,
      user_id,
      property_id: propertyId,
      type: "driver",
    };
    dispatch(cancelWaitListedPermit(formData.selectedPermitId, "driver", "cancel", waitlistData));
  };

  const handleSearch = async () => {
    const propertyId = selectedPropertyObj.value;
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    const waitlistData = {
      childcheckbox,
      user_id,
      property_id: propertyId,
      type: "driver",
    };
    dispatch(getWaitListPermit(waitlistData));

    document.body.classList.remove("foc-open");
  };

  const handleSearchChange = (e) => {
    const val = e?.target?.value?.toString().trim();
    setSearchValue(val);
    !isEmpty(val) && search(val);
  };

  const search = (val) => {
    const columnList = ["created_at", "domain", "lpn", "waitlist_order", "priority_order", "lot_name", "package_name"];
    const filteredValue = findBySearch(val, waitListPermit, waitListPermit, columnList, timeZone);
    setFiltered([...filteredValue]);
  };

  useEffect(() => {
    const height = tableHeightScrollBars();
    setFinalTableHeight(height);
  }, []);

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const getPaginationRows = (searchData, report, filter) => {
    let pageLength = report.length;
    if (searchData?.length) {
      pageLength = filter.length;
    }
    return pageLength;
  };

  const getTableData = (searchValueData, reportData, filterValue) => {
    let data = reportData;
    if (searchValueData?.length) {
      data = filterValue;
    }
    return data;
  };
  return (
    <div>
      <Container fluid className="manage-unit-list-page p-0">
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Body className="pos-rel-overflow-hide pb-0">
                <>
                  <div className="sidebarFilter">
                    <Card className="contact-container mb-0">
                      <Card.Header style={{}}>
                        <div className="d-flex align-items-center justify-content-between">
                          <Card.Title as="h4">Waitlist Of Permits</Card.Title>
                          <a
                            onClick={hideSlider}
                            // href="javascript:void(0);"
                            className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </a>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={childcheckbox}
                                onChange={() => {
                                  setChildcheckbox(!childcheckbox);
                                }}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails
                            selectedPropertyObj={selectedPropertyObj}
                            changeProperty={(e) => {
                              setselectedPropertyObj(e);
                            }}
                            properties={properties}
                          />
                        </Col>
                      </Card.Body>
                      <Card.Footer className="text-right justify-content-end d-flex">
                        <Button variant="info" className="h-40 btn-fill btn-sm" onClick={handleSearch} disabled={loader}>
                          Search
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                  <header>
                    <div className="active-permit-header flex-column-sm sm-align-items-start">
                      <h5 className="mb-2 mb-md-0">WaitListed Permits</h5>
                      <div className="filter-button d-flex flex-wrap">
                        <div className="d-flex align-items-center search-mb-0">
                          <DatatableHeader
                            className="mt-0 mb-0"
                            // Header={"List of Units"}
                            searchValue={searchValue}
                            HandleChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>
                  </header>
                </>
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    {
                      <>
                        {/* <SRLWrapper options={options}> */}
                        <div className="citation-list-table verify-audit-table">
                          <DataTable
                            data={getTableData(searchValue, waitListPermit, filtered)}
                            columns={columns.current}
                            customStyles={customStyles.current}
                            responsive={true}
                            defaultSortAsc={true}
                            fixedHeader={true}
                            onChangePage={scroolToTop}
                            fixedHeaderScrollHeight={finalTableHeight}
                            paginationPerPage="100"
                            paginationTotalRows={getPaginationRows(searchValue, waitListPermit, filtered)}
                            pagination={true}
                            paginationRowsPerPageOptions={[100, 200, 300, 400]}
                          />
                        </div>
                        {/* </SRLWrapper> */}
                      </>
                    }
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {formData.show_cancel_modal ? (
        <ConfirmationModal
          onOpen={formData.show_cancel_modal}
          onConfirm={handleCancelPermit}
          message="Are You sure you want to cancel permit?"
          onClose={handleShowCancelPermitModal}
          loading={false}
          title={"Cancel Permit"}
          isCanceldisabled={formData.isCancel}
        />
      ) : null}
      {formData.showDriverPriorityModal ? (
        <DriverWaitlistPriorityModel
          show={formData.showDriverPriorityModal}
          closeModal={() => showDriverPriorityFormModel()}
          updateDriverPriorityOrder={updateDriverPriorityOrder}
        />
      ) : null}
      {formData.show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
      {formData.show_error && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}{" "}
    </div>
  );
};

const CellDisplay = ({ data }) => {
  return <>{data}</>;
};
