import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

const PleaseWait = () => {
  return (
    <div className="login-container">
      <div className="login-card">
        <Card>
          <h2 className="title text-center"> Please wait...</h2>
          <Row>
            <Col md="12">
              <div className="info-container">
                <p>
                  <span>We are gathering property information</span>
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default PleaseWait;
const Card = styled.div`
  width: 100%;
`;
