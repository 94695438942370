import React, { useCallback, useEffect } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Form } from "react-bootstrap";
import emailIcon from "../../../assets/img/email.svg";
import nameIcon from "../../../assets/img/name.svg";
import phoneIcon from "../../../assets/img/phone.svg";
import selectlogIcon from "../../../assets/img/select-lot.svg";
import ssl from "../../../assets/img/ssl.svg";
import pci_dss from "../../../assets/img/pci-dss-compliant-logo-vector.svg";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { getAllDriverStall, getAllPermitOptionsByLots, getParkingPermitSetupDetails } from "../../Actions/ParkingPermitActions/actions";
import PropertDetails from "../../Components/PropertyDetail/PropertySelection";
import { getFinalUrlForParkingPermitAndCarwallet } from "../Helper/ValidationHelper";

const DriverPermitCreate = (props) => {
  const {
    handleChangeLot,
    handleChangePackage,
    handleChangeStall,
    editCardDetails,
    deleteCardDetails,
    mainOptions,
    showError,
    formData,
    handleChange,
    handleSelectLot,
    TogglePasswordVisibility,
    handleCalender,
    handleCheckBox,
    driver_credit_card,
    isPublic,
    handleDateChange,
    handleAddLpnCarDescription,
    handleLpnCarOptions,
    removeLpnCarOption,
    changeProperty,
    selectedProperties,
    selectedPropertyObj,
    lpn_btn_status,
    properties,
  } = props;
  const dispatch = useDispatch();
  const lot_package = useSelector((state) => state.ParkingPermitDetails.driver_package);
  const DriverStallData = useSelector((state) => state.ParkingPermitDetails.driver_stall_data);
  const ParkingPermitSetupDetail = useSelector((state) => state.ParkingPermitDetails?.ParkingPermitSetupDetail);

  const ParkingData = (ParkingPermitSetupDetail?.instruction?.split("\n") ?? []).map((item, index) => ({ id: index, value: item }));

  const final_url = getFinalUrlForParkingPermitAndCarwallet();

  useEffect(() => {
    if (isPublic && selectedProperties) {
      dispatch(getParkingPermitSetupDetails(selectedProperties));
    }
    return () => {
      dispatch({ type: "FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK" });
    };
  }, [selectedProperties]);

  const getPermitMonthAndYearAndAmount = useCallback(
    (max_amount, daily_amount) => {
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      const startDate = formData.startDate ? new Date(formData.startDate) : new Date();
      const month_name = month[startDate.getMonth()];
      const currentYear = startDate.getFullYear();
      // today date minus one day
      if (!formData.startDate) {
        startDate.setDate(startDate.getDate() - 1);
      }
      const start = formData.startDate ? new Date(formData.startDate) : new Date();
      // lst day of month plus one day
      const endDate = new Date(start.getFullYear(), start.getMonth() + 1, 1);
      const firstDay = moment(startDate);
      const lastDay = moment(endDate);
      const days = lastDay.diff(firstDay, "days");
      let amount = "";
      const monthlyCostOfPermit = days * daily_amount;
      if (monthlyCostOfPermit > max_amount) {
        amount = max_amount;
      } else {
        amount = monthlyCostOfPermit;
      }
      return { amount, month_name, currentYear };
    },
    [formData.startDate],
  );

  React.useEffect(() => {
    if (window.location.href.split("?").length > 1) {
      handleSelectLot(window.location.href.split("?")[window.location.href.split("?").length - 1]);
    }
  }, []);

  useEffect(() => {
    if (selectedProperties && formData.lot_id && mainOptions?.length > 1) {
      dispatch(getAllPermitOptionsByLots(selectedProperties, formData.lot_id, isPublic));
    }
  }, [selectedProperties, formData.lot_id, mainOptions]);

  useEffect(() => {
    if (selectedProperties && formData.lot_id && formData.package_name && formData.stall_type === "user_selected") {
      // we need to fetch all available stall's regarding that lot
      dispatch(getAllDriverStall(selectedProperties, formData.lot_id, isPublic));
    }
  }, [selectedProperties, formData.lot_id, formData.package_name, formData.stall_type]);

  const addZeroes = (num) => {
    let dec;
    if (!Number.isInteger(num)) {
      dec = parseFloat(num).toFixed(2);
      return dec;
    }
    return num;
  };

  const handleLotSelection = () => {
    if (mainOptions?.length === 1 && selectedPropertyObj?.value) {
      return (
        <Form.Label className="mb-0 fs-6" value={mainOptions[0].lot_id}>
          {mainOptions[0].alias}
        </Form.Label>
      );
    } else {
      return (
        <>
          <Form.Control as="select" name="lot_id" value={formData.lot_id} onChange={handleChangeLot} placeholder="Select Parking Area">
            <option value="">--Select Parking Area--</option>

            {mainOptions && selectedPropertyObj.value ? (
              <>
                {(mainOptions ?? []).map((item) => (
                  <option key={item?.lot_id} value={item?.lot_id}>
                    {item.alias}
                  </option>
                ))}
              </>
            ) : null}
          </Form.Control>
          <span className="field-with-icon">
            <img src={selectlogIcon} width="18" />
          </span>
        </>
      );
    }
  };

  const handleLotSelectionCarWallet = () => {
    if (mainOptions?.length === 1 && selectedPropertyObj?.value) {
      return (
        <Form.Label className="mb-0 fs-6" value={mainOptions[0].lot_id}>
          {mainOptions[0].alias}
        </Form.Label>
      );
    } else {
      return (
        <>
          <Form.Control as="select" name="lot_id" value={formData.lot_id} onChange={handleChangeLot} placeholder="Select Car Type">
            <option value="">--Select Car Type--</option>

            {mainOptions && selectedPropertyObj.value ? (
              <>
                {(mainOptions ?? []).map((item) => (
                  <option key={item?.lot_id} value={item?.lot_id}>
                    {item.alias}
                  </option>
                ))}
              </>
            ) : null}
          </Form.Control>
          <span className="field-with-icon">
            <img src={selectlogIcon} width="18" />
          </span>
        </>
      );
    }
  };

  const handleExpireLabel = (expire_in_days) => {
    if (expire_in_days === "end_of_month") {
      return "End of Month";
    } else {
      return `${expire_in_days} Days`;
    }
  };

  return (
    <div>
      <Row>
        <Col lg={6} md={6} className="mb-2">
          <PropertDetails selectedPropertyObj={selectedPropertyObj} changeProperty={changeProperty} showError={showError} properties={properties} />
          {final_url === process.env.REACT_APP_PARKING_PERMIT_URL ? (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>
                Select Parking Area <span className="asterisk">*</span>
              </Form.Label>
              <div className="icon-grps position-relative">{handleLotSelection()}</div>
            </Form.Group>
          ) : (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>
                Select Car Type <span className="asterisk">*</span>
              </Form.Label>
              <div className="icon-grps position-relative">{handleLotSelectionCarWallet()}</div>
            </Form.Group>
          )}
          {showError("lot_id")}
        </Col>
        <Col lg={6} md={6} className="mt-4 mb-2">
          {selectedProperties &&
          formData?.package_name?.toString().length > 0 &&
          formData?.lot_id?.toString().length > 0 &&
          formData?.max_amount &&
          formData?.daily_amount ? (
            <>
              Monthly Rate: <strong>${addZeroes(formData.max_amount)}</strong>
              <br />
              Daily Amount: <strong>${addZeroes(formData.daily_amount)}</strong>
              <br />
              Rate for the rest of {getPermitMonthAndYearAndAmount(formData.max_amount, formData.daily_amount).month_name}{" "}
              {getPermitMonthAndYearAndAmount(formData.max_amount, formData.daily_amount).currentYear}:{" "}
              <strong>${addZeroes(getPermitMonthAndYearAndAmount(formData.max_amount, formData.daily_amount).amount)}</strong>
            </>
          ) : (
            selectedProperties &&
            formData?.lot_id?.toString().length > 0 &&
            formData?.package_name?.toString().length > 0 &&
            formData?.cost &&
            formData?.usage_count &&
            formData?.expire_in_days && (
              <>
                Rate: <strong>${addZeroes(formData.cost)}</strong>
                <br />
                Expires: <strong>{handleExpireLabel(formData.expire_in_days)}</strong>
                <br />
                Usage: {formData?.usage_count}{" "}
              </>
            )
          )}
        </Col>
        <Col lg={12} md={12} className="mb-2">
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>
              Select Package <span className="asterisk">*</span>
            </Form.Label>
            <div className="icon-grps position-relative">
              <Form.Control as="select" name="package_name" value={formData.package_name} onChange={handleChangePackage} placeholder="Select Package">
                <option value="">--Select Package--</option>
                {lot_package && selectedPropertyObj.value ? (
                  <>
                    {(lot_package?.data ?? []).map((item) => (
                      <option key={item?.package_name} value={item?.package_name}>
                        {item.package_name}
                        {+item?.usage_count > 0 ? ` (Limited)` : " (Unlimited)"}
                      </option>
                    ))}
                  </>
                ) : null}
              </Form.Control>
              <span className="field-with-icon">
                <img src={selectlogIcon} width="18" />
              </span>
            </div>
          </Form.Group>

          {showError("package_name")}
        </Col>
        {selectedProperties &&
        formData?.lot_id?.toString().length > 0 &&
        formData?.package_name?.toString().length > 0 &&
        formData.stall_type === "user_selected" &&
        !formData.allow_waitlist ? (
          <Col lg={12} md={12} className="mb-2">
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label className="mb-0">Select Stall:</Form.Label>
              <Form.Control
                as="select"
                name="stall_id"
                value={formData.stall_id}
                onChange={handleChangeStall}
                placeholder="Select Stall"
                disabled={!DriverStallData?.length > 0}
              >
                <option value="">--Select Stall--</option>
                {DriverStallData?.length ? (
                  <>
                    {(DriverStallData ?? []).map((item) => (
                      <option key={item.id} id={item.id} value={item.stall_id}>
                        {item.stall_name}
                      </option>
                    ))}
                  </>
                ) : null}
              </Form.Control>
            </Form.Group>
            {showError("stall_id")}
            <label className="mt-2">{!DriverStallData?.length && "Note: There are no stall available for this lot"}</label>
          </Col>
        ) : (
          <></>
        )}

        {selectedProperties &&
        formData?.lot_id?.toString().length > 0 &&
        formData?.package_name?.toString().length > 0 &&
        +formData.active_permit_count >= +formData.max_permit_count &&
        formData.permit_type === "monthly_permits" &&
        formData.allow_waitlist ? (
          <Col lg={12} md={12} className="mb-2">
            <p>
              Sorry, this parking facility is currently full and we are not accepting parking permits applications at this time. If you would like to join the
              waiting list, please register by filling out the information below.{" "}
            </p>
            <p>
              Should a parking permit become available, and you are next on the waiting list, the purchase of the permit will be completed automatically. You
              will receive an email notification and charges will apply accordingly. If you are already a City of Kitchener parking customer at a different
              parking facility, please remember to cancel your existing permit.
            </p>
            <p>
              If you do not wish to accept the new permit, you can cancel at no charge within 24 hours.We recommend that you review your account and waiting
              list registrations on a regular basis. You can remove yourself from waiting lists at any time.
            </p>
          </Col>
        ) : (
          <></>
        )}
        <Col lg={6} md={6} className="mb-2">
          <Form.Group>
            <Form.Label>
              First Name <span className="asterisk">*</span>
            </Form.Label>
            <div className="icon-grps position-relative">
              <Form.Control type="text" name="driver_first_name" value={formData.driver_first_name} onChange={handleChange} autoComplete="off" />
              <span className="field-with-icon">
                <img src={nameIcon} width="18" />
              </span>
            </div>
          </Form.Group>
          {showError("driver_first_name")}
        </Col>
        <Col lg={6} md={6} className="mb-2">
          <Form.Group>
            <Form.Label>
              Last Name <span className="asterisk">*</span>
            </Form.Label>
            <div className="icon-grps position-relative">
              <Form.Control type="text" name="driver_last_name" value={formData.driver_last_name} onChange={handleChange} autoComplete="off" />
              <span className="field-with-icon">
                <img src={nameIcon} width="18" />
              </span>
            </div>
          </Form.Group>
          {showError("driver_last_name")}
        </Col>
        {isPublic ? (
          <Col lg={12} md={12} className="mb-2">
            <Form.Group>
              <Form.Label>
                Email
                <span className="asterisk">*</span>
              </Form.Label>
              <div className="icon-grps position-relative">
                <Form.Control required type="text" className="form-control pe-5" name="email" value={formData.email} onChange={handleChange} />
                {showError("email")}
                <span className="field-with-icon">
                  <img src={emailIcon} width="18" />
                </span>
              </div>
            </Form.Group>
          </Col>
        ) : (
          <Col lg={12} md={12} className="mb-2">
            <Form.Group>
              <Form.Label>
                Email
                <span className="asterisk">*</span>
              </Form.Label>
              <div className="icon-grps position-relative">
                <Form.Control required type="email" className="form-control pe-5" name="email" value={formData.email} onChange={handleChange} disabled={true} />
                {showError("email")}
                <span className="field-with-icon">
                  <img src={emailIcon} width="18" />
                </span>
              </div>
            </Form.Group>
          </Col>
        )}

        <br />
      </Row>
      <Row>
        <Col lg={12} md={12} className="d-flex">
          <div className="mb-2 me-3">
            <label className="text-nowrap">Country Code</label>
            <Form.Control as="select" name="country_code" onChange={handleChange} value={formData.country_code} required>
              {formData.country_code_list ? (
                <>
                  {(formData.country_code_list ?? []).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Control>
          </div>
          <div className="mb-2 col">
            {" "}
            <label>
              Phone
              <span className="asterisk">*</span>
            </label>
            <div className="icon-grps position-relative">
              <Form.Control
                placeholder="phone"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                autoComplete="off"
                required
                maxLength={10}
              />
              {showError("phone")}
              <span className="field-with-icon">
                <img src={phoneIcon} width="18" />
              </span>
            </div>
          </div>
        </Col>
        {isPublic && (
          <Col lg={12} md={12} className="mb-2">
            <Form.Group>
              <label>Create Password for future access</label>
              <div className="input-mask-btn">
                <Form.Control
                  type={formData.passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  autoComplete="new-password"
                />
                <button className="mask-password" type="button" onClick={TogglePasswordVisibility}>
                  <i className={`fas icon-color ${formData.passwordVisible ? "fa-eye" : "fa-eye-slash"}`} />
                </button>
              </div>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={4} md={4} className="mb-2">
          <Form.Group>
            <Form.Label>
              Licence Plate No. <span className="asterisk">*</span>
            </Form.Label>
            <Form.Control type="text" name="lpn" value={formData.lpn} onChange={handleChange} autoComplete="off" maxLength={11} />
          </Form.Group>
          {showError("lpn")}
        </Col>
        <Col lg={4} md={4} className="mb-2">
          <Form.Group>
            <Form.Label>Car Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Car Desription"
              name="car_desription"
              value={formData.car_desription}
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col lg={4} md={4} className="col-auto">
          <div>
            <label>&nbsp;</label>
          </div>
          <Button size="sm" className="h-40px" disabled={lpn_btn_status} onClick={handleAddLpnCarDescription}>
            Add additional plates
          </Button>
        </Col>
      </Row>
      {formData.lpn_car_options &&
        typeof formData.lpn_car_options === "object" &&
        Array.isArray(formData.lpn_car_options) &&
        (formData.lpn_car_options ?? []).map((option, index) => {
          return (
            <Row key={option?.country_code} className="">
              <Col lg={4} md={4} className="mb-2">
                <Form.Group>
                  <Form.Label>Licence Plate No. {index + 1}</Form.Label>
                  <span className="asterisk">*</span>
                  <Form.Control
                    className="w-85px"
                    type="text"
                    name="lpn_options"
                    onChange={(e) => handleLpnCarOptions(index, e, "lpn_options")}
                    value={option?.lpn}
                    maxLength="11"
                    required
                  ></Form.Control>
                  {showError("lpn_options")}
                </Form.Group>
              </Col>
              <Col lg={4} md={4} className="mb-2">
                <Form.Group className="p-0">
                  <Form.Label>Car Desription {index + 1}</Form.Label>
                  <Form.Control
                    placeholder="Car Description"
                    type="text"
                    name="car_description_options"
                    value={option?.car_desription}
                    onChange={(e) => handleLpnCarOptions(index, e, "car_description_options")}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={4} className="col-auto mt-4">
                <Button size="sm" className="h-40px" onClick={() => removeLpnCarOption(index)}>
                  Remove Plate
                </Button>
              </Col>
            </Row>
          );
        })}
      <Row>
        <Col lg={12} md={12} className="mt-3">
          {formData.lpn_car_options?.length > 0 && (
            <span>
              <b>Note: You can use only one LPN at a time. If you use more than one LPN, you will be overcharged.</b>
            </span>
          )}
        </Col>
      </Row>
      {!formData.startDateDisable && (
        <Col lg={12} md={12}>
          <Form.Group className="my-3">
            <label className="me-3">Start Date</label>
            <DatePicker
              className="form-control"
              placeholderText="Select Date"
              selected={formData.startDate}
              value={formData.startDate}
              selectsStart
              name="startDate"
              timeInputLabel="Time:"
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              minDate={new Date()}
              maxDate={new Date().setDate(new Date().getDate() + 9)}
              autoComplete="off"
              // disabled={formData.startDateDisable}
            />
          </Form.Group>
        </Col>
      )}
      {!formData.startDateDisable && (
        <>
          <Row>
            <Row>
              <Col lg={12} md={12} className="mt-2 mb-2">
                <label className="fancy-checkbox">
                  <input
                    type="checkbox"
                    name={"auto_renew"}
                    checked={formData.auto_renew}
                    onChange={handleCheckBox}
                    className="custom-control-input"
                    id="auto_renew1"
                    style={{ position: "relative", top: "2px" }}
                    // disabled={formData.startDateDisable}
                  ></input>
                  {/* <label className="ms-1">Auto Renew</label> */}
                  <span>
                    <i></i> Auto Renew at the start of each month or expires
                  </span>
                </label>
                <i className="fa fa-refresh ml-2" aria-hidden="true"></i>
              </Col>
            </Row>
          </Row>
          {!formData.lpn_car_options.length && +formData.active_permit_count < +formData.max_permit_count && (
            <Row>
              <Row>
                <Col lg={12} md={12} className="mt-2 mb-2">
                  <label className="fancy-checkbox">
                    <input
                      type="checkbox"
                      name={"auto_cancel"}
                      checked={formData.auto_cancel}
                      onChange={handleCheckBox}
                      className="custom-control-input"
                      id="auto_cancel1"
                      style={{ position: "relative", top: "2px" }}
                      // disabled={formData.startDateDisable}
                    ></input>
                    {/* <label className="ms-1">Auto Renew</label> */}
                    <span>
                      <i></i> Auto Cancel existing permit
                    </span>
                    <div className="mt-3">
                      <span>Note: If you select auto-cancel, it will be automatically canceled when the waitlist permit is cleared.</span>
                    </div>
                  </label>
                </Col>
              </Row>
            </Row>
          )}
        </>
      )}
      {console.log("driver_credit_card:", driver_credit_card, formData.isCardEdit)}
      {formData.isCardEdit ? (
        <Row>
          <div className="mb-2 mt-3 change-credit-card-box">
            <div className="mt-2">
              <Row>
                <Col lg="12">
                  <div className="form-group mb-3">
                    <label>
                      Card Holder Name
                      <span className="asterisk">*</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        required={true}
                        type="text"
                        className="form-control pe-5"
                        name="card_name"
                        value={formData.card_name || ""}
                        onChange={handleChange}
                        disabled={formData.disable_card_options}
                      />
                      <div className="payment-formicon">
                        <span className="fa fa-user" aria-hidden="true" />
                      </div>
                    </div>
                    {showError("card_name")}
                  </div>
                </Col>
              </Row>
              <div className="form-group mb-3">
                <label>
                  Credit Card number
                  <span className="asterisk">*</span>
                </label>
                <div className="d-flex align-items-center position-relative">
                  <input
                    type="text"
                    required={true}
                    className="form-control pe-5"
                    name="card_number"
                    value={formData.card_number}
                    onChange={handleChange}
                    maxLength={16}
                    disabled={formData.disable_card_options}
                  />
                  <div className="payment-formicon">
                    <span className="fa fa-credit-card" aria-hidden="true" />
                  </div>
                </div>
                {showError("card_number")}
              </div>

              <div className="form-group mb-2">
                <Row>
                  <Col lg="12">
                    <div className="form-group mb-3">
                      <label>
                        Expiry Date
                        <span className="asterisk">*</span>
                      </label>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          required={true}
                          className="form-control"
                          name="card_expiry"
                          value={formData.card_expiry}
                          onChange={handleChange}
                          disabled={formData.disable_card_options}
                        />
                        <div className="payment-formicon">
                          <span className="fa fa-calendar" style={{ cursor: "pointer" }} aria-hidden="true" />
                        </div>
                      </div>
                      {showError("card_expiry")}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3">
              <div>
                <Button className="btn-fill btn-sm mb-2" type="button" variant="info" onClick={() => editCardDetails()}>
                  <span className="pe-2">
                    <i className="far fa-edit"></i>
                  </span>
                  Edit Card
                </Button>
              </div>
              <div>
                <Button className="btn-fill btn-sm mb-2" type="button" variant="danger" onClick={() => deleteCardDetails()}>
                  <span className="pe-2">
                    <i className="far fa-trash-alt"></i>
                  </span>
                  Delete Card
                </Button>
              </div>
            </div>
          </div>
        </Row>
      ) : (
        <Row>
          <Col>
            <div className="mb-2 mt-3 change-credit-card-box">
              <div className="mt-2">
                <Row>
                  <Col lg="6">
                    <div className="form-group mb-3">
                      <label>
                        Card Holder Name
                        <span className="asterisk">*</span>
                      </label>
                      <div className="d-flex align-items-center position-relative">
                        <input required={true} type="text" className="form-control pe-5" name="card_name" value={formData.card_name} onChange={handleChange} />
                        <div className="payment-formicon">
                          <span className="fa fa-user" aria-hidden="true" />
                        </div>
                      </div>
                      {showError("card_name")}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="form-group mb-3">
                      <label>
                        Credit Card number
                        <span className="asterisk">*</span>
                      </label>
                      <div className="d-flex align-items-center position-relative">
                        <input
                          type="text"
                          required={true}
                          className="form-control pe-5"
                          name="card_number"
                          value={formData.card_number}
                          onChange={handleChange}
                          maxLength={16}
                        />
                        <div className="payment-formicon">
                          <span className="fa fa-credit-card" aria-hidden="true" />
                        </div>
                      </div>
                      {showError("card_number")}
                    </div>
                  </Col>
                </Row>

                <div className="form-group mb-4">
                  <Row>
                    <Col md="6" lg="6">
                      <div className="form-group mb-3">
                        <label>
                          Expiry Date
                          <span className="asterisk">*</span>
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <DatePicker
                            required
                            placeholderText="MM/YY"
                            // className="form-control datePickerPayment pe-5"
                            name="card_expiry"
                            selected={formData?.card_expiry}
                            onChange={handleCalender}
                            dateFormat="MM/yy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                            customInput={
                              <InputMask
                                mask="99/99"
                                maskChar={null}
                                // placeholder="MM/YY"
                                className="form-control datePickerPayment pe-5"
                                name="card_expiry"
                                value={formData?.card_expiry}
                                onChange={handleCalender}
                                // onChangeRaw
                                // showMask
                              />
                            }
                          ></DatePicker>
                          <div className="payment-formicon">
                            <span className="fa fa-calendar" style={{ cursor: "pointer" }} aria-hidden="true" />
                          </div>
                        </div>
                        {showError("card_expiry")}
                      </div>
                    </Col>
                    <Col md="6" lg="6">
                      <div className="form-group mb-3">
                        <label>
                          CVV
                          <span className="asterisk">*</span>
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <input
                            required={true}
                            type="password"
                            className="form-control pe-5"
                            name="card_cvv"
                            value={formData.card_cvv}
                            onChange={handleChange}
                            maxLength={4}
                          />
                          <div className="payment-formicon">
                            <span className="fa fa-key" aria-hidden="true" />
                          </div>
                        </div>
                        {showError("card_cvv")}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {isPublic && ParkingData?.length && (
        <>
          <div className="mb-2 mt-3 mr-3 change-credit-card-box">
            <label className="fancy-checkbox">
              <input
                type="checkbox"
                name={"terms_and_condition"}
                checked={formData.terms_and_condition}
                onChange={handleCheckBox}
                className="custom-control-input "
                id="terms_and_condition1"
                style={{ marginRight: "10px" }}
              ></input>
              <span>
                {" "}
                <i></i> I agree to the Terms and Conditions.{" "}
              </span>
            </label>

            <div className="d-flex justify-content-center align-items-center">
              <Row>
                <Col className="border-right">
                  <div className="text-left fs-25px">
                    {(ParkingData ?? []).map((item) => {
                      return (
                        <div key={item?.id}>
                          <span className="mb-1 mt-2" key={item.id}>
                            {item?.value}
                          </span>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {showError("terms_and_condition")}
        </>
      )}
      <div className="d-flex justify-content-center align-items-center">
        <img className="sslLogo" src={ssl} alt="" />
        <img className="pciLogo ml-2" src={pci_dss} alt="" />
      </div>
      {/* ) : (
        <p className="p-3">
          We are sorry, there are no permit packages available
        </p>
      )} */}
    </div>
  );
};

export default DriverPermitCreate;
