import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { isEmailValid } from "../Helper/ValidationHelper";

const SendInvoiceModal = ({ closeModal, ticketDataPdf, tital }) => {
  const [emailToSend, setemailToSend] = useState("");
  const [emailError, setemailError] = useState("");
  const [isMailFormValid, setisMailFormValid] = useState(false);

  const handleMailChange = (e) => {
    setemailToSend(e.target.value);

    if (!emailToSend.trim().length) {
      setemailError("Please Enter email!");
    }
    if (emailToSend.trim().length) {
      setemailError(!isEmailValid(emailToSend) ? "Email is invalid!" : "");
      setisMailFormValid(isEmailValid(emailToSend));
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      ticketDataPdf(event, emailToSend);
    }
  };

  return (
    <React.Fragment>
      <Modal
        id="ticketdetails"
        lg={"medium"}
        show={true}
        // onHide={closeModal}
        dialogClassName="modal-90w"
        fullscreen={"sm-down"}
        className="modalData"
      >
        <Modal.Header id="import" className="d-flex justify-content-between bg-white align-items-center">
          <h5 className="m-0 font-weight-bold">
            <Modal.Title className="m-0">{tital || "Send Mail as PDF"}</Modal.Title>
          </h5>
          <Button id="modal" title={"Ticket"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
            <i className="nc-icon nc-simple-remove text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form>
            <label>
              Email Address
              <span className="asterisk">*</span>
            </label>
            <Form.Control
              placeholder="example@gmail.com"
              type="text"
              name="email"
              value={emailToSend}
              onChange={handleMailChange}
              autoComplete="off"
              onKeyDown={handleEnterPress}
              required
            />
          </Form>
          <span className="help-block">{emailError}</span>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <Button disabled={!isMailFormValid} onClick={(e) => ticketDataPdf(e, emailToSend)}>
            Send Mail
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default SendInvoiceModal;
