import t from "../../Actions/LoginActions/types";

const initialState = {
  auth: 0,
  userProfileDetails: {},
  currect_link: "",
  backgroundColor: {},
  userPropertyData: [],
  login_status: false,
  error_message_for_subdomain_not_exist: "",
  is_sub_domain_not_exist: true,
  is_pay_loader: false,
};

const UserLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.USER_LOGIN_INFO: {
      return {
        ...state,
        login_info: action.payload,
      };
    }
    case t.USER_LOGIN: {
      return {
        ...state,
        auth: action.payload,
        login_status: true,
      };
    }
    case t.IS_PAY_LOADER: {
      return {
        ...state,
        is_pay_loader: action.payload,
      };
    }

    case t.SUBDOMIAN_NOT_EXIT_ERROR: {
      return {
        ...state,
        error_message_for_subdomain_not_exist: action.payload.message,
        currect_link: action.payload.link,
      };
    }

    case t.IS_SUBDOMIAN_NOT_EXIT: {
      return {
        ...state,
        is_sub_domain_not_exist: action.payload,
      };
    }

    case t.USER_PROFILE_INFO: {
      return {
        ...state,
        userProfileDetails: action.payload,
        login_status: true,
      };
    }
    case t.SETBACKGROUNDCOLOR: {
      return {
        ...state,
        backgroundColor: action.payload,
      };
    }
    case t.USER_LOGOUT_STATUS: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default UserLoginReducer;
