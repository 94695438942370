import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Navbars/ParkingNavbar";
import { Button, Card, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { UserProfileActions } from "../../Actions";
import { regExForPhone, isEmailValid, validations } from "../Helper/ValidationHelper";
import CustomContentAlert from "../Helper/DesignHelper";
import isEqual from "lodash/isEqual";
import ChangeProfile from "./ChangeProfile";
import ProfileBannerImg from "./../../../assets/img/photo-1431578500526-4d9613015464.jpeg";
import ProfilePicsImg from "./../../../assets/img/default-avatar.png";
import UpdateCreditCard from "./UpdateCreditCard";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      new_first_name: "",
      last_name: "",
      email: "",
      phone: "",
      suiteId: "",
      username_alias: "",
      user_type: "",
      admin_login: false,
      loader: false,
      newPassword: "",
      newPasswordLoader: false,
      wrongEmail: false,
      isFormValid: false,
      emptyPass: false,
      property: this.props.property,
      api_error_message: "",
      api_success_message: "",
      show_alert: false,
      show_error: false,
    };
  }

  componentDidMount() {
    const userInfo = this.props.UserProfileDetails;
    const data = {
      ...(userInfo && {
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        email: userInfo.email,
        phone: userInfo.phone,
        suiteId: userInfo.user,
        user_type: userInfo.user_type,
        admin_login: userInfo.admin_login,
        username: userInfo.username,
        username_alias: userInfo.username,
      }),
    };

    this.setState(data);
  }

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.UserProfileDetails, this.props.UserProfileDetails)) {
      const userInfo = this.props.UserProfileDetails;
      const data = {
        ...(userInfo && {
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          email: userInfo.email,
          phone: userInfo.phone,
          suiteId: userInfo.user,
          user_type: userInfo.user_type,
          username: userInfo.username,
          username_alias: userInfo.username,
        }),
      };
      this.setState(data);
    }
  };

  handleChange = ({ target }) => {
    const prevState = this.state.phone;
    const userInfo = this.props.UserProfileDetails;
    const data = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      email: userInfo.email,
      phone: userInfo.phone,
      username_alias: userInfo.user,
    };
    this.setState({ [target.name]: target.value }, async () => {
      switch (target.name) {
        case "username_alias": {
          const username_alias = validations(target.name, "username_alias", this.state.username_alias.replace(/\s/g, ""));
          this.setState({
            username_alias: username_alias.value,
          });
          break;
        }
        case "first_name": {
          const FirstName = validations(target.name, "first_name", this.state.first_name);
          this.setState({
            first_name: FirstName.value,
          });
          break;
        }
        case "last_name": {
          const LastName = validations(target.name, "last_name", this.state.last_name);
          this.setState({
            last_name: LastName.value,
          });

          break;
        }
        case "email": {
          const Emails = validations(target.name, "email", this.state.email.replace(/\s/g, ""));
          this.setState({
            email: Emails.value,
          });

          break;
        }
        case "phone": {
          const Phone = validations(target.name, "phone", this.state.phone);
          this.setState({
            phone: Phone.value,
          });

          break;
        }
        default:
          break;
      }
      if (this.state.first_name && this.state.last_name && this.state.email && this.state.phone && this.state.username_alias) {
        if (target.name === "phone") {
          if (regExForPhone.test(target.value)) {
            this.setState({ phone: target.value });
          } else {
            this.setState({ phone: prevState });
          }
        }
        if (
          this.state.first_name === data.first_name &&
          this.state.last_name === data.last_name &&
          this.state.email === data.email &&
          this.state.phone === data.phone &&
          this.state.username_alias === data.username_alias &&
          this.state.phone.length
        ) {
          this.setState(
            {
              isFormValid: false,
            },
            this.validateForm(),
          );
        } else {
          this.validateForm();
        }
      } else {
        this.setState({
          isFormValid: false,
        });
      }
    });
  };

  validateForm = () => {
    const { email, phone } = this.state;
    const isEmailValidate = isEmailValid(email);
    this.setState({ wrongEmail: !isEmailValidate }, () => {
      let isValidPhone = true;
      if ((phone && phone.length > 10) || (phone && phone.length < 10)) {
        this.setState({
          phoneErrorMessage: "Please enter valid phone number",
        });
        isValidPhone = false;
      } else {
        this.setState({
          phoneErrorMessage: "",
        });
      }
      if (isEmailValidate && this.state.phone.length && isValidPhone) {
        this.setState({ isFormValid: true });
      } else {
        this.setState({ isFormValid: false });
      }
    });
  };

  handleSubmit = async () => {
    const userInfo = this.props.UserProfileDetails;
    const { first_name, last_name, email, phone, username_alias: username } = this.state;
    const obj = {
      ...userInfo,
      first_name,
      last_name,
      email,
      phone,
      username,
      isProfile: true,
    };
    await this.props.userUpdate(obj);
    if (this.props.api_response) {
      this.setState(
        {
          show_alert: true,
          api_success_message: this.props.api_success_message,
        },
        () => {
          setTimeout(() => {
            this.setState({
              show_alert: false,
            });
          }, 4000);
        },
      );
    } else {
      const data = {
        ...(userInfo && {
          first_name: userInfo.first_name,
          last_name: userInfo.last_name,
          email: userInfo.email,
          phone: userInfo.phone,
          suiteId: userInfo.user,
          user_type: userInfo.user_type,
          username: userInfo.username,
          username_alias: userInfo.username,
        }),
      };
      this.setState(
        {
          show_error: true,
          api_error_message: this.props.api_error_message,
          ...data,
        },
        () => {
          setTimeout(() => {
            this.setState({ show_error: false });
          }, 4000);
        },
      );
    }

    this.setState({
      isFormValid: false,
    });
  };

  emailValidation = (email, wrongEmail) => {
    if (email && wrongEmail) {
      return <div className="help-block">Invalid Email</div>;
    } else if (!email) {
      return <div className="help-block">Email is required</div>;
    } else {
      return <div> </div>;
    }
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      phone,
      user_type,
      loader,
      wrongEmail,
      username_alias,
      api_success_message,
      api_error_message,
      show_alert,
      show_error,
    } = this.state;
    return (
      <>
        <Header />
        <div className="content pt-3 pb-3 h-100" style={{ backgroundColor: "rgb(239, 239, 241)" }}>
          <Container fluid>
            <a href="/parking-permit" className="btn-link ">
              <i class="fa fa-arrow-left me-2" aria-hidden="true"></i>
              Go to Parking Permit
            </a>
            <Row>
              <Col lg="4" className="order-lg-2">
                <Card className="card-user">
                  <div className="card-image">
                    <img src={ProfileBannerImg} alt="Profile Banner Img" />
                  </div>
                  <Card.Body>
                    <div className="author">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={ProfilePicsImg} className="avatar border-gray" alt="Profile Banner Img" />
                        {/* <h3>User Profile</h3> */}
                        <h5 className="title">{` ${first_name} ${last_name}`}</h5>
                      </a>
                      <p className="description d-flex align-items-center justify-content-center">
                        <i className="nc-icon nc-circle-09 font-weight-bold mr-1" />
                        {user_type}
                      </p>
                    </div>
                    <p className="description  d-flex align-items-center justify-content-center">
                      <i className="nc-icon nc-email-85 font-weight-bold mr-1" />
                      {email}
                    </p>
                    <p className="description  d-flex align-items-center justify-content-center">
                      <i className="nc-icon nc-mobile font-weight-bold mr-1" />
                      {phone}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="8">
                <Card>
                  <Card.Header style={{ backgroundColor: this.props.main_body }}>
                    <Card.Title as="h4">Edit Profile</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="form-group">
                            <label>
                              Username
                              <span className="asterisk">*</span>
                            </label>
                            <Form.Control
                              // defaultValue="Creative Code Inc."
                              placeholder="Username"
                              type="text"
                              name="username_alias"
                              value={username_alias}
                              autoComplete="off"
                              onChange={this.handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="form-group">
                            <label>
                              First Name<span className="asterisk">*</span>
                            </label>
                            <Form.Control
                              // defaultValue="Mike"
                              placeholder="First Name"
                              type="text"
                              value={first_name}
                              name="first_name"
                              required
                              onChange={this.handleChange}
                              autoComplete="off"
                            ></Form.Control>
                            {!first_name ? <div className="help-block">First Name is required</div> : null}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="form-group">
                            <label>
                              Last Name<span className="asterisk">*</span>
                            </label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder="Last Name"
                              type="text"
                              value={last_name}
                              name="last_name"
                              required
                              onChange={this.handleChange}
                              autoComplete="off"
                            ></Form.Control>
                            {!last_name ? <div className="help-block">Last Name is required</div> : null}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="form-group">
                            <label htmlFor="exampleInputEmail1">
                              Email address
                              {/* <span className="asterisk">*</span> */}
                            </label>
                            <Form.Control
                              placeholder="Email"
                              type="email"
                              value={email}
                              name="email"
                              disabled
                              onChange={this.handleChange}
                              autoComplete="off"
                            ></Form.Control>
                            {this.emailValidation(email, wrongEmail)}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="form-group">
                            <label>
                              Phone<span className="asterisk">*</span>
                            </label>
                            <Form.Control
                              placeholder="Phone no."
                              type="text"
                              value={phone}
                              name="phone"
                              required
                              onChange={this.handleChange}
                              autoComplete="off"
                              maxLength={10}
                            ></Form.Control>
                            {!phone ? <div className="help-block">Phone number is required</div> : <div> </div>}
                            <span className="help-block">{this.state.phoneErrorMessage}</span>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        {!loader ? (
                          <Button className="btn-fill" type="button" variant="info" onClick={this.handleSubmit} disabled={!this.state.isFormValid}>
                            Update Profile
                          </Button>
                        ) : (
                          <Spinner animation="border" variant="info" />
                        )}
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <UpdateCreditCard />
            <ChangeProfile />
          </Container>
        </div>
        <Footer />
        {show_alert && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        {show_error && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
      </>
    );
  }
}

const mapStateToProps = ({ UserProfileDetails, APIResponseDetails, Logindetails }) => {
  const { api_response, api_success_message, api_error_message } = APIResponseDetails;
  const { property } = UserProfileDetails;
  const { backgroundColor } = Logindetails;
  const { MainBackground: main_body } = backgroundColor;
  return {
    UserProfileDetails,
    api_response,
    api_success_message,
    api_error_message,
    property,
    main_body,
  };
};

const mapStateToDispatch = {
  userUpdate: UserProfileActions.userUpdate,
  resetPassword: UserProfileActions.resetPassword,
  userLogout: UserProfileActions.userLogout,
  // setDomainHeader: SuperAdminAction.setDomainHeader,
};

export default connect(mapStateToProps, mapStateToDispatch)(UserProfile);
