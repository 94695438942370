import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

const DomainNotExist = () => {
  const url = window.location.origin;
  const url_result = url.replace(/^https?:\/\//, "");
  const splitURL = url_result.split(".");
  const final_url = splitURL[0] + "." + splitURL[1];


  if (final_url === process.env.REACT_APP_PARKING_PERMIT_URL) {
    return (
      <div className="login-container">
        <div className="login-card">
          <Card>
            <h2 className="title text-center">
              <span>ParkingPermit Zone</span>
              <br />
              <span> ParkingPermit Zone Parking Ticket Management System</span>
            </h2>
            <Row>
              <Col md="12">
                <div className="info-container">
                  <p>
                    <span>
                      ParkingPermit Zone is a cloud based paid parking management system which allows parking lot owner and managers to issue parking
                      infractions. The system allows drivers to create paid permit.
                    </span>
                  </p>
                  <p className="mt-4">
                    <span>
                      In order to create paid permit, you must enter correct site name before parkingpermit.zone
                      <span>
                        <br />
                        i.e site.parkingpermit.zone
                      </span>
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  } else {
    return (
      <div className="login-container">
        <div className="login-card">
          <Card>
            <h2 className="title text-center">
              <span>Car Wallet</span>
              <br />
              <span> Car Wallet Ticket Management System</span>
            </h2>
            <Row>
              <Col md="12">
                <div className="info-container">
                  <p>
                    <span>
                      Car Wallet is a cloud based paid parking management system which allows parking lot owner and managers to issue parking infractions. The
                      system allows drivers to create paid permit.
                    </span>
                  </p>
                  <p className="mt-4">
                    <span>
                      In order to create paid permit, you must enter correct site name before carwallet.zone
                      <span>
                        <br />
                        i.e site.carwallet.zone
                      </span>
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
};
export default DomainNotExist;
const Card = styled.div`
  width: 100%;
`;
