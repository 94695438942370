import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import ConfirmPassword from "../Components/LoginPage/ConfirmPassword";
import LoginPage from "../Components/LoginPage/LoginPage";
import ParkingPermitPage from "../Components/ParkingPermitPage/ParkingPermitPage";
import DriverSignup from "../Components/DriverSignup/DriverSignup";
import UserProfile from "../Components/UserProfile/UserProfile";
import PrivateRoute from "./PrivateRoute";
import { getLoginDetails } from "../selectors/LoginDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router";

import {
  userLogin,
  getDriverLoginInfo,
} from "../Actions/UserProfileActions/actions";

const MainRoutes = () => {
  //
  const location = useLocation();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };
  const basicAuth = getCookie("driverbasicAuth");

  const getUserDetail = useSelector(getLoginDetails);
  const { is_sub_domain_not_exist } = getUserDetail;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      getUserDetail?.login_status === false &&
      basicAuth !== null &&
      (location.pathname !== "/login" || location.pathname !== "/signup")
    ) {
      dispatch(userLogin(null, basicAuth, true));
      if (location.pathname === "/parking-permit") {
        dispatch(getDriverLoginInfo());
      }
    }
  }, [getUserDetail?.login_status]);

  return (
    <div className="App d-flex flex-column h-100">
      <Routes>
        <Route path="/signup" element={<DriverSignup />} />
        {getUserDetail?.login_status === false &&
          !Object.keys(getUserDetail?.userProfileDetails).length &&
          !getUserDetail?.auth ? (
          <>
            {(location.pathname === "/parking-permit" ||
              location.pathname === "/user-profile") &&
              basicAuth === null ? (
              <>
                <Route
                  path="/parking-permit"
                  element={<Navigate to="/signup" />}
                />
                <Route
                  path="/user-profile"
                  element={<Navigate to="/signup" />}
                />
              </>
            ) : (
              <>
                {!is_sub_domain_not_exist ? (
                  <>
                    <Route
                      path="/parking-permit"
                      element={<Navigate to="/signup" />}
                    />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/newpassword" element={<ConfirmPassword />} />
                  </>
                ) : (
                  <>
                    {" "}
                    <Route path="/" element={<Navigate to="/signup" />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/newpassword" element={<ConfirmPassword />} />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Route
              path="/parking-permit"
              element={
                <PrivateRoute>
                  <ParkingPermitPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-profile"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Navigate replace to="/parking-permit" />
                </PrivateRoute>
              }
            />
          </>
        )}
      </Routes>
    </div>
  );
};

export default MainRoutes;
