import t from "../../Actions/UserProfileActions/types";

const initialState = {
  data: [],
  // backgroundColor: {},
  LoginDetails: {},
};

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.LOGIN_USER: {
      return {
        ...state,
        LoginDetails: action.payload,
      };
    }
    case t.FETCH_USERPROFILE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case t.LOGOUT_USER: {
      return {};
    }
    default:
      return state;
  }
};

export default UserProfileReducer;
