import React from "react";

function Footer() {
  return (
    <div className="fixed-bottom">
      <footer className="footer d-flex justify-content-between align-items-center py-2 px-3"></footer>
    </div>
  );
}

export default Footer;
