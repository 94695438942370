import React from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";

const PropertDetails = ({ selectedPropertyObj, changeProperty, showError, properties }) => {
  console.log("property data on selection:---", selectedPropertyObj, properties);

  const allProperties_ = useSelector((state) => state.ParkingPermitDetails.getAllProperties);
  const allProperties = allProperties_?.length > 0 ? allProperties_.filter((id) => id.is_archived === false) : [];

  const getParentPropertyName = React.useCallback(
    (property) => {
      const parentProperty = allProperties.find((a) => a.id === property.property_id);
      if (parentProperty) {
        if (parentProperty.property_id && property.property_id !== parentProperty.property_id) {
          return `${property.site_name} - ${getParentPropertyName(parentProperty)}`;
        } else {
          return `${property.site_name} - ${parentProperty.site_name}`;
        }
      }
      return property.site_name;
    },
    [allProperties],
  );
  const Properties = React.useMemo(() => {
    return (allProperties ?? [])
      .map((a) => ({ ...a, modified_site_name: getParentPropertyName(a) }))
      .map((a) => ({ value: a.id, label: a.modified_site_name }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [allProperties]);

  const PropertyOptions = properties ? properties : Properties;

  return (
    <Form.Group>
      <label>
        Select Property
        <span className="asterisk">*</span>
      </label>
      {PropertyOptions?.length === 1 ? (
        <Form.Label className="mb-0 d-block fs-6" value={PropertyOptions[0]?.value}>
          {PropertyOptions[0]?.label}
        </Form.Label>
      ) : (
        <Select
          value={selectedPropertyObj}
          onChange={changeProperty}
          options={PropertyOptions}
          placeholder="Select property"
          selectedValue={PropertyOptions?.length === 1 ? PropertyOptions[0] : selectedPropertyObj}
        />
      )}

      {showError ? showError("property_id") : ""}
    </Form.Group>
  );
};

export default PropertDetails;
