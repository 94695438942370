import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const DeleteCardModal = (props) => {
  const {
    onOpen,
    onClose,
    message = "",
    onConfirm,
    isDeleteCarddisabled,
    loading,
  } = props;
  console.log("onOpen::::::::::::1", onOpen);
  return (
    <Modal lg={"medium"} show={onOpen} onHide={onClose}>
      <Modal.Header className="no-border">
        <h4 className="m-0 font-weight-bold">{"Delete Card"}</h4>
        <Button
          title={"Delete Card"}
          type="button"
          onClick={onClose}
          className="close-btn"
          data-dismiss="modal"
        >
          <i className="nc-icon nc-simple-remove"></i>
        </Button>
      </Modal.Header>

      <Modal.Body className="modal-data-body">
        <p className="theme-color">{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ minWidth: "93px" }}
          onClick={onClose}
          type="button"
          className="btn btn-danger btn-cancel"
        >
          No
        </Button>
        {!loading ? (
          <Button
            onClick={onConfirm}
            type="button"
            className="btn btn-fill btn-info"
            disabled={isDeleteCarddisabled}
          >
            Yes
          </Button>
        ) : (
          <Spinner animation="border" variant="info" />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCardModal;
