import t from "./types";
import apiType from "../APIResponseActions/type";
import loginType from "../LoginActions/types";

import { AuthAPI, BufferAuthAPI, UnAuthAPI } from "../../../Auth/requestHandler";
import Config from "../../../Auth/Config";
import { userLogin } from "../UserProfileActions/actions";

export const getReceiptPDF = (row) => async (dispatch) => {
  try {
    console.log("check this::::::::::::::::::,getReceiptPDF", row);
    const url = "get_parking_pdf/" + row.property_id + "/" + row.id + "/" + row.language;
    return await new Promise((resolve) => {
      fetch(`${Config.baseURL}/${url}`)
        .then((blobData) => blobData.blob())
        .then((response) => {
          console.log("==== response", response);
          resolve(URL.createObjectURL(response));
        });
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const pdfSendToMail = (sendMailData) => async (dispatch) => {
  try {
    const url = "permit_options/send-receipt-mail";
    const body = sendMailData;
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("Mail send Response success::::::", response);
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: response.message,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message,
    });
  }
};

export const getAllLotPermitList = (property_id, is_public) => async (dispatch) => {
  try {
    let response;
    const url = "permit_options/get-driver-lot/" + property_id;
    if (property_id) {
      if (is_public) {
        response = await UnAuthAPI(url, "GET");
      } else {
        response = await await AuthAPI("domain", url, "GET");
      }
    }

    if (response) {
      await dispatch({
        type: t.FETCH_DRIVER_LOT,
        payload: response,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const getAllDriverStall = (property_id, lot_id, is_public) => async (dispatch) => {
  try {
    let response;
    const url = `permit_options/get-stall/${property_id}/${lot_id}`;
    if (property_id && lot_id) {
      if (is_public) {
        response = await UnAuthAPI(url, "GET");
      } else {
        response = await AuthAPI("domain", url, "GET");
      }
    }

    if (response) {
      await dispatch({
        type: t.FETCH_DRIVER_STALL,
        payload: response,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const get_all_properties = (property_id, isPublic, type) => async (dispatch) => {
  try {
    let url;
    let result;
    if (isPublic) {
      if (property_id) {
        url = "property/driverfindallproperty/" + property_id + "/" + type;
        result = await UnAuthAPI(url, "GET");
      }
    } else {
      result = await AuthAPI("domain", "property", "GET");
    }

    await dispatch({ type: t.GETALLPROPERTY, payload: result });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};

export const getAllPermitOptionsByLots = (property_id, lot_id, isPublic) => async (dispatch) => {
  try {
    let response;
    const url = `permit_options/get-driver-packages/${property_id}/${lot_id}`;
    if (isPublic) {
      response = await UnAuthAPI(url, "GET");
    } else {
      response = await AuthAPI("domain", url, "GET");
    }

    if (response) {
      await dispatch({
        type: t.FETCH_DRIVER_PACKAGE,
        payload: response,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const getParkingPermitSetupDetails = (property_id) => async (dispatch) => {
  try {
    const url = "permit/parkingpermit-terms-details/" + property_id;
    const response = await UnAuthAPI(url, "GET");

    if (response.status === "failed") {
      dispatch({
        type: t.FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK,
        payload: {},
      });
    } else {
      await dispatch({
        type: t.FETCH_PARKING_PERMIT_SETTING_DETAILS,
        payload: response,
      });
    }
  } catch (error) {
    dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
    dispatch({
      type: t.FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK,
      payload: {},
    });
  }
};

export const createDriverPaidPermit = (data, pageObj) => async (dispatch) => {
  const body = {
    end_time: data.end_time,
    max_amount: data.max_amount,
    cost: data?.cost,
    usage_count: data?.usage_count,
    one_usage_per_day: data?.one_usage_per_day,
    expire_in_days: data?.expire_in_days,
    max_permit_count: data.max_permit_count,
    daily_amount: data.daily_amount,
    user_id: data.user_id,
    first_name: data.first_name,
    last_name: data.last_name,
    country_code: data.country_code ? data.country_code : "+1",
    phone: data.phone,
    password: data.password ? data.password : "",
    email: data.email,
    lot_id: data.lot_id,
    package_name: data.package_name,
    lpn: data.lpn,
    lpn_options: data.lpn_car_options,
    role: "Visitor",
    permit_class: "PermitPackage",
    is_card_edit: data.is_card_edit,
    property_id: data.property_id,
    car_description: data.car_description,
    paid_permit_option_id: data.paid_permit_option_id,
    permit_type: data.permit_type,
    stall_type: data?.stall_type,
    stall_id: data?.stall_id,
    original_stall_id: data?.original_stall_id,
    active_permit_count: data.active_permit_count,
    allow_waitlist: data.allow_waitlist,
    auto_renew: data.auto_renew,
    auto_cancel: data.auto_cancel,
    startDate: data.startDate,
    card_details: {
      cc_number: data.card_number,
      card_expiry: data.card_expiry,
      cvv: data.card_cvv,
      name: data.card_name,
      email: data.email,
    },
  };
  try {
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    const response = await AuthAPI("domain", "permit_options/add-permits-by-driver", "POST", body);
    if (response?.success === 1) {
      await dispatch({
        type: t.CREATE_DRIVER_PAID_PERMIT,
        payload: response,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
      if (data.active_permit_count >= data.max_permit_count && data.allow_waitlist) {
        const waitlistData = {
          childcheckbox: pageObj.childcheckbox,
          user_id: data.user_id,
          property_id: data.property_id,
          type: "driver",
        };
        dispatch(getWaitListPermit(waitlistData));
      } else {
        dispatch(fetchCurrentPermits(pageObj));
      }
    } else {
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: false,
      });

      dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const createDriverSignup = (data) => async (dispatch) => {
  let signUpData;
  let response;
  let url, login_data;

  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    signUpData = {
      country_code: data.country_code ? data.country_code : "+1",
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      password: data.password,
      phone: data.phone,
      property_id: data.property_id,
      user_type: "driver",
      username: data.email,
      terms_and_condition: data.terms_and_condition,
      ...data,
    };

    url = "user/driver-signup";
    response = await UnAuthAPI(url, "POST", signUpData);
    if (response?.data) {
      login_data = response.data;
      const authTocken = "";
      const loginObj = {
        username: login_data.username,
        password: login_data.password,
      };
      if (login_data.username.trim().length && login_data.password.trim().length) {
        dispatch(userLogin(loginObj, authTocken, false));
      }
      await dispatch({
        type: loginType.IS_PAY_LOADER,
        payload: true,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const getDriverCreditCardList = (user_id, property_id) => async (dispatch) => {
  try {
    const response = await AuthAPI("domain", `permit_options/get-driver-credit-card/${user_id}/${property_id}`, "GET");
    if (response) {
      await dispatch({
        type: t.FETCH_DRIVER_CARD_DETAIL,
        payload: response.data,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const DeleteCreditCardDetails = (card_id) => async (dispatch) => {
  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    const url = `permit_options/delete-credit-card/${card_id}`;

    const response = await AuthAPI("domain", url, "DELETE");
    console.log("Driver card delete response::::", response);
    if (response?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const saveCreditCardDetails = (data) => async (dispatch) => {
  console.log("card save data on actions::::", data);
  try {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: "",
    });
    await dispatch({
      type: apiType.API_RESPONSE_SUCCESS,
      payload: "",
    });
    const url = `permit_options/save-credit-card`;

    const response = await AuthAPI("domain", url, "POST", data);
    console.log("Driver card save response::::", response);
    if (response?.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (err) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: err.message || "The server did not respond, Please try again after sometime",
    });
  }
};

export const fetchCurrentPermits = (pageobj) => async (dispatch) => {
  try {
    let url = "";
    const unix_time = Math.round(new Date().getTime() / 1000);
    const currentDate = unix_time - 14400;
    const exportPemrits = !!pageobj?.exportLink;
    url =
      "permit?permit_type=" +
      pageobj.permitType +
      "&status=" +
      pageobj.status +
      "&suite_id=" +
      pageobj.suite_id +
      "&page_size=" +
      pageobj.Norow +
      "&page=" +
      pageobj.page +
      "&search=" +
      pageobj.searchValue +
      "&order_by=" +
      pageobj.selectedColumn +
      "&sorting=" +
      pageobj.sort_direction +
      "&current_time=" +
      currentDate +
      "&exportLink=" +
      exportPemrits +
      "&childcheckbox=" +
      pageobj.childcheckbox +
      "&propertyId=" +
      pageobj.PropertyId;
    if (exportPemrits) {
      try {
        return await BufferAuthAPI("domain", url, "GET");
      } catch (error) {
        await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
      }
    } else {
      const data = await AuthAPI("domain", url, "GET");
      await dispatch({ type: t.FETCH_CURRENT_PERMIT, payload: data });
      await dispatch({ type: apiType.API_RESPONSE_SUCCESS, payload: "" });
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: false,
      });
    }
    await dispatch({
      type: loginType.IS_PAY_LOADER,
      payload: false,
    });
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const updatePermit = (permitId, body, PageObject) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
  await dispatch({
    type: t.UPDATE_PERMIT_ERROR,
    payload: "",
  });
  try {
    const resource = "permit/" + permitId;
    body.id = permitId;

    const response = await AuthAPI("domain", resource, "PATCH", body);
    await dispatch({ type: t.UPDATE_PERMIT, payload: body });
    if (response.success === 1) {
      dispatch({
        type: t.UPDATE_PERMIT_SUCCESS,
        payload: response.message,
      });
      await dispatch({
        type: t.FETCH_CURRENT_PERMIT,
        payload: response.data,
      });
      dispatch(fetchCurrentPermits(PageObject));
    } else {
      await dispatch({
        type: t.UPDATE_PERMIT_ERROR,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const activateDeactivateAutoRenewForPermit = (row) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  try {
    const url = "permit/update-auto-renew-status";
    const response = await AuthAPI("domain", url, "POST", row);
    if (response?.success) {
      await dispatch({
        type: t.AUTO_RENEW_ACTIVATE_DEACTIVATE,
        payload: row.id,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const activateDeactivateAutoCancelForPermit = (row) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_FAILURE,
    payload: "",
  });
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  try {
    const url = "permit/update-auto-cancel-status";
    const response = await AuthAPI("domain", url, "POST", row);
    if (response?.success) {
      await dispatch({
        type: t.AUTO_CANCEL_ACTIVATE_DEACTIVATE,
        payload: row.id,
      });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const cancelAndRefundPaidPermit =
  (permitId, pageObj, based_on_days, amount = 0) =>
  async (dispatch) => {
    try {
      const url = `permit/cancel-and-refund-permit/${permitId}/${based_on_days}/${amount}`;
      const response = await AuthAPI("domain", url, "GET");
      if (response.success === 1 || response.success === 2) {
        await dispatch({
          type: t.UPDATE_CURRENT_PERMIT,
          payload: permitId,
        });
        dispatch(fetchCurrentPermits(pageObj));
        await dispatch({
          type: apiType.API_RESPONSE_SUCCESS,
          payload: response.message,
        });
      } else {
        await dispatch({
          type: apiType.API_RESPONSE_FAILURE,
          payload: response.message,
        });
      }
    } catch (error) {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: error.toString(),
      });
    }
  };

export const cancelWaitListedPermit = (selectedPermitId, sent_from, type, data) => async (dispatch) => {
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  const body = {
    id: selectedPermitId,
    sent_from,
    type,
  };
  try {
    let url;
    if (selectedPermitId) {
      url = `permit/waitlist/autorenew-cancel`;
    }
    const response = await AuthAPI("domain", url, "POST", body);
    if (response) {
      dispatch(getWaitListPermit(data));
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getHistoryPermit = (userId, childcheckbox, propertyId) => async (dispatch) => {
  try {
    let url;
    if (userId) {
      url = `permit_options/paid_permit_history/${userId}/${childcheckbox}/${propertyId}`;
    }
    const response = await AuthAPI("domain", url, "GET");
    if (response) {
      await dispatch({ type: t.GET_PAID_PARMIT_HISTORY, payload: response });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.msg,
      });
    } else {
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getWaitListPermit = (data) => async (dispatch) => {
  try {
    const body = {
      childcheckbox: data.childcheckbox,
      user_id: data.user_id,
      property_id: data.property_id,
      type: data.type,
    };
    let url;
    if (data.user_id) {
      url = `permit/waitlist`;
    }
    const response = await AuthAPI("domain", url, "POST", body);
    if (response) {
      await dispatch({ type: t.GET_WAITLIST_PERMITS, payload: response.data });
    } else {
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};
export const updateDriverWaitPriorityOrder = (data, waitlist_data) => async (dispatch) => {
  console.log("data:::::::", data);
  await dispatch({
    type: apiType.API_RESPONSE_SUCCESS,
    payload: "",
  });
  const body = {
    waitListPriorityData: data,
  };
  try {
    const url = `permit/waitlist/manage-priority-order`;
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("response:::::1111", response);
    if (response.success) {
      dispatch(getWaitListPermit(waitlist_data));
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.error("error ::::: ", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getUserWaitListPriorityPermit = (data) => async (dispatch) => {
  const body = {
    user_id: data.user_id,
    property_id: data.property_id,
    lpn: data.lpn,
  };
  console.log("body", body);
  try {
    let url;
    if (data.user_id) {
      url = `permit/waitlist/user-priority-order`;
    }
    const response = await AuthAPI("domain", url, "POST", body);
    console.log("response:::::1111", response);
    if (response) {
      await dispatch({ type: t.GET_USER_WAITLIST_PRIORITY, payload: response.data });
    } else {
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    console.error("error ::::: ", error);
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};
