const UserActionType = {
  FETCH_USERPROFILE: "FETCH_USERPROFILE",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  CHANGE_SUITE_ID: "CHANGE_SUITE_ID",
  GET_LOGIN_INFO: "GET_LOGIN_INFO",
  FETCH_LINK: "FETCH_LINK",
  SETBACKGROUNDCOLOR: "SETBACKGROUNDCOLOR",
};

export default UserActionType;
