import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";

import "../src/assets/scss/lbd/pages/_register.scss";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import reduxStore from "./App/redux_store";
import Loader from "./App/Components/Loader/Loader";

import { PersistGate } from "redux-persist/integration/react";
import "./assets/css/demo.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

if (process.env.REACT_APP_FRONT_ENV === "local") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_FRONT_ENV === "dev") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_FRONT_ENV === "live") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_FRONT_ENV === "ca") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={reduxStore.store}>
    <PersistGate loading={<Loader />} persistor={reduxStore.persistor}>
      {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
