const types = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_INFO: "USER_LOGIN_INFO",
  USER_PROFILE_INFO: "USER_PROFILE_INFO",
  USER_LOGOUT_STATUS: "USER_LOGOUT_STATUS",
  SUBDOMIAN_NOT_EXIT_ERROR: "SUBDOMIAN_NOT_EXIT_ERROR",
  IS_SUBDOMIAN_NOT_EXIT: "IS_SUBDOMIAN_NOT_EXIT",
  IS_PAY_LOADER: "IS_PAY_LOADER",
};

export default types;
