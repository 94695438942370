import React, { useEffect, useCallback } from "react";
import t from "../../Actions/ParkingPermitActions/types";
import { Card, Container, Row, Col, Tooltip, OverlayTrigger, Button, Modal, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { getHistoryPermit, getReceiptPDF, pdfSendToMail } from "../../Actions/ParkingPermitActions/actions";
import { isEmpty } from "lodash";
import CustomContentAlert, { findBySearch, scroolToTop, tableHeightScrollBars, utcToSpecificTimeZone } from "../Helper/DesignHelper";
import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";
import { getUserProfileDetails, getLoginDetails } from "../../selectors/LoginDetails";
import { getAPIResponse } from "../../selectors/APIResponseReducer";

import DatatableHeader from "../DatatableHeader/DatatableHeader";
import ModalLoader from "../Loader/ModalLoader";
import Loader from "../Loader/Loader";
import PropertDetails from "../PropertyDetail/PropertySelection";
import { isMobile } from "react-device-detect";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import SendInvoiceModal from "../Modal/SendInvoiceModal";
import useProperty from "../../../hooks/property";

export const ParkingPermitHistoryPermit = () => {
  const dispatch = useDispatch();
  const { properties } = useProperty();

  const [detectedDevice, setDetectedDevice] = React.useState("");

  const [searchValue, setSearchValue] = React.useState("");
  const [filtered, setFiltered] = React.useState("");
  const [finalTableHeight, setFinalTableHeight] = React.useState(0);
  const [childcheckbox, setChildcheckbox] = React.useState(true);

  const [showReceiptModal, setshowReceiptModal] = React.useState(false);
  const [pdfLoader, setpdfLoader] = React.useState(false);
  const [pdfUrl, setpdfUrl] = React.useState("");
  const [selectedReceiptId, setselectedReceiptId] = React.useState("");
  const [isSendMailModalOpen, setisSendMailModalOpen] = React.useState(false);
  const [show_alert, setshow_alert] = React.useState(false);
  const { historyPermit, loader } = useSelector(getParkingPermitDetail);
  const userProfileDetail = useSelector(getUserProfileDetails);

  const { api_response, api_success_message } = useSelector(getAPIResponse);

  const { userProfileDetails, login_info } = useSelector(getLoginDetails);

  const [selectedPropertyObj, setselectedPropertyObj] = React.useState({
    value: login_info?.id,
    label: login_info?.property_name,
  });
  const user_id = userProfileDetails?.id;
  const timeZone = userProfileDetail?.timezone;

  const columns = React.useRef([]);

  const customStyles = React.useRef({
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  });

  const renderOverlayTrigger = (id, content, tooltipContent) => (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={<Tooltip id={id}>{tooltipContent}</Tooltip>}>
      <div className="text-truncate text-nowrap pr-3">{content}</div>
    </OverlayTrigger>
  );

  const formatPermitType = (type) =>
    type
      .split("_")
      .join(" ")
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

  const ShowCell = useCallback((row, _, column) => {
    let data = "";
    switch (column.name) {
      case "Domain":
        data = showDomain(row);
        break;
      case "Package Name":
        data = showPackageName(row);
        break;
      case "Permit Type":
        data = showPermitType(row);
        break;
      case "LPN":
        data = showLpn(row);
        break;
      case "LOT":
        data = showLot(row);
        break;
      case "RATES":
        data = showRates(row);
        break;
      case "REFUNDED AMOUNT":
        data = showRefundAmount(row);
        break;
      case "View Parking Receipt":
        data = showReceiptView(row);
        break;
      case "Purchased On":
        data = showCreatedAt(row);
        break;
      case "Begin Time":
        data = showBeginTime(row);
        break;
      case "Expire Time":
        data = showExpireTime(row);
        break;
      default:
        break;
    }
    return <CellDisplay data={data} />;
  }, []);

  const showExpireTime = (row) =>
    row.expire_time
      ? renderOverlayTrigger(`tooltip-end`, utcToSpecificTimeZone(timeZone, row.expire_time), utcToSpecificTimeZone(timeZone, row.expire_time))
      : "-";

  const showBeginTime = (row) =>
    row.begin_time
      ? renderOverlayTrigger(`tooltip-start`, utcToSpecificTimeZone(timeZone, row.begin_time), utcToSpecificTimeZone(timeZone, row.begin_time))
      : "-";

  const showCreatedAt = (row) =>
    row.created_at
      ? renderOverlayTrigger(`tooltip-purchased-on`, utcToSpecificTimeZone(timeZone, row.created_at), utcToSpecificTimeZone(timeZone, row.created_at))
      : "-";

  const showReceiptView = (row) => {
    if (row.primary_permit && row.paid_permit_type !== "invoice") {
      return (
        <Button className="btn-blue-bg" variant="primary" size="xs" onClick={() => handleShowReceiptDetails(row)}>
          View Receipt
        </Button>
      );
    } else {
      return "-";
    }
  };

  const showRefundAmount = (row) =>
    row?.refunded_amount ? renderOverlayTrigger(`tooltip-refunded-amount`, `$${row?.refunded_amount}`, `$${row?.refunded_amount}`) : "-";

  const showRates = (row) => (row?.paid_amount ? renderOverlayTrigger(`tooltip-rates`, `$${row?.paid_amount}`, `$${row?.paid_amount}`) : "0.00");

  const showLot = (row) => (row?.lots?.alias ? renderOverlayTrigger(`tooltip-lot`, row?.lots?.alias, row?.lots?.alias) : "-");

  const showLpn = (row) => (row?.lpn ? renderOverlayTrigger(`tooltip-lpn`, row?.lpn, row?.lpn) : "");

  const showPermitType = (row) => {
    if (row?.paid_permit_options?.permit_type) {
      return renderOverlayTrigger(
        `tooltip-permit-type`,
        formatPermitType(row?.paid_permit_options?.permit_type),
        formatPermitType(row?.paid_permit_options?.permit_type),
      );
    } else {
      return "-";
    }
  };

  const showPackageName = (row) =>
    row?.lpn ? renderOverlayTrigger(`tooltip-package-name`, row?.paid_permit_options?.package_name, row?.paid_permit_options?.package_name) : "";

  const showDomain = (row) => {
    if (row?.domain) {
      return renderOverlayTrigger(`tooltip-domain`, row?.domain, row?.domain);
    } else {
      return "-";
    }
  };

  useEffect(() => {
    const init = async () => {
      const propertyId = selectedPropertyObj.value;
      setselectedPropertyObj({
        value: login_info?.id,
        label: login_info?.property_name,
      });
      dispatch({
        type: t.SHOW_HIDE_LOADER,
        payload: true,
      });
      dispatch(getHistoryPermit(user_id, childcheckbox, propertyId));
      columns.current = [
        {
          name: "Domain",
          sortable: true,
          minWidth: "120px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.domain,
        },
        {
          name: "Package Name",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.paid_permit_options?.package_name,
        },
        {
          name: "Permit Type",
          sortable: true,
          minWidth: "180px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.paid_permit_options?.permit_type,
        },
        {
          name: "LPN",
          sortable: true,
          minWidth: "180px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.lpn,
        },
        {
          name: "LOT",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.lots?.alias,
        },
        {
          name: "RATES",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.paid_amount || "-",
        },
        {
          name: "REFUNDED AMOUNT",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row?.refunded_amount || "-",
        },
        {
          name: "View Parking Receipt",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row.primary_permit,
        },
        {
          name: "Purchased On",
          minWidth: "140px",
          maxWidth: "250px",
          sortable: true,
          cell: ShowCell,
          selector: (row) => row.created_at,
        },
        {
          name: "Begin Time",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row.begin_time,
        },
        {
          name: "Expire Time",
          sortable: true,
          minWidth: "140px",
          maxWidth: "250px",
          cell: ShowCell,
          selector: (row) => row.end_time,
        },
      ];
    };
    init();
  }, [user_id]);

  const handleShowReceiptDetails = async (row) => {
    row.language = "en";
    console.log("this is the row", row);
    setshowReceiptModal(true);
    setpdfLoader(true);

    if (row) {
      const data = await dispatch(getReceiptPDF(row));
      if (api_response) {
        setpdfUrl(data);
        setselectedReceiptId(row.id);
        setpdfLoader(false);
      }
    }
  };

  const sendMailClick = (e, emailToSend) => {
    e.preventDefault();
    const type = "pdf";
    const sendMailData = {
      type,
      emailToSend,
      permitId: selectedReceiptId,
      propertyId: selectedPropertyObj.value,
      language: "en",
    };
    ticketDataPdf(sendMailData);
  };

  const ticketDataPdf = (sendMailData) => {
    dispatch(pdfSendToMail(sendMailData));
    if (api_response) {
      setshow_alert(true);
      setisSendMailModalOpen(false);
      setshowReceiptModal(false);
      setTimeout(() => {
        setshow_alert(false);
      }, 4000);
    }
  };

  const closeModal = () => {
    setshowReceiptModal(false);
  };

  const handleSearch = async () => {
    const propertyId = selectedPropertyObj.value;
    dispatch({
      type: t.SHOW_HIDE_LOADER,
      payload: true,
    });
    dispatch(getHistoryPermit(user_id, childcheckbox, propertyId));

    document.body.classList.remove("foc-open");
  };

  const handleSearchChange = (e) => {
    const val = e?.target?.value?.toString().trim();
    setSearchValue(val);
    !isEmpty(val) && search(val);
  };

  const search = (val) => {
    const columnList = ["created_at", "begin_time", "expire_time", "paid_amount", "lpn", "permit_type", "package_name", "alias"];
    const filteredValue = findBySearch(val, historyPermit, historyPermit, columnList, timeZone);
    setFiltered([...filteredValue]);
  };

  React.useEffect(() => {
    if (historyPermit && !isEmpty(historyPermit)) {
      setFiltered([...historyPermit]);
    }
  }, [historyPermit]);

  useEffect(() => {
    const height = tableHeightScrollBars();
    setFinalTableHeight(height);
  }, []);

  useEffect(() => {
    const detectedDevices = getMobileOperatingSystem();
    setDetectedDevice(detectedDevices);
  }, []);

  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || window.opera; // || navigator.vendor;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  };

  const hideSlider = () => {
    document.body.classList.remove("foc-open");
  };

  const getPaginationRows = (searchData, report, filter) => {
    let pageLength = report.length;
    if (searchData?.length) {
      pageLength = filter.length;
    }
    return pageLength;
  };

  const getTableData = (searchValueData, reportData, filterValue) => {
    let data = reportData;
    if (searchValueData?.length) {
      data = filterValue;
    }
    return data;
  };

  return (
    <div>
      <Container fluid className="manage-unit-list-page p-0">
        <Row>
          <Col md="12">
            <Card className="mb-0">
              <Card.Body className="pos-rel-overflow-hide pb-0">
                <>
                  <div className="sidebarFilter">
                    <Card className="contact-container mb-0">
                      <Card.Header style={{}}>
                        <div className="d-flex align-items-center justify-content-between">
                          <Card.Title as="h4">History Of Permits</Card.Title>
                          <a
                            onClick={hideSlider}
                            // href="javascript:void(0);"
                            className="btn btn-sm border-0 bg-light rounded-circle  w-30px h-30px d-flex align-items-center justify-content-center"
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </a>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Col md="12">
                          <div className="mb-2">
                            <label className="fancy-checkbox">
                              <input
                                type="checkbox"
                                name={"childcheckbox"}
                                checked={childcheckbox}
                                onChange={() => {
                                  setChildcheckbox(!childcheckbox);
                                }}
                                className="custom-control-input"
                                id="childcheckbox1"
                              ></input>
                              <span>
                                <i></i>Include Children Sites
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col md="12">
                          <PropertDetails
                            selectedPropertyObj={selectedPropertyObj}
                            changeProperty={(e) => {
                              setselectedPropertyObj(e);
                            }}
                            properties={properties}
                          />
                        </Col>
                      </Card.Body>
                      <Card.Footer className="text-right justify-content-end d-flex">
                        <Button variant="info" className="h-40 btn-fill btn-sm" onClick={handleSearch} disabled={loader}>
                          Search
                        </Button>
                      </Card.Footer>
                    </Card>
                  </div>
                  <header>
                    <div className="active-permit-header flex-column-sm sm-align-items-start">
                      <h5 className="mb-2 mb-md-0">History of Permits</h5>
                      <div className="filter-button d-flex flex-wrap">
                        <div className="d-flex align-items-center search-mb-0">
                          <DatatableHeader
                            className="mt-0 mb-0"
                            // Header={"List of Units"}
                            searchValue={searchValue}
                            HandleChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>
                  </header>
                </>
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    {
                      <>
                        {/* <SRLWrapper options={options}> */}
                        <div className="citation-list-table verify-audit-table">
                          <DataTable
                            data={getTableData(searchValue, historyPermit, filtered)}
                            columns={columns.current}
                            customStyles={customStyles.current}
                            responsive={true}
                            defaultSortAsc={true}
                            fixedHeader={true}
                            onChangePage={scroolToTop}
                            fixedHeaderScrollHeight={finalTableHeight}
                            paginationPerPage="100"
                            paginationTotalRows={getPaginationRows(searchValue, historyPermit, filtered)}
                            pagination={true}
                            paginationRowsPerPageOptions={[100, 200, 300, 400]}
                          />
                        </div>
                        {/* </SRLWrapper> */}
                      </>
                    }
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}

      {
        <Modal show={showReceiptModal} onHide={closeModal}>
          <Modal.Header className="no-border">
            <Modal.Title as="h4" className="m-0">
              <h4 className="m-0 font-weight-bold">Parking Receipt</h4>
            </Modal.Title>
            <Button title={"Close"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove"></i>
            </Button>
          </Modal.Header>
          <Modal.Body className="modal-data-body">
            {pdfLoader ? (
              <PdfContainer>
                <ModalLoader />
              </PdfContainer>
            ) : (
              <div>
                <>
                  {pdfUrl && !isMobile && (
                    <>
                      <EmbedIframe className={"pdf-frame"} src={pdfUrl} />
                    </>
                  )}
                  {pdfUrl && isMobile && (
                    <>
                      {detectedDevice === "iOS" && <EmbedIframe className={"pdf-frame"} src={pdfUrl} rotate={180} />}
                      {detectedDevice !== "iOS" && (
                        <div>
                          <Document file={pdfUrl}>
                            <Page pageNumber={1} />
                          </Document>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <a id="pdf" href={pdfUrl} target={"_blank"} rel="noopener noreferrer" style={{ display: "none" }}>
              pdf
            </a>
            <div className="d-flex w-100 justify-content-md-center flex-wrap">
              <Dropdown className="mx-2 mt-2" id="ignoreButton2">
                <Dropdown.Toggle>
                  <i className="fa fa-download" aria-hidden="true"></i> Save as
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      document.getElementById("pdf").click();
                    }}
                  >
                    PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="mx-2 mt-2" id="ignoreButton2">
                <Dropdown.Toggle>
                  {/* <img className="" src={email} width={18} /> */}
                  Send mail as
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setshowReceiptModal(false);
                      setisSendMailModalOpen(true);
                    }}
                  >
                    PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button id="ignoreButton3" className="btn-fill mx-2 mt-2" type="button" variant="secondary" onClick={closeModal}>
                {/* <img className="ml-0 mr-1" src={cancel} width={18} /> */}
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      }
      {isSendMailModalOpen && (
        <SendInvoiceModal
          closeModal={() => {
            setisSendMailModalOpen(false);
          }}
          ticketDataPdf={sendMailClick}
        />
      )}
    </div>
  );
};

const CellDisplay = ({ data }) => {
  return <>{data}</>;
};

const EmbedIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 200px);
  rotate: degrees(-45);
`;
const PdfContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
`;
