import React from "react";

const DatatableHeader = (props) => {
  const setIsOpen = () => {
    if (document.body.classList.contains("foc-open")) {
      document.body.classList.remove("foc-open");
    } else {
      document.body.classList.add("foc-open");
    }
  };
  const setIsZoom = () => {
    if (document.body.classList.contains("screen-zoomin-out")) {
      document.body.classList.remove("screen-zoomin-out");
    } else {
      document.body.classList.add("screen-zoomin-out");
    }
  };
  return (
    <div className="active-permit-header sm-flex-column sm-align-items-start mb-0">
      <h5 className="mb-2 mb-sm-0">{props.Header}</h5>
      <div className={"header-search-button"}>
        <div className="search-container">
          <div className="position-relative">
            <i className="fas fa-search"></i>
            <input
              autoFocus={false}
              type="text"
              placeholder={props.citations ? "LPN/Citation" : "Search"}
              value={props.searchValue}
              onChange={props.HandleChange}
              className="form-control form-control-sm"
            />
          </div>
        </div>
        {!props.noShowFilter && (
          <div className="filter-button">
            <a
              // href="javascript:void(0);"
              onClick={() => setIsOpen()}
              className="btn btn-sm filter-icon-btn border-0 bg-light ms-2  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
            >
              <i className="fa fa-filter fs-13px" aria-hidden="true"></i>
            </a>
            <a
              // href="javascript:void(0);"
              onClick={() => setIsZoom()}
              className="btn btn-sm zoom-icon-btn border-0 mo-none bg-light ms-2  w-30px h-30px d-flex align-items-center justify-content-center ml-2"
            >
              <i className="fa fa-expand fs-13px" aria-hidden="true"></i>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatatableHeader;
