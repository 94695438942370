import React from "react";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";

export const customRow = ({ value, className = "", primary_permit, row }) => {
  const paid = primary_permit ? true : false;
  return (
    <div className={`text-truncate pe-2 ${className}`}>
      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"top"} overlay={<Tooltip id={`tooltip-qw`}>{value}</Tooltip>}>
        <div className="text-truncate text-nowrap pe-2">{ParkingPermitToolTip(paid, row, value, className)}</div>
      </OverlayTrigger>
    </div>
  );
};

const ParkingPermitToolTip = (paid, row, value, className) => {
  if (paid) {
    return (
      <span>
        {value}
        {row?.lpn_options?.length > 0 ? `+(${row?.lpn_options?.length})` : ""}
      </span>
    );
  } else if (className === "lpnIcon") {
    return <span style={{ color: "#359ed8" }}>{value}</span>;
  } else {
    return <span>{value}</span>;
  }
};

export const overlayTriggerRow = ({
  value,
  delay = { show: 250, hide: 400 },
  placement = "top",
  className = "text-nowrap text-truncate pe-2",
  style = { maxWidth: "140px" },
}) => {
  return (
    <div className="text-nowrap text-truncate">
      <OverlayTrigger delay={delay} placement={placement} overlay={<Tooltip id={`tooltip-qw`}>{value}</Tooltip>}>
        <div className={className} style={style}>
          {value}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export const buttonRow = ({ active, primary_permit, permit_type, value, className = "", variant = "", size = "", onClick, allow_cancellation }) => {
  return (
    <div>
      {(value === "View Receipt" && primary_permit) ||
      (value === "Cancel" && allow_cancellation && primary_permit && active && permit_type === "monthly_permits") ? (
        <Button className={className} variant={variant} size={size} onClick={onClick}>
          {value}
        </Button>
      ) : (
        "-"
      )}
    </div>
  );
};

export const checkBoxRow = ({ active = true, primary_permit, value, onChange, disabled, allow_cancellation }) => {
  return (
    <div>
      {allow_cancellation && active && primary_permit ? (
        <div>
          <input type="checkbox" checked={value} onChange={onChange} disabled={disabled} />
        </div>
      ) : (
        "-"
      )}
    </div>
  );
};
