import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

const useProperty = () => {
  const allProperties_ = useSelector(
    (state) => state.ParkingPermitDetails.getAllProperties
  );
  const allProperties =
    allProperties_?.length > 0
      ? allProperties_.filter((id) => id.is_archived === false)
      : [];

  const getParentPropertyName = useCallback(
    (property) => {
      const parentProperty = allProperties.find(
        (a) => a.id === property.property_id
      );
      if (parentProperty) {
        if (
          parentProperty.property_id &&
          property.property_id !== parentProperty.property_id
        ) {
          return `${property.site_name} - ${getParentPropertyName(
            parentProperty
          )}`;
        } else {
          return `${property.site_name} - ${parentProperty.site_name}`;
        }
      }
      return property.site_name;
    },
    [allProperties]
  );
  const properties = useMemo(() => {
    return (allProperties ?? [])
      .map((a) => {
        return { ...a, modified_site_name: getParentPropertyName(a) };
      })
      .map((a) => ({ value: a.id, label: a.modified_site_name }))
      .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }, [allProperties]);

  return { properties };
};

export default useProperty;
