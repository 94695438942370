import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileDetails,
  getLoginDetails,
} from "../../selectors/LoginDetails";
import { userLogout } from "../../Actions/UserProfileActions/actions";
import { useNavigate } from "react-router-dom";
import paidPermitIcon from "../../../assets/img/paid-permit_.png";
import carWalletIcon from "../../../assets/img/car-wallet.svg";
import { useLocation } from "react-router";
import { getFinalUrlForParkingPermitAndCarwallet } from "../Helper/ValidationHelper";

function ParkingNavbar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const getUserProfileDetail = useSelector(getUserProfileDetails);
  const username = getUserProfileDetail?.username;
  const url = window.location.origin;
  const url_result = url.replace(/^https?:\/\//, '');
  const splitURL = url_result.split(".");
  const main_url = splitURL[0] + "." + splitURL[1];

  const final_url = getFinalUrlForParkingPermitAndCarwallet();

  const { login_info } = useSelector(getLoginDetails);

  const logoutData = {
    userid: getUserProfileDetail?.id,
    permission_id: getUserProfileDetail.permission_id,
    highSecureProperty: 0,
    property_id: getUserProfileDetail?.property_id,
    login_id: "",
  };
  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    dispatch(userLogout(logoutData, navigate));
  };

  const loginToExistingAccount = () => {
    navigate("/login");
  };

  const editProfile = () => {
    if (location.pathname === "/user-profile") {
      window.location.reload();
    } else {
      navigate("/user-profile");
    }
  };

  return (
    <Navbar bg="light" expand="lg" className="h-auto p-0">
      <Container fluid className="py-2">
        {final_url === process.env.REACT_APP_PARKING_PERMIT_URL || main_url === process.env.REACT_APP_PARKING_PERMIT_URL ? (
          <div className="CompanyHeader"></div>
        ) : (
          <div className="CompanyHeader">
            <img src={login_info?.header_logo} width="200" />
          </div>
        )}
        {final_url === process.env.REACT_APP_PARKING_PERMIT_URL || main_url === process.env.REACT_APP_PARKING_PERMIT_URL ? (
          <div
            className="parkingPermitHeader"
          // style={{
          //   flex: 1,
          //   justifyContent: "center",
          //   display: "flex",
          //   paddingLeft: "130px",
          // }}
          >
            <Navbar.Brand href="/parking-permit" className="fw-700 text-dark">
              <img src={paidPermitIcon} width="200" style={{ marginRight: 15 }} />
            </Navbar.Brand>
          </div>
        ) : (
          <div
            className="parkingPermitHeader"
          // style={{
          //   flex: 1,
          //   justifyContent: "center",
          //   display: "flex",
          //   paddingLeft: "130px",
          // }}
          >
            <Navbar.Brand href="/parking-permit" className="fw-700 text-dark">
              <img src={carWalletIcon} width="200" style={{ marginRight: 15 }} />
            </Navbar.Brand>
          </div>
        )}
        {window.location.pathname === "/signup" ? (
          <>
            <div className="mt-md-0 d-flex align-items-center flex">
              <i className="nc-icon nc-circle-09 fs-1 mt-0 mr-2 me-2"></i>
              <div>
                <a className="btn-link" type="button" onClick={loginToExistingAccount}>
                  Login to existing account
                </a>
                <OverlayTrigger delay={{ show: 250, hide: 400 }} placement={"bottom"} overlay={<Tooltip id={`tooltip-top`}>{login_info?.address}</Tooltip>}>
                  <div className="parkingPermitAddress">{login_info?.address}</div>
                </OverlayTrigger>
              </div>
            </div>
          </>
        ) : (
          <>
            <Nav>
              <div className="d-flex">
                <div id="basic-nav-dropdown" className="mr-2 d-flex align-items-center">
                  <span> </span>
                  <i className="nc-icon nc-circle-09 fs-1 mt-0"></i>
                  <span> </span>
                </div>
                {username && (
                  <NavDropdown title={username} align="end" className="p-0 d-flex align-items-center">
                    <NavDropdown.Item onClick={editProfile}>Edit Profile</NavDropdown.Item>
                    <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                  </NavDropdown>
                )}
              </div>
            </Nav>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default ParkingNavbar;
