import React, { useState } from "react";
import moment from "moment";

import { Row, Col, Toast } from "react-bootstrap";
import momentTimeZone from "moment-timezone";

const CustomContentAlert = (value) => {
  const [show, setShow] = useState(true);
  return (
    <Row>
      <Col md={12}>
        <Toast
          style={{ position: "center" }}
          onClose={() => setShow(false)}
          show={show}
          delay={value.delay ? value.delay : 4000}
          autohide
          className={value.className}
        >
          <Toast.Header style={{ position: "right" }}></Toast.Header>
          <Toast.Body>{value.message}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
};

export const findBySearch = (
  searchValue,
  list,
  oldList,
  columnList,
  timezone
) => {
  let newItems = [];
  if (searchValue && searchValue.trim().length === 0) {
    newItems = oldList;
  } else {
    subFindBySearch(searchValue, list, columnList, timezone, newItems);
  }
  return newItems;
};

const subFindBySearch = async (
  searchValue,
  list,
  columnList,
  timezone,
  newItems
) => {
  (list ?? []).filter((item) => {
    const temp1 = false;
    let filteredItem;
    item &&
      (Object.keys(item) ?? []).map((ele) => {
        let tempResult;
        if (!columnList || columnList.includes(ele)) {
          if (timezone) {
            if (
              ele === "created_at" ||
              ele === "IssuedDate" ||
              ele === "time_of_action" ||
              ele === "deactivation_time" ||
              ele === "ticket_time" ||
              ele === "transaction_time" ||
              ele === "end_time" ||
              ele === "entry_time" ||
              ele === "permit_created_at" ||
              ele === "createdAt"
            ) {
              let date;
              if (ele === "IssuedDate") {
                date = utcToSpecificTimeZone(timezone, item[ele], true);
              } else if (ele === "permit_created_at") {
                date = moment(item[ele]).format("lll");
              } else {
                date = utcToSpecificTimeZone(timezone, item[ele]);
              }
              const temp2 = date
                .toLowerCase()
                .includes(searchValue?.toLowerCase());
              tempResult = temp1 || temp2 ? temp2 : false;
              tempResult && (filteredItem = item);
              return tempResult;
            }
          }
          if (typeof item[ele] === "number") {
            const temp2 = item[ele].toString().includes(searchValue);
            tempResult = temp1 || temp2 ? temp2 : false;
            tempResult && (filteredItem = item);
            return tempResult;
          } else {
            const temp2 =
              item[ele] &&
              item[ele] !== true &&
              item[ele] !== false &&
              typeof item[ele] !== "object" &&
              item[ele].trim().length &&
              item[ele].toLowerCase().includes(searchValue?.toLowerCase());
            tempResult = temp1 || temp2 ? temp2 : false;
            tempResult && (filteredItem = item);
            return tempResult;
          }
        }
        return tempResult;
      });
    filteredItem && newItems.push(filteredItem);
    return [];
  });
};

export const tableHeightScrollBars = () => {
  const bodyHeight = document.body.scrollHeight;
  const navbarHeight = document.getElementsByClassName("navbar")[0]
    ?.scrollHeight
    ? document.getElementsByClassName("navbar")[0]?.scrollHeight
    : null;
  const filterHeaderHeight =
    document.getElementsByTagName("header")[0].offsetHeight;
  const footerHeight =
    document.getElementsByClassName("fixed-bottom")[0].offsetHeight;
  const finalHeight =
    bodyHeight - (navbarHeight + filterHeaderHeight + footerHeight + 100);
  return finalHeight + "px";
};

export const scroolToTop = () => {
  try {
    const tableElement = document.getElementsByClassName("rdt_Table")[0];
    tableElement?.scrollIntoView({ behavior: "smooth" });
  } catch (error) {
    console.log("scroolToTop Error:::-", error);
  }
};

export const utcToSpecificTimeZone = (timezone, date, time) => {
  try {
    if (time) {
      return momentTimeZone
        .tz(date, "UTC")
        .tz(timezone)
        .format("MMMM DD, YYYY");
    } else {
      return momentTimeZone.tz(date, "UTC").tz(timezone).format("lll");
    }
  } catch (e) {
    console.log("ERROR:: in utcToSpecificTimeZone", e);
  }
};

export default CustomContentAlert;
