import t from "./types";
import apiType from "../APIResponseActions/type";
import loginType from "../LoginActions/types";

import { LoginAPI, AuthAPI, UnAuthAPI } from "../../../Auth/requestHandler";

const rootScope = { user: {} };

function setCrossSubdomainCookie(name, value, days) {
  try {
    const assign = name + "=" + encodeURI(value) + ";";
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString() + ";";
    const path = "path=/;";
    const currentUrl = new URL(window.location.href);
    const hostnameParts = currentUrl.hostname.split(".");
    const documentDomain = hostnameParts.slice(-2).join(".");
    const domain = "domain=" + documentDomain + ";";
    document.cookie = assign + expires + path + domain;
  } catch (e) {
    console.log(e);
  }
}

export const userLogin = (loginObj, authToken, isAutoLogin) => async (dispatch) => {
  let res = null;

  try {
    if (isAutoLogin) {
      res = await AuthAPI("domain", "user/auto/auth", "GET", null, authToken);
    } else {
      res = await LoginAPI("/driver/auth", "POST", {
        ...loginObj,
        flag: true,
      });
    }
    if (res.token && res.success === 1) {
      localStorage.setItem("driverbasicAuth", JSON.stringify(res.token));
      setCrossSubdomainCookie("driverbasicAuth", res.token, 365);
      localStorage.setItem("driverbasicRefreshAuth", JSON.stringify(res.refresh_login_token));
      setCrossSubdomainCookie("driverbasicRefreshAuth", res.refresh_login_token, 365);
      const tokenVariable = res.token;
      const success = res.success === 1;
      const isLinkExist = "link" in res;
      await dispatch({ type: loginType.USER_LOGIN, payload: success });

      const { userData, username } = await getUserData(loginObj, res, isLinkExist);
      rootScope.user.basicAuth = tokenVariable;
      rootScope.user.info = userData;
      rootScope.user.login = username;
      rootScope.user.property_id = res.property_id;
      rootScope.user.domain_property_id = res.domain_property_id;

      localStorage.setItem("DriverUser", JSON.stringify(rootScope.user));

      const userObj = {
        basicAuth: tokenVariable,
        link: isLinkExist ? res.link : "",
        username: username ? username : res.email,
        ...userData,
      };

      await dispatch({
        type: loginType.USER_PROFILE_INFO,
        payload: userObj,
      });
      await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: res.message,
      });
    } else {
      removeLocalStorage();
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res.message,
      });
      if (res.code === 500 && res.success === 0) {
        await dispatch({
          type: loginType.IS_SUBDOMIAN_NOT_EXIT,
          payload: false,
        });
      }
    }
  } catch (error) {
    removeLocalStorage();
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.message || "The server did not respond, Please try again after sometime",
    });
  }
};

const removeLocalStorage = () => {
  if (localStorage.getItem("driverbasicAuth")) localStorage.removeItem("driverbasicAuth");
  if (localStorage.getItem("driverbasicRefreshAuth")) localStorage.removeItem("driverbasicRefreshAuth");
  if (localStorage.getItem("persist:root")) localStorage.removeItem("persist:root");
};

const getUserData = async (loginObj, res, isLinkExist) => {
  const username = loginObj ? loginObj.username : res.username;
  const userData = {
    property_id: res.property_id,
    user_type: res.user_type,
    username: username ? username : res.email,
    first_name: res.first_name,
    last_name: res.last_name,
    phone: res.phone,
    email: username ? username : res.email,
    high_security: res.high_security,
    domain: res.domain ? res.domain : "",
    permission_id: res.permission_id,
    timezone: res.timezone,
    id: res.id ? res.id : "",
    link: isLinkExist ? res.link : "",
  };
  return { username, userData };
};

export const userLogout = (data, navigate) => async (dispatch) => {
  try {
    const body = {
      userid: data?.userid,
      property_id: data?.property_id,
      login_id: data?.login_id,
      highSecureProperty: data?.highSecureProperty,
    };
    const res = await AuthAPI("domain", "user/logout", "POST", body);
    if (res?.success === 1) {
      navigate("/signup");
      localStorage.removeItem("driverbasicAuth");
      setCrossSubdomainCookie("driverbasicAuth", null, -1);
      localStorage.removeItem("driverbasicRefreshAuth");
      localStorage.removeItem("user");
      setCrossSubdomainCookie("driverbasicRefreshAuth", null, -1);
      localStorage.clear();
      await dispatch({ type: t.LOGOUT_USER });
      await dispatch({ type: t.FETCH_USERPROFILE, payload: {} });
      dispatch({ type: loginType.USER_LOGOUT_STATUS });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res?.message?.toString(),
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error?.toString(),
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    const url = "user/verify_email/" + email;
    const res = await UnAuthAPI(url, "GET");
    if (res.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: res.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: res.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const userUpdate = (userData) => async (dispatch) => {
  try {
    const scope = userData;
    const url = "user/" + userData.id;
    const url2 = "user/profile/self";
    let userDataResponse;

    const response = await AuthAPI("domain", url, "PATCH", scope);
    if (response?.success) {
      userDataResponse = await AuthAPI("domain", url2, "GET");
      const basicAuth = localStorage.getItem("driverbasicAuth");
      const userObj = {
        basicAuth,
        ...userDataResponse,
      };
      await dispatch({ type: t.FETCH_USERPROFILE, payload: userObj });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: response.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: response.message,
      });
    }
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const resetPassword = (passwordData) => async (dispatch) => {
  try {
    let data;
    if (passwordData.id) {
      data = await AuthAPI("domain", "password/change", "POST", passwordData);
    } else {
      data = await AuthAPI("domain", "user/password/change", "POST", passwordData);
    }
    if (data.success === 1) {
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: data.message,
      });
    } else {
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: data.message,
      });
    }
    return data;
  } catch (error) {
    await dispatch({
      type: apiType.API_RESPONSE_FAILURE,
      payload: error.toString(),
    });
  }
};

export const getDriverLoginInfo = () => async (dispatch) => {
  try {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: "" });
    const url = "driver_login_info";
    const result = await AuthAPI("domain", url, "GET");
    if (result && result.success === 1) {
      await dispatch({ type: loginType.USER_LOGIN_INFO, payload: result.data });
      await dispatch({
        type: apiType.API_RESPONSE_SUCCESS,
        payload: result.msg,
      });
    } else {
      await dispatch({
        type: loginType.IS_SUBDOMIAN_NOT_EXIT,
        payload: false,
      });
      await dispatch({
        type: loginType.SUBDOMIAN_NOT_EXIT_ERROR,
        payload: result,
      });
      await dispatch({
        type: apiType.API_RESPONSE_FAILURE,
        payload: result.message,
      });
    }
  } catch (error) {
    await dispatch({ type: apiType.API_RESPONSE_FAILURE, payload: error });
  }
};
