import Config from "./Config";

export const LoginAPI = async (url, type, body) => {
  try {
    type = type.toUpperCase();
    const responseData = await fetch(`${Config.baseURL}${url}`, {
      method: type,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return await responseData.json();
  } catch (error) {
    console.log(error);
  }
};

export const BufferAuthAPI = async (
  _baseURL,
  url,
  type,
  body_details,
  authToken
) => {
  const Bearer_Token =
    JSON.stringify(authToken) || localStorage.getItem("driverbasicAuth");
  let API_details = {};
  type = type.toUpperCase();
  if (type === "GET") {
    API_details = {
      method: type,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(Bearer_Token),
      },
    };
  } else {
    if (body_details) {
      API_details = {
        method: type,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
        body: JSON.stringify(body_details),
      };
    } else {
      API_details = {
        method: type,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(Bearer_Token),
        },
      };
    }
  }
  try {
    const resourse = `${Config.baseURL}/${url}`;
    const res = await fetch(resourse, API_details);
    return await res.arrayBuffer();
  } catch (error) {
    console.log(error);
  }
};

export const AuthAPI = async (_baseURL, url, type, body_details, authToken) => {
  try {
    const Bearer_Token =
      JSON.stringify(authToken) || localStorage.getItem("driverbasicAuth");
    type = type.toUpperCase();
    let API_details = {
      method: type,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(Bearer_Token),
      },
    };
    if (type === "GET") {
      API_details = { ...API_details };
    } else {
      if (body_details) {
        API_details = {
          ...API_details,
          body: JSON.stringify(body_details),
        };
      } else {
        API_details = { ...API_details };
      }
    }
    const resourse = `${Config.baseURL}/${url}`;

    const res = await fetch(resourse, API_details);

    return await res.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const UnAuthAPI = async (url, type, body_details, abortControl) => {
  type = type.toUpperCase();
  let API_details = {};
  if (type === "GET") {
    API_details = {
      method: type,
      headers: { "Content-Type": "application/json" },
    };
  } else {
    if (body_details) {
      API_details = {
        method: type,
        signal: abortControl || new AbortController().signal,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body_details),
      };
    } else {
      API_details = {
        method: type,
        signal: abortControl || new AbortController().signal,
        headers: { "Content-Type": "application/json" },
      };
    }
  }
  try {
    const resourse = `${Config.baseURL}/${url}`;

    const res = await fetch(resourse, API_details);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
