import React, { useState, useEffect, useMemo, useCallback, Suspense } from "react";
import Header from "../Navbars/ParkingNavbar";
import Footer from "../Footer";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import moment from "moment-timezone";
import CustomContentAlert from "../Helper/DesignHelper";
import { useNavigate } from "react-router-dom";
import { getFinalUrlForParkingPermitAndCarwallet, isEmailValid, removeSpecialChar } from "../Helper/ValidationHelper";
import {
  getAllLotPermitList,
  createDriverSignup,
  createDriverPaidPermit,
  get_all_properties,
  getAllPermitOptionsByLots,
} from "../../Actions/ParkingPermitActions/actions";
import { useDispatch, useSelector } from "react-redux";
import { getDriverLoginInfo } from "../../Actions/UserProfileActions/actions";
import { getAPIResponse } from "../../selectors/APIResponseReducer";
import { getLoginDetails } from "../../selectors/LoginDetails";
import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";
import DriverPermitCreate from "../DriverPermitCreate/DriverPermitCreate";
import DomainNotExist from "../DomainNotExist/DomainNotExist";
import PleaseWait from "../PleaseWait/PleaseWait";
import _ from "lodash";
import useProperty from "../../../hooks/property";

const initialFormData = {
  domain: "",
  lotList: [],
  max_amount: "",
  usage_count: "",
  cost: "",
  daily_amount: "",
  user_id: "",
  driver_first_name: "",
  driver_last_name: "",
  email: "",
  country_code: "",
  phone: "",
  password: "",
  car_desription: "",
  lpn: "",
  auto_renew: true,
  auto_cancel: true,
  terms_and_condition: false,
  card_number: "",
  card_expiry: "",
  card_cvv: "",
  card_name: "",
  startDate: "",
  permit_type: "",
  country_code_list: [
    { text: "+1", value: "+1" },
    { text: "+91", value: "+91" },
    { text: "+44", value: "+44" },
    { text: "+61", value: "+61" },
  ],
  passwordVisible: false,
  is_form_valid: "",
  errors: {},
  disable_card_options: false,
  Norow: 100,
  page: 1,
  permitType: "all",
  searchValue: "",
  selectedColumn: "created_at",
  sort_direction: "DESC",
  status: "current",
  suite_id: "ParkingPermit",
  userType: "driver",
  isPay: false,
  api_error_message: "",
  api_success_message: "",
  lpn_car_options: [],
  selectedProperty: "",
  selectedPropertyObj: {},
  show_alert: false,
  show_error: false,
  startDateDisable: false,
  stall_id: "",
  original_stall_id: null,
};

let signup_and_permit;

const DriverSignup = () => {
  const { properties } = useProperty();

  const final_url = getFinalUrlForParkingPermitAndCarwallet();

  const propertyObj = properties?.length === 1 ? properties[0] : { value: "", label: "--Select Property--" };
  const [formData, setFormData] = useState({ ...initialFormData });
  const [showForm, setShowform] = useState(false);
  const [lpn_btn_status, setLpn_btn_status] = useState(true);
  const [selectedProperties, setselectedProperties] = useState("");
  const [selectedPropertyObj, setselectedPropertyObj] = useState(propertyObj);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { api_response, api_error_message, api_success_message } = useSelector(getAPIResponse);

  const { login_info, is_sub_domain_not_exist, userProfileDetails, is_pay_loader } = useSelector(getLoginDetails);

  const { driver_data } = useSelector(getParkingPermitDetail);
  const lot_package = useSelector((state) => state.ParkingPermitDetails.driver_package);

  const user_id = userProfileDetails?.id;
  const property_id = login_info?.id;
  const domain = login_info?.property_name;
  const timezone = login_info?.timezone;

  useEffect(() => {
    dispatch(getDriverLoginInfo());
    console.log("login_info?.id::", login_info?.id);
  }, []);

  useEffect(() => {
    dispatch(get_all_properties(property_id, true, "driver"));
    setselectedProperties(selectedPropertyObj.value ? selectedPropertyObj.value : property_id);
  }, [property_id, selectedPropertyObj]);

  const changeProperty = (event) => {
    setselectedPropertyObj(event);
    setselectedProperties(event.value);
    setFormData({
      ...formData,
      lot_id: "",
      package_name: "",
      stall_id: "",
      original_stall_id: "",
      lpn_car_options: [],
      lpn: "",
      car_desription: "",
    });
    const mainObj = { ...formData, lot_id: "", package_name: "", stall_id: "", original_stall_id: "", lpn_car_options: [], lpn: "", car_desription: "" };
    checkValidationsOnChange(event, mainObj);
  };

  useEffect(() => {
    if (selectedPropertyObj?.value || property_id) {
      dispatch(getAllLotPermitList(selectedPropertyObj?.value ? selectedPropertyObj?.value : property_id, true));
    }
  }, [selectedPropertyObj?.value, property_id]);

  useEffect(() => {
    if (formData.lpn.toString().length > 0 && formData.lpn_car_options.length === 0) {
      if (formData.active_permit_count >= formData.max_permit_count && formData.allow_waitlist) {
        setLpn_btn_status(true);
      } else {
        setLpn_btn_status(false);
      }
    } else if (formData.lpn_car_options.length === 1) {
      if (formData.lpn_car_options[0]?.lpn?.toString().length > 0) {
        setLpn_btn_status(false);
      } else {
        setLpn_btn_status(true);
      }
    } else {
      setLpn_btn_status(true);
    }
    if (formData.lpn_car_options.length === 0 && formData.permit_type === "monthly_permits") {
      setFormData({
        ...formData,
        auto_cancel: true,
      });
    } else {
      setFormData({
        ...formData,
        auto_cancel: false,
      });
    }
  }, [formData.lpn_car_options, formData.lpn]);

  useEffect(() => {
    if (api_success_message && user_id) {
      showSuccessAlert();
      hideSuccessAlert();
    } else {
      showErrorAlert();
      hideErrorAlert();
    }
  }, [api_response, api_error_message, api_success_message]);

  useEffect(() => {
    setTimeout(() => {
      if (user_id && (api_success_message || api_error_message)) {
        navigate("/parking-permit");
      }
    }, 3000);
  }, [user_id, api_success_message]);

  const showErrorAlert = () => {
    setFormData((prevState) => ({
      ...prevState,
      is_form_valid: false,
      show_error: true,
      isPay: is_pay_loader,
      api_error_message: api_error_message,
    }));
  };

  const hideErrorAlert = () => {
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_error: false,
        api_error_message: "",
      }));
    }, 4000);
  };

  const showSuccessAlert = () => {
    setFormData((prevState) => ({
      ...prevState,
      is_form_valid: true,
      show_alert: true,
      isPay: is_pay_loader,
      api_success_message: api_success_message,
    }));
  };

  const hideSuccessAlert = () => {
    setTimeout(() => {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: false,
        isPay: is_pay_loader,
        api_success_message: "",
      }));
    }, 10000);
  };

  const checkLpnValidation = useCallback(
    (mainObj) => {
      const allLpn = [mainObj.lpn, ...mainObj.lpn_car_options.map((item) => item.lpn)];

      const checkValueBlank = allLpn.filter((item) => item === "");
      if (checkValueBlank.length === 0 && allLpn.length <= 2) {
        setLpn_btn_status(false);
      } else {
        setLpn_btn_status(true);
      }

      const duplicateLPN = allLpn.filter((item, index) => allLpn.indexOf(item) !== index);
      if (duplicateLPN.length >= 1) {
        return { error_msg: "Duplicate Plate Number Exists", valid: false };
      } else {
        return { error_msg: "", valid: true };
      }
    },
    [formData.lpn, formData.lpn_car_options],
  );

  const handleChange = useCallback(
    ({ target }) => {
      let { name, value } = target;

      if (name === "password" || name === "email") {
        value = value.replace(/\s/g, "");
      }
      let newValue = !["email", "country_code", "password"].includes(name) ? removeSpecialChar(value) : value;

      let Obj = { ...formData };

      if (["phone", "card_number", "card_cvv"].includes(name)) {
        newValue = newValue.replace(/[^0-9.]/g, "");
      }
      if (["lpn"].includes(name)) {
        newValue = newValue.toUpperCase().replace(/\s/g, "");
      }
      Obj = { ...Obj, [name]: newValue };
      const mainObj = { ...formData, ...Obj };
      setFormData(mainObj);
      checkValidationsOnChange(target, mainObj);
    },
    [formData],
  );

  const checkValidationsOnChange = useCallback(
    async (target, mainObj) => {
      let is_form_valid = true;
      const errors = {};
      if (!target.value.toString().length) {
        is_form_valid = false;
        errors[target.name] = `${_.startCase(target.name).split("_").join(" ")} can't be empty`;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }
      if (target.name === "card_number") {
        const cardData = !/^\d+$/.test(target.value);
        if (!cardData && target.value.length <= 16) {
          if (target.value.length > 14) {
            const validCard = await validateCardNumber(target.value);
            if (validCard) {
              is_form_valid = true;
              errors.card_number = "";
            } else {
              is_form_valid = false;
              errors.card_number = "Please Enter Valid card number";
            }
          } else {
            is_form_valid = false;
            errors.card_number = "Please Enter 16 digit card number";
          }
        }
      }

      if (target.name === "card_cvv") {
        const cvvdata = !/^\d+$/.test(target.value);

        if ((!cvvdata && target.value.length <= 4) || target.value.length === 0) {
          if (target.value.length >= 3) {
            errors.card_cvv = "";
            is_form_valid = true;
          } else if (target.value.length > 1) {
            errors.card_cvv = "CVV should be at least 3 digits.";
            is_form_valid = false;
          } else {
            errors.card_cvv = "";
            is_form_valid = true;
            console.log("cvv length 1");
          }
        }
      }

      if (target.name === "lot_id") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.lot_id = `Lot can't be empty`;
        } else {
          is_form_valid = true;
          errors.lot_id = "";
        }
      }
      if (target.name === "package_name") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.package_name = `Package can't be empty`;
        } else {
          is_form_valid = true;
          errors.package_name = "";
        }
      }

      if (formData.stall_type === "user_selected" && target.name === "stall_id") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          errors.stall_id = `Stall can't be empty`;
        } else {
          is_form_valid = true;
          errors.stall_id = "";
        }
      }

      if (target.name === "lpn") {
        if (!target.value.trim().length) {
          is_form_valid = false;
          setLpn_btn_status(true);
        } else {
          is_form_valid = checkLpnValidation(mainObj).valid;
          errors.lpn = checkLpnValidation(mainObj).error_msg;
        }
      }

      if (target.name === "lpn_options") {
        is_form_valid = checkLpnValidation(mainObj).valid;
        errors.lpn = checkLpnValidation(mainObj).error_msg;
      }

      if (target.label === "--Select Property--" && !target.value) {
        is_form_valid = false;
        errors.property_id = "Property Can't be Empty";
      } else {
        is_form_valid = true;
        errors.property_id = "";
      }
      setFormData((prevState) => ({
        ...prevState,
        is_form_valid: is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const getUTCWT = useCallback(() => {
    if (formData.expire_in_days === "end_of_month" || formData.expire_in_days === null) {
      let currentdate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      if (formData.startDate) {
        const date = new Date(formData.startDate);
        currentdate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      const formatEndDate = moment(currentdate).format("YYYY-MM-DD");
      const endDateWithTime = `${formatEndDate} 23:59:59`;
      const formatedET = moment(endDateWithTime).format("YYYY-MM-DD HH:mm:ss");
      const convertedExpireTime = moment.tz(formatedET, timezone).format();

      return moment.tz(convertedExpireTime, "UTC").format("YYYY-MM-DD HH:mm:ss");
    } else {
      let expire_in = +formData.expire_in_days;
      let currentDate = new Date();
      if (formData.startDate) {
        currentDate = new Date(formData.startDate);
      }
      const endDateWithDays = moment(currentDate).add(expire_in - 1, "days");

      const formatStartDate = moment(endDateWithDays).format("YYYY-MM-DD");

      const startDateWithTime = `${formatStartDate} 23:59:59`;

      const formatedET = moment(startDateWithTime).format("YYYY-MM-DD HH:mm:ss");
      const convertedStartTime = moment.tz(formatedET, timezone).format("YYYY-MM-DD HH:mm:ss");
      const endTime = moment.tz(convertedStartTime, "UTC").format("YYYY-MM-DD HH:mm:ss");

      return endTime;
    }
  }, [timezone, formData.startDate, formData.expire_in_days]);

  const TogglePasswordVisibility = () => {
    setFormData({
      ...formData,
      passwordVisible: !formData.passwordVisible,
    });
  };

  const handleCheckBox = (e) => {
    const { name } = e.target;
    const errors = {};
    if (name === "auto_renew") {
      setFormData({ ...formData, auto_renew: !formData.auto_renew });
    }
    if (name === "auto_cancel") {
      setFormData({ ...formData, auto_cancel: !formData.auto_cancel });
    }
    if (name === "terms_and_condition") {
      if (formData.terms_and_condition === false) {
        errors.terms_and_condition = "";
        setFormData({
          ...formData,
          is_form_valid: true,
          terms_and_condition: !formData.terms_and_condition,
          errors: { ...formData.errors, ...errors },
        });
      } else {
        setFormData({
          ...formData,
          is_form_valid: false,
          terms_and_condition: !formData.terms_and_condition,
        });
      }
    }
  };

  const handleChangeLot = ({ target }) => {
    const mainObj = { ...formData, lot_id: target.value };
    setFormData(mainObj);
    checkValidationsOnChange(target, mainObj);
  };

  const handleChangePackage = ({ target }) => {
    const mainObj = { ...formData, package_name: target.value, stall_id: "", original_stall_id: "" };
    setFormData(mainObj);
    checkValidationsOnChange(target, mainObj);
  };

  const handleChangeStall = (event) => {
    const selectedStall = event.target.selectedOptions[0];
    console.log("selectedStallOption::::::", selectedStall, selectedStall.value, selectedStall.getAttribute("id"));
    const mainObj = {
      ...formData,
      stall_id: selectedStall.value,
      original_stall_id: selectedStall.getAttribute("id"),
    };
    setFormData(mainObj);
    checkValidationsOnChange(event.target, mainObj);
  };

  const handleSelectLot = (lotId) => {
    setFormData({
      ...formData,
      lot_id: lotId,
    });
  };

  useEffect(() => {
    if (formData.lot_id && formData.package_name && driver_data && selectedProperties) {
      const findAmount = driver_data?.data?.filter((item) => item.lot_id === formData.lot_id && item.package_name === formData.package_name);
      if (findAmount) {
        let driverObj = {
          ...formData,
          max_amount: findAmount[0]?.max_amount,
          cost: findAmount[0]?.cost,
          usage_count: findAmount[0]?.usage_count,
          one_usage_per_day: findAmount[0]?.one_usage_per_day,
          expire_in_days: findAmount[0]?.expire_in_days,
          daily_amount: findAmount[0]?.daily_amount,
          paid_permit_option_id: findAmount[0]?.id,
          permit_type: findAmount[0]?.permit_type,
          active_permit_count: findAmount[0]?.active_permit_count,
          allow_waitlist: findAmount[0]?.allow_waitlist,
          max_permit_count: findAmount[0]?.max_permit_count,
          stall_type: findAmount[0]?.stall_type,
          lpn_car_options: [],
          lpn: "",
          car_desription: "",
        };
        if (findAmount[0]?.permit_type === "usage_permits" || findAmount[0]?.permit_type === "term_permits") {
          driverObj = { ...driverObj, auto_renew: false, startDateDisable: true, startDate: "", auto_cancel: false };
        } else {
          if (findAmount[0]?.active_permit_count >= findAmount[0]?.max_permit_count && formData.allow_waitlist) {
            driverObj = { ...driverObj, auto_renew: true, startDateDisable: true, startDate: "", auto_cancel: true };
          } else {
            driverObj = { ...driverObj, auto_renew: true, startDateDisable: false, startDate: "", auto_cancel: true };
          }
        }
        setFormData(driverObj);
      }
    }
    Object.keys(driver_data).length && setShowform(true);
  }, [formData.lot_id, formData.package_name, driver_data, selectedProperties]);

  const handleCalender = (date) => {
    const errors = {};
    errors.card_expiry = "";

    setFormData((prevState) => ({
      ...prevState,
      card_expiry: date,
      errors: { ...prevState.errors, ...errors },
    }));
  };

  const mainOptions = useMemo(
    () =>
      (driver_data?.data ?? [])
        .map((item) => ({
          alias: item?.lots?.alias,
          lot_id: item?.lots?.id,
        }))
        .filter((item, index) => {
          if (driver_data?.data.map((item) => item?.lot_id).indexOf(item?.lot_id) === index) {
            return item;
          } else {
            return false;
          }
        }),
    [driver_data],
  );

  useEffect(() => {
    if (propertyObj.value) {
      setselectedPropertyObj(propertyObj);
    }
  }, [propertyObj.value]);

  useEffect(() => {
    if (mainOptions?.length === 1 && selectedProperties && mainOptions?.[0]?.lot_id) {
      setFormData({
        ...formData,
        lot_id: mainOptions[0]?.lot_id,
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
      dispatch(getAllPermitOptionsByLots(selectedProperties, mainOptions?.[0]?.lot_id, true));
    } else {
      setFormData({
        ...formData,
        lot_id: "",
        package_name: "",
        stall_id: "",
        original_stall_id: "",
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
    }
  }, [mainOptions, propertyObj.value, selectedPropertyObj.value, selectedProperties]);

  useEffect(() => {
    if (lot_package?.data?.length === 1 && selectedPropertyObj.value) {
      setFormData({
        ...formData,
        lot_id: lot_package.data?.[0]?.lot_id,
        package_name: lot_package.data?.[0]?.package_name,
        max_amount: lot_package.data?.[0]?.max_amount,
        daily_amount: lot_package.data?.[0]?.daily_amount,
        paid_permit_option_id: lot_package.data?.[0]?.id,
        permit_type: lot_package.data?.[0]?.permit_type,
        active_permit_count: lot_package.data?.[0]?.active_permit_count,
        allow_waitlist: lot_package.data?.[0]?.allow_waitlist,
        cost: lot_package.data?.[0]?.cost,
        usage_count: lot_package.data?.[0]?.usage_count,
        expire_in_days: lot_package.data?.[0]?.expire_in_days,
        max_permit_count: lot_package.data?.[0]?.max_permit_count,
        stall_type: lot_package.data?.[0]?.stall_type,
        one_usage_per_day: lot_package.data?.[0]?.one_usage_per_day,
        stall_id: "",
        original_stall_id: "",
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
    } else {
      setFormData({
        ...formData,
        package_name: "",
        lpn_car_options: [],
        lpn: "",
        car_desription: "",
      });
    }
  }, [lot_package, selectedPropertyObj]);

  const validateCardNumber = async (number) => {
    // Check if the number contains only numeric value
    // and is of between 13 to 19 digits
    const regex = /^\d{13,19}$/;
    if (!regex.test(number)) {
      return false;
    }

    return numberCheck(number);
  };

  const numberCheck = (val) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j === 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    // Check if it is divisible by 10 or not.
    return checksum % 10 === 0;
  };

  const handleDriverPermitCreate = () => {
    const {
      lot_id,
      package_name,
      driver_first_name,
      driver_last_name,
      email,
      country_code,
      phone,
      car_desription,
      auto_renew,
      auto_cancel,
      terms_and_condition,
      lpn,
      lpn_car_options,
      card_number,
      card_expiry,
      card_cvv,
      card_name,
      max_amount,
      cost,
      usage_count,
      one_usage_per_day,
      expire_in_days,
      max_permit_count,
      stall_type,
      stall_id,
      original_stall_id,
      daily_amount,
      paid_permit_option_id,
      permit_type,
      active_permit_count,
      allow_waitlist,
      password,
      // isCardEdit,
      startDate,
      // selectedProperty,
    } = formData;
    const card_expiry_ = moment(new Date(card_expiry)).format("YYYY-MM-DD hh:mm:ss");
    let valid = false;
    let UTCstartDate = "";
    if (startDate) {
      const formatStartDate = moment(startDate).format("YYYY-MM-DD");
      const startDateWithTime = `${formatStartDate} 00:00:00`;
      const formatedET = moment(startDateWithTime).format("YYYY-MM-DD HH:mm:ss");
      const convertedStartTime = moment.tz(formatedET, timezone).format();
      UTCstartDate = moment.tz(convertedStartTime, "UTC").format("YYYY-MM-DD HH:mm:ss");
    }
    if (checkValidations()) {
      valid = true;
      if (permit_type == "usage_permits" || permit_type == "monthly_permits"){

        let end_date_new = getUTCWT();
        signup_and_permit = {
          end_time: end_date_new,
          max_amount: max_amount,
          cost: cost,
          usage_count: usage_count,
          one_usage_per_day: one_usage_per_day,
          expire_in_days: expire_in_days,
          max_permit_count: max_permit_count,
          stall_type: stall_type,
          stall_id: stall_id,
          original_stall_id: original_stall_id,
          lot_id: lot_id,
          package_name: package_name,
          daily_amount: daily_amount,
          // user_id: user_id,
          first_name: driver_first_name,
          last_name: driver_last_name,
          country_code: country_code,
          phone: phone,
          email: email,
          lpn: lpn,
          show_after: show_after || "",
          start_time: start_time || "",
          renew_to: renew_to || "",
          property_id: selectedProperties,
          car_description: car_desription || "",
          auto_renew: auto_renew,
          auto_cancel: auto_cancel,
          terms_and_condition: terms_and_condition,
          card_number: card_number,
          card_expiry: card_expiry_,
          card_cvv: card_cvv,
          card_name: card_name,
          paid_permit_option_id: paid_permit_option_id,
          permit_type: permit_type,
          active_permit_count: active_permit_count,
          allow_waitlist: allow_waitlist,
          is_card_edit: true,
          lpn_car_options: lpn_car_options,
          startDate: UTCstartDate,
        };
      } else {
            signup_and_permit = {
              max_amount: max_amount,
              cost: cost,
              usage_count: usage_count,
              one_usage_per_day: one_usage_per_day,
              expire_in_days: expire_in_days,
              max_permit_count: max_permit_count,
              stall_type: stall_type,
              stall_id: stall_id,
              original_stall_id: original_stall_id,
              lot_id: lot_id,
              package_name: package_name,
              daily_amount: daily_amount,
              // user_id: user_id,
              first_name: driver_first_name,
              last_name: driver_last_name,
              country_code: country_code,
              phone: phone,
              email: email,
              lpn: lpn,
              show_after: show_after || "",
              start_time: start_time || "",
              end_time: end_time || "",
              renew_to: renew_to || "",
              property_id: selectedProperties,
              car_description: car_desription || "",
              auto_renew: auto_renew,
              auto_cancel: auto_cancel,
              terms_and_condition: terms_and_condition,
              card_number: card_number,
              card_expiry: card_expiry_,
              card_cvv: card_cvv,
              card_name: card_name,
              paid_permit_option_id: paid_permit_option_id,
              permit_type: permit_type,
              active_permit_count: active_permit_count,
              allow_waitlist: allow_waitlist,
              is_card_edit: true,
              lpn_car_options: lpn_car_options,
              startDate: start_time || "",
            };
            console.log("================ELSE============");
            console.log(signup_and_permit);
      }
      signup_and_permit = {
        end_time: getUTCWT(),
        max_amount: max_amount,

        cost: cost,
        usage_count: usage_count,
        one_usage_per_day: one_usage_per_day,
        expire_in_days: expire_in_days,

        max_permit_count: max_permit_count,
        stall_type: stall_type,
        stall_id: stall_id,
        original_stall_id: original_stall_id,
        lot_id: lot_id,
        package_name: package_name,
        daily_amount: daily_amount,
        // user_id: user_id,
        first_name: driver_first_name,
        last_name: driver_last_name,
        country_code: country_code,
        phone: phone,
        email: email,
        lpn: lpn,
        property_id: selectedProperties,
        car_description: car_desription || "",
        auto_renew: auto_renew,
        auto_cancel: auto_cancel,
        terms_and_condition: terms_and_condition,
        card_number: card_number,
        card_expiry: card_expiry_,
        card_cvv: card_cvv,
        card_name: card_name,
        paid_permit_option_id: paid_permit_option_id,
        permit_type: permit_type,
        active_permit_count: active_permit_count,
        allow_waitlist: allow_waitlist,
        is_card_edit: true,
        lpn_car_options: lpn_car_options,
        startDate: UTCstartDate,
      };
      if (password.toString().length > 0) {
        signup_and_permit = {
          ...signup_and_permit,
          password: password,
        };
      } else {
        signup_and_permit = {
          ...signup_and_permit,
          password: "",
        };
      }
    }
    if (valid) {
      dispatch(createDriverSignup(signup_and_permit));

      setFormData({
        ...formData,
        isPay: true,
      });

      if (api_error_message && api_response === true) {
        showErrorAlert();

        setTimeout(() => {
          setFormData((prevState) => ({
            ...prevState,
            show_error: false,
            api_error_message: api_error_message,
          }));
        }, 4000);
      }
    }
  };

  useEffect(() => {
    if (user_id) {
      signup_and_permit = {
        ...signup_and_permit,
        user_id: user_id,
      };
      const pageObj = {
        Norow: formData.Norow,
        page: formData.page,
        permitType: formData.permitType,
        searchValue: formData.searchValue,
        selectedColumn: formData.selectedColumn,
        sort_direction: formData.sort_direction,
        status: formData.status,
        suite_id: formData.suite_id,
        userType: formData.userType,
        PropertyId: property_id,
        childcheckbox: false,
      };
      if (Object.keys(signup_and_permit).length > 1) {
        dispatch(createDriverPaidPermit(signup_and_permit, pageObj));
      }
    }
  }, [user_id]);

  const handleAddLpnCarDescription = () => {
    setFormData({
      ...formData,
      lpn_car_options: [...formData.lpn_car_options, { lpn: "", car_description: "" }],
    });
    setLpn_btn_status(true);
  };

  const handleLpnCarOptions = (index, e, type) => {
    const {
      target: { value },
      target,
    } = e;
    const { lpn_car_options } = formData;
    let lpn_values;
    let car_description_values;
    const mainObj = {
      ...formData,
      lpn_car_options: (lpn_car_options ?? []).map((item, i) => {
        const obj = { ...item };
        if (i === index) {
          if (type === "lpn_options") {
            lpn_values = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
            obj.lpn = lpn_values;
          }
          if (type === "car_description_options") {
            car_description_values = value;
            obj.car_description = car_description_values;
          }
        }
        return obj;
      }),
    };

    setFormData(mainObj);

    checkValidationsOnChange(target, mainObj);
  };

  const removeLpnCarOption = (index) => {
    let is_form_valid = true;
    const errors = {};
    const mainObj = {
      ...formData,
      lpn_car_options: [...formData.lpn_car_options.filter((_x, i) => i !== index)],
    };

    is_form_valid = checkLpnValidation(mainObj).valid;
    errors.lpn = checkLpnValidation(mainObj).error_msg;

    setFormData({
      ...mainObj,
      is_form_valid: is_form_valid,
      errors: { ...errors },
    });
  };

  const handleDateChange = useCallback(
    (target) => {
      setFormData((prevState) => ({
        ...prevState,
        startDate: target,
      }));
    },
    [formData],
  );

  const checkValidations = () => {
    let is_form_valid = true;
    const errors = {};
    const {
      package_name,
      driver_first_name,
      driver_last_name,
      email,
      phone,
      lot_id,
      lpn,
      lpn_car_options,
      card_number,
      card_expiry,
      card_cvv,
      card_name,
      terms_and_condition,
      stall_type,
      stall_id,
    } = formData;

    if (!selectedPropertyObj.value) {
      is_form_valid = false;
      errors.property_id = `Property Can't be Empty`;
    }
    if (!lot_id?.toString().length) {
      is_form_valid = false;
      errors.lot_id = `Parking Area can't be empty.`;
    }
    if (!package_name?.toString().length) {
      is_form_valid = false;
      errors.package_name = `Package can't be empty.`;
    }
    if (stall_type?.toString() === "user_selected" && !stall_id?.toString().length) {
      is_form_valid = false;
      errors.stall_id = `Stall can't be empty.`;
    }
    if (!driver_first_name.toString().length) {
      is_form_valid = false;
      errors.driver_first_name = `First Name can't be empty.`;
    }
    if (!driver_last_name.toString().length) {
      is_form_valid = false;
      errors.driver_last_name = `Last Name can't be empty.`;
    }
    if (!email.toString().length || !isEmailValid(email)) {
      is_form_valid = false;
      errors.email = `Email can't be empty or invalid.`;
    }
    if (!phone.toString().length || phone.toString().length !== 10) {
      is_form_valid = false;
      errors.phone = `Phone can't be empty or invalid.`;
    }

    if (!lpn.toString().length) {
      is_form_valid = false;
      errors.lpn = `Licence Plate Number can't be empty.`;
    }

    if (lpn_car_options.length >= 1) {
      for (const i of lpn_car_options) {
        if (!i?.lpn?.toString().length) {
          is_form_valid = false;
          errors.lpn_options = `Licence Plate Number Can't enpty.`;
        }
      }
    }

    if (!card_name?.toString().length) {
      is_form_valid = false;
      errors.card_name = `Card Holder Name can't be empty. `;
    }

    if (!card_number?.toString().length) {
      is_form_valid = false;
      errors.card_number = `Card Number can't be empty. `;
    }
    if (!card_expiry.toString().length) {
      is_form_valid = false;
      errors.card_expiry = `Card Expiry date can't be empty. `;
    }
    if (!card_cvv.toString().length) {
      is_form_valid = false;
      errors.card_cvv = `CVV Number can't be empty. `;
    }

    if (!terms_and_condition) {
      is_form_valid = false;
      errors.terms_and_condition = `You must accept our Terms and Conditions.`;
    }

    setFormData({
      ...formData,
      is_form_valid: is_form_valid,
      errors: errors,
    });
    return is_form_valid;
  };

  const showError = (type) => {
    let { valid, errorMsg } = subShowError(type);
    if (type === "property_id" && formData?.errors.property_id) {
      valid = false;
      errorMsg = formData.errors.property_id;
    }
    if (type === "lpn" && formData.errors?.lpn) {
      valid = false;
      errorMsg = formData.errors.lpn;
    }

    if (type === "lpn" && formData.errors?.lpn) {
      valid = false;
      errorMsg = formData.errors.lpn;
    }

    if (type === "lpn_options" && formData.errors?.lpn_options) {
      valid = false;
      errorMsg = formData.errors.lpn_options;
    }

    if (type === "card_name" && formData.errors?.card_name) {
      valid = false;
      errorMsg = formData.errors.card_name;
    }

    if (type === "card_number" && formData.errors?.card_number) {
      valid = false;
      errorMsg = formData.errors.card_number;
    }
    if (type === "card_expiry" && formData.errors?.card_expiry) {
      valid = false;
      errorMsg = formData.errors.card_expiry;
    }
    if (type === "card_cvv" && formData.errors?.card_cvv) {
      valid = false;
      errorMsg = formData.errors.card_cvv;
    }

    if (type === "terms_and_condition" && formData.errors?.terms_and_condition) {
      valid = false;
      errorMsg = formData.errors.terms_and_condition;
    }

    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const subShowError = (type) => {
    let valid = true;
    let errorMsg = "";
    if (type === "lot_id" && formData.errors?.lot_id) {
      valid = false;
      errorMsg = formData.errors.lot_id;
    }
    if (type === "package_name" && formData.errors?.package_name) {
      valid = false;
      errorMsg = formData.errors.package_name;
    }
    if (type === "stall_id" && formData.errors?.stall_id) {
      valid = false;
      errorMsg = formData.errors.stall_id;
    }
    if (type === "driver_first_name" && formData.errors?.driver_first_name) {
      valid = false;
      errorMsg = formData.errors.driver_first_name;
    }
    if (type === "driver_last_name" && formData.errors?.driver_last_name) {
      valid = false;
      errorMsg = formData.errors.driver_last_name;
    }
    if (type === "email" && formData.errors?.email) {
      valid = false;
      errorMsg = formData.errors.email;
    }
    if (type === "phone" && formData.errors?.phone) {
      valid = false;
      errorMsg = formData.errors.phone;
    }
    return { valid, errorMsg };
  };

  return (
    <>
      <Header />
      <Suspense fallback={<PleaseWait />}>
        {is_sub_domain_not_exist === undefined && <PleaseWait />}
        {is_sub_domain_not_exist === false && <DomainNotExist />}
        {is_sub_domain_not_exist === true && (
          <div className="content pt-3 pb-3 h-100 overflow-auto">
            <Container fluid className="h-100 flex-1">
              <Row className="h-100">
                <Col sm={10} lg={8} xl={6} className="h-100  mx-auto pb-2">
                  <Card className="mb-0 flex-column">
                    <Card.Header>
                      <div className="d-flex align-items-sm-center align-items-start justify-content-between flex-column-sm">
                        <div>
                          {final_url === process.env.REACT_APP_PARKING_PERMIT_URL ? (
                            <Card.Title as="h4">Parking Permit For {domain} </Card.Title>
                          ) : (
                            <Card.Title as="h4">Car Wallet: {domain} </Card.Title>
                          )}
                        </div>
                      </div>
                    </Card.Header>
                    {showForm && (
                      <Card.Body className="pos-rel-overflow-hide">
                        <DriverPermitCreate
                          handleChangeLot={handleChangeLot}
                          handleSelectLot={handleSelectLot}
                          handleChangePackage={handleChangePackage}
                          handleChangeStall={handleChangeStall}
                          editCardDetails={""}
                          mainOptions={mainOptions}
                          showError={showError}
                          formData={formData}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          TogglePasswordVisibility={TogglePasswordVisibility}
                          handleCalender={handleCalender}
                          handleCheckBox={handleCheckBox}
                          handleAddLpnCarDescription={handleAddLpnCarDescription}
                          changeProperty={changeProperty}
                          selectedProperties={selectedProperties}
                          selectedPropertyObj={selectedPropertyObj}
                          handleLpnCarOptions={(index, e, type) => handleLpnCarOptions(index, e, type)}
                          removeLpnCarOption={(index) => removeLpnCarOption(index)}
                          driver_credit_card={""}
                          isPublic={true}
                          lpn_btn_status={lpn_btn_status}
                          properties={properties}
                        />
                        {formData.isPay ? (
                          <Spinner animation="border" variant="info" />
                        ) : (
                          <Button variant="primary" disabled={!formData.is_form_valid} onClick={handleDriverPermitCreate} className="btn-blue-bg">
                            Pay
                          </Button>
                        )}
                      </Card.Body>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Suspense>
      {formData.show_alert && <CustomContentAlert delay={10000} message={formData.api_success_message} className="toast-success" />}
      {formData.show_error && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
      <Footer />
    </>
  );
};

export default DriverSignup;
