import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Navbars/ParkingNavbar";
import { Container, Row, Col, Card, Button, Tabs, Tab } from "react-bootstrap";
import DriverPermitModal from "./DriverPermitModal";
import ParkingPermitActivePermit from "./ParkingPermitActivePermit";
import ParkingPermitFuturePermit from "./ParkingPermitFuturePermit";
import { ParkingPermitHistoryPermit } from "./ParkingPermitHistoryPermit";
import { ParkingPermitWaitlist } from "./ParkingPermitWaitlist";
import { getLoginDetails } from "../../selectors/LoginDetails";
import { getAPIResponse } from "../../selectors/APIResponseReducer";
import CustomContentAlert from "../Helper/DesignHelper";
import { useSelector } from "react-redux";
import { getFinalUrlForParkingPermitAndCarwallet } from "../Helper/ValidationHelper";

const InitialFormData = {
  showDriverPermitModal: false,
  show_alert: false,
  show_error: false,
};

const ParkingPermitPage = () => {
  const [formData, setFormData] = useState({ ...InitialFormData });
  const [activeTab, setActiveTab] = useState("1");

  const getUserDetail = useSelector(getLoginDetails);

  const { api_response, api_error_message, api_success_message } = useSelector(getAPIResponse);

  const domain = getUserDetail?.login_info?.property_name;

  const final_url = getFinalUrlForParkingPermitAndCarwallet();

  useEffect(() => {
    if (api_response) {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: true,
      }));

      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_alert: false,
        }));
      }, 4000);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        show_error: true,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_error: false,
        }));
      }, 4000);
    }
  }, [api_response, api_error_message, api_success_message]);

  const showDriverFormModal = () => {
    setFormData({
      ...formData,
      showDriverPermitModal: !formData.showDriverPermitModal,
    });
  };

  const onTabClick = async (k) => {
    setActiveTab(k);
  };

  return (
    <>
      <Header />
      <div className="content pt-3 pb-3 h-100">
        <Container fluid className="h-100 flex-1">
          <Row className="h-100">
            <Col md="12" className="h-100">
              <Card className="mb-0 flex-column">
                <Card.Header>
                  <div className="d-flex align-items-sm-center align-items-start justify-content-between flex-column-sm">
                    <div>
                      {final_url === process.env.REACT_APP_PARKING_PERMIT_URL ? (
                        <Card.Title as="h4">Parking Permit For {domain} </Card.Title>
                      ) : (
                        <Card.Title as="h4">Car Wallet: {domain} </Card.Title>
                      )}
                    </div>

                    <div>
                      {final_url === process.env.REACT_APP_PARKING_PERMIT_URL ? (
                        <Button onClick={showDriverFormModal} type="button" variant="primary" className="btn-blue-bg btn-sm btn btn-primary">
                          Create new parking permit
                        </Button>
                      ) : (
                        <Button onClick={showDriverFormModal} type="button" variant="primary" className="btn-blue-bg btn-sm btn btn-primary">
                          Purchase New Package
                        </Button>
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pos-rel-overflow-hide">
                  <Tabs activeKey={activeTab} id="controlled-tab-example" onSelect={(k) => onTabClick(k)} transition={false}>
                    <Tab eventKey="1" title="Active Permits" className="m-0">
                      {activeTab === "1" && <ParkingPermitActivePermit />}
                    </Tab>
                    <Tab eventKey="2" title="Future Permits" className="m-0">
                      {activeTab === "2" && <ParkingPermitFuturePermit />}
                    </Tab>
                    <Tab eventKey="3" title="History" className="m-0">
                      {activeTab === "3" && <ParkingPermitHistoryPermit />}
                    </Tab>
                    <Tab eventKey="4" title="Waitlist" className="m-0">
                      {activeTab === "4" && <ParkingPermitWaitlist />}
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {formData.showDriverPermitModal ? (
          <DriverPermitModal
            show={formData.showDriverPermitModal}
            closeModal={() => showDriverFormModal()}
            getUserDetail={getUserDetail}
            activeTab={activeTab}
          />
        ) : null}
        {formData.show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
        {formData.show_error && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
      </div>
      <Footer />
    </>
  );
};

export default ParkingPermitPage;
