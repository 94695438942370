const DriverActionType = {
  FETCH_DRIVER_LOT: "FETCH_DRIVER_LOT",
  FETCH_DRIVER_STALL: "FETCH_DRIVER_STALL",
  FETCH_DRIVER_PACKAGE: "FETCH_DRIVER_PACKAGE",
  FETCH_PARKING_PERMIT_SETTING_DETAILS: "FETCH_PARKING_PERMIT_SETTING_DETAILS",
  FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK: "FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK",
  FETCH_DRIVER_LAST_PERMIT_DATA: "FETCH_DRIVER_LAST_PERMIT_DATA",
  CREATE_DRIVER_PAID_PERMIT: "CREATE_DRIVER_PAID_PERMIT",
  FETCH_DRIVER_CARD_DETAIL: "FETCH_DRIVER_CARD_DETAIL",
  FETCH_CURRENT_PERMIT: "FETCH_CURRENT_PERMIT",
  UPDATE_CURRENT_PERMIT: "UPDATE_CURRENT_PERMIT",
  CALL_WHEN_HERE_ACTIVATE_DEACTIVATE: "CALL_WHEN_HERE_ACTIVATE_DEACTIVATE",
  AUTO_RENEW_ACTIVATE_DEACTIVATE: "AUTO_RENEW_ACTIVATE_DEACTIVATE",
  AUTO_CANCEL_ACTIVATE_DEACTIVATE: "AUTO_CANCEL_ACTIVATE_DEACTIVATE",
  UPDATE_PERMIT: "UPDATE_PERMIT",
  UPDATE_PERMIT_SUCCESS: "UPDATE_PERMIT_SUCCESS",
  UPDATE_PERMIT_ERROR: "UPDATE_PERMIT_ERROR",
  GET_PAID_PARMIT_HISTORY: "GET_PAID_PARMIT_HISTORY",
  GET_WAITLIST_PERMITS: "GET_WAITLIST_PERMITS",
  GET_USER_WAITLIST_PRIORITY: "GET_USER_WAITLIST_PRIORITY",
  SHOW_HIDE_LOADER: "SHOW_HIDE_LOADER",
  GETALLPROPERTY: "GETALLPROPERTY",
};

export default DriverActionType;
