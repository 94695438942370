import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Modal, Button, ModalBody } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";

export default function DriverWaitlistPriorityModel(props) {
  const { updateDriverPriorityOrder, show, closeModal } = props;
  const [items, setItems] = useState([]);

  const { userWaitPermitData } = useSelector(getParkingPermitDetail);

  const handleDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    reorderedItems.forEach((item, index) => {
      item.priority_order = index + 1; // Assuming 'order' field starts from 1
    });
    console.log("reorderedItems:::::::::::::::::::::::", reorderedItems);
    setItems(reorderedItems);
  };

  useEffect(() => {
    if (userWaitPermitData) {
      setItems(userWaitPermitData);
    }
  }, [userWaitPermitData]);
  return (
    <div>
      <Modal lg={"medium"} show={show} onHide={closeModal} dialogClassName="modal-90w " fullscreen={"sm-down"} className="modal-lg">
        <Card>
          <Card.Header className="d-flex justify-content-between bg-white align-items-center">
            {/* <h4 className="m-0 font-weight-bold"> */}
            <Card.Title as="h4" className="m-0">
              Manage Preference Order
            </Card.Title>
            {/* </h4> */}
            <Button title={"Close"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
              <i className="nc-icon nc-simple-remove text-danger"></i>
            </Button>
          </Card.Header>
          <ModalBody>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="priority">
                {(provided) => (
                  <ul className="list-group mt-4" {...provided.droppableProps} ref={provided.innerRef}>
                    <li className="list-group-item list-group-item-secondary">
                      <Row className="fw-bold">
                        <Col>Domain</Col>
                        <Col>LPN</Col>
                        <Col>Preference Order</Col>
                      </Row>
                    </li>
                    {(items ?? []).map((item, index) => (
                      <Draggable key={item.priority_order} draggableId={item.priority_order.toString()} index={index}>
                        {(provided, status) => (
                          <div
                            className={`list-group-item ${status.isDragging ? "start-0 top-position-0" : ""}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Row>
                              <Col>{item.domain}</Col>
                              <Col>{item.lpn}</Col>
                              <Col>{item.priority_order}</Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>
          <Modal.Footer className="d-block">
            <div>
              <p className="small">Note: you can change the preference order by drag and drop.</p>
              <div className="d-flex justify-content-end">
                <Button onClick={closeModal} className="me-3">
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => updateDriverPriorityOrder(items)} className="btn-blue-bg">
                  Submit
                </Button>
              </div>
            </div>
          </Modal.Footer>
          {/* ) : null} */}
        </Card>
      </Modal>
    </div>
  );
}
