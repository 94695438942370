import { combineReducers } from "redux";
import UserProfileDetailsReducer from "./UserProfileReducer/reducer";
import UserLoginReducer from "./UserLoginReducer/reducer";
import ParkingPermitReducer from "./ParkingPermitReducer/reducer";
import APIResponseReducer from "./APIResponseReducer/reducer";
import { connectRouter } from "connected-react-router";

const allReducer = (history) => {
  return {
    router: connectRouter(history),
    UserProfileDetails: UserProfileDetailsReducer,
    APIResponseDetails: APIResponseReducer,
    Logindetails: UserLoginReducer,
    ParkingPermitDetails: ParkingPermitReducer,
  };
};

const rootReducer = (history) =>
  combineReducers({
    ...allReducer(history),
  });

const exportReducers = { rootReducer, allReducer };

export default exportReducers;
