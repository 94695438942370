import React, { Component } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import { isMobile } from "react-device-detect";

const override = css`
  position: fixed;
  left: calc(50% + ${isMobile ? "0px" : "130px"});
  top: 50%;
  transform: translate(-50%, -50%);
`;

const center = css`
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
`;

class Loader extends Component {
  render() {
    return (
      <div className="puff-loader-box d-flex justify-content-center align-items-center">
        <div
          className={`pl-260 ${this.props.center === true && "text-center"}`}
        >
          <PuffLoader
            color={"#167AB6"}
            loading={true}
            css={this.props.center ? center : override}
            size={150}
          />
        </div>
      </div>
    );
  }
}

export default Loader;
