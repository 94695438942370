import "./App.css";

import MainRoutes from "./App/AppRoute/index";
function App() {
  return (
    <div className="main-panel w-100">
      <MainRoutes className="d-flex flex-column h-100" />
    </div>
  );
}

export default App;
