import React, { useState, useEffect, Suspense } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/scss/lbd/pages/_register.scss";
import "../../../assets/scss/lbd/pages/_profile.scss";

import { userLogin, forgotPassword, getDriverLoginInfo } from "../../Actions/UserProfileActions/actions";
import { getLoginDetails } from "../../selectors/LoginDetails";

import { useNavigate } from "react-router-dom";

import { validation_message, isEmailValid } from "../Helper/ValidationHelper";
import CustomContentAlert from "../Helper/DesignHelper";
import { getAPIResponse } from "../../selectors/APIResponseReducer";
import DomainNotExist from "../DomainNotExist/DomainNotExist";
import PleaseWait from "../PleaseWait/PleaseWait";

const initialFormData = {
  username: "",
  password: "",
  // submitted: false,
  loader: false,
  login_body: ``,
  contentvisible: false,
  title: "Parkingpermit.Zone",
  site: "",
  validUsername: true,
  validpassword: true,
  emailValidate: false,
  forgetPass: false,
  showMsg: false,
  invalemail: false,
  showError: false,
  api_error_message: "",
  api_success_message: "",
  show_alert: false,
  show_error: false,
  email: "",
  error_message_for_subdomain_not_exist: "",
  isDomianExist: true,
  currect_link: "",
};
const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserDetail = useSelector(getLoginDetails);
  const { is_sub_domain_not_exist, login_info } = getUserDetail;
  const { api_response, api_error_message, api_success_message } = useSelector(getAPIResponse);
  const [formdata, setFormData] = useState(initialFormData);

  const togglePassword = () => {
    setFormData({
      ...formdata,
      contentvisible: !formdata.contentvisible,
    });
  };

  useEffect(() => {
    setFormData({
      ...formdata,
      login_body: login_info?.login_body,
      title: login_info?.login_header,
      site: login_info?.property_name,
    });
  }, [getUserDetail]);

  useEffect(() => {
    dispatch(getDriverLoginInfo());
  }, []);

  const handleChange = ({ target }) => {
    let obj = { ...formdata };
    if (target.name === "username" && formdata.username.trim().length === 0) {
      obj = {
        ...obj,
        username: "",
        validUsername: false,
      };
    } else if (target.name === "password" && formdata.password.trim().length === 0) {
      obj = {
        ...obj,
        password: "",
        validpassword: false,
      };
    }
    setFormData({
      ...formdata,
      ...obj,
      [target.name]: target.value.includes(" ") ? target.value.replace(/\s/g, "") : target.value,
    });
  };

  useEffect(() => {
    if (api_success_message) {
      setFormData((prevState) => ({
        ...prevState,
        show_alert: true,
        showMsg: true,
      }));
      setTimeout(() => {
        setFormData((prevState) => ({
          ...prevState,
          show_alert: false,
          api_success_message: "",
        }));
      }, 4000);
    } else {
      if (api_error_message) {
        setFormData((prevState) => ({
          ...prevState,
          show_error: true,
          showMsg: false,
        }));
        setTimeout(() => {
          setFormData((prevState) => ({
            ...prevState,
            show_error: false,
            api_error_message: "",
          }));
        }, 4000);
      }
    }
  }, [api_response, api_error_message, api_success_message]);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = formdata;

    const loginObj = {
      username: username.trim(),
      password: password,
    };
    const authToken = "";

    if (username.trim().length && password.trim().length) {
      dispatch(userLogin(loginObj, authToken, false));
    }
  };

  useEffect(() => {
    if (getUserDetail?.login_status === true) {
      if (getUserDetail.userProfileDetails) {
        navigate(`${getUserDetail?.userProfileDetails?.link}/parking-permit`, {
          replace: true,
        });
      }
    }
  }, [getUserDetail?.login_status]);

  const toggleForm = () => {
    const { forgetPass } = formdata;
    setFormData({
      ...formdata,
      forgetPass: !forgetPass,
      email: "",
      showError: false,
      invalemail: false,
      showMsg: false,
      // username: "",
      // password: "",
      validpassword: true,
      contentvisible: false,
      api_error_message: "",
      api_success_message: "",
    });
  };

  const handleEmailChange = ({ target }) => {
    const { value } = target;
    const email = value.replace(/\s/g, "");
    let Obj = { ...formdata };

    if (email.length === 0) {
      Obj = {
        ...Obj,
        showError: true,
      };
    } else {
      const newEmailvalidate = isEmailValid(email);
      Obj = {
        ...Obj,
        emailValidate: newEmailvalidate,
      };
    }
    setFormData({
      ...Obj,
      // ...formdata,
      email: email,
    });
  };

  useEffect(() => {
    const { emailValidate } = formdata;
    if (emailValidate === false) {
      setFormData({
        ...formdata,
        invalemail: true,
        showError: true,
      });
    } else {
      setFormData({
        ...formdata,
        invalemail: false,
        showError: false,
      });
    }
  }, [formdata.emailValidate]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const { email, emailValidate } = formdata;
    if (emailValidate === true) {
      dispatch(forgotPassword(email));

      setFormData({
        ...formdata,
        // loader: true,
        showError: false,
        email: "",
        invalemail: false,
        forgetPass: true,
        // showMsg: true,
      });
    } else {
      setFormData({
        ...formdata,
        invalemail: true,
        email: "",
      });
    }
  };

  const switchCase = (showMsg, forgetPass, email, showError, invalemail) => {
    if (!forgetPass && showMsg) {
      return login_page();
    } else if (!showMsg && forgetPass) {
      return forgetPassword(email, showError, invalemail);
    } else if (showMsg && forgetPass && !email.toString().length) {
      return emailSent(email);
    } else {
      return login_page();
    }
  };

  const emailSent = (email) => {
    return (
      <>
        <h2 className="title">Email sent</h2>
        <Row>
          <Col className={!email ? " has-error" : " "} md="12">
            <div className="info-container">
              <p>An email has been sent with a link to reset your password.</p>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const login_page = () => {
    const {
      // submitted,
      username,
      validUsername,
      password,
      validpassword,
      contentvisible,
    } = formdata;

    return (
      <>
        <h2 className="title">Login</h2>
        <Row>
          <Col className={!username ? " has-error" : ""} md="12">
            <Form.Group className="mb-3">
              <label className="field-lbl">Username</label>
              <Form.Control type="text" className="form-control" name="username" value={username} onChange={handleChange} autoComplete="off"></Form.Control>
              {validation_message(username, "Username", validUsername)}
            </Form.Group>
          </Col>
          <Col className={!password ? " has-error" : ""} md="12">
            <Form.Group className="mb-3">
              <label className="field-lbl">Password</label>
              <div className="input-mask-btn">
                <Form.Control
                  type={contentvisible ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  autoComplete="off"
                  onKeyDown={handleEnterPress}
                />
                <button className="mask-password" type="button" onClick={togglePassword}>
                  {contentvisible ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                </button>
              </div>
              {validation_message(password, "Password", validpassword)}
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  const handleEmailValidationOnForgot = (showError, email, invalemail) => {
    const getEmailError = () => {
      if (showError && !email.trim().length) {
        return "Email is required";
      } else if (invalemail) {
        return "Invalid email";
      } else {
        return "";
      }
    };

    const emailError = getEmailError();

    return <div>{emailError ? <div className="help-block">{emailError}</div> : <div></div>}</div>;
  };

  const forgetPassword = (email, showError, invalemail) => {
    return (
      <>
        <h2 className="title">Forgot Password?</h2>
        <Row>
          <Col className={!email ? " has-error" : ""} md="12">
            <Form.Group>
              <label className="field-lbl">Email</label>
              <Form.Control
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={handleEmailChange}
                // onBlur={this.handleEmailblur}
                autoComplete="off"
              ></Form.Control>
              {handleEmailValidationOnForgot(showError, email, invalemail)}
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  const handleSubmitWithShowmsgAndLoder = () => {
    const { showMsg, loader, forgetPass } = formdata;
    if (showMsg) {
      return <div></div>;
    } else {
      if (!loader) {
        return (
          <Button className="btn-fill pull-right w-100" type="button" variant="info" onClick={handleSubmitOnClick} disabled={handleSubmitDisabled()}>
            {!forgetPass ? "Login" : "Submit"}
          </Button>
        );
      } else {
        return <Spinner animation="border" variant="info" />;
      }
    }
  };

  const handleSubmitDisabled = () => {
    const { forgetPass, username, password, showError, email } = formdata;

    if (!forgetPass) {
      return !(username.trim().length && password.trim().length);
    } else {
      return showError || !email.trim().length;
    }
  };

  const handleSubmitOnClick = (e) => {
    e.preventDefault();
    const { forgetPass } = formdata;
    if (!forgetPass) {
      handleSubmit(e);
    } else {
      handleEmailSubmit(e);
    }
  };

  const handleTextLinkOnLogin = () => {
    const { showMsg, forgetPass } = formdata;
    if (!forgetPass) {
      return "Forgot Password?";
    } else {
      if (!showMsg) {
        return "Back";
      } else {
        return "Log in back";
      }
    }
  };

  return (
    <>
      <Suspense fallback={<PleaseWait />}>
        {is_sub_domain_not_exist === undefined && <PleaseWait />}
        {is_sub_domain_not_exist === false && <DomainNotExist />}
        {is_sub_domain_not_exist === true && (
          <div
            className="login-container"
            // style={{ backgroundImage: `url(${formdata.background_img})` }}
          >
            <div className="login-card">
              <div className="info-container">
                <h2 className="title">
                  <span className={"text-capitalize"}>{formdata.site}</span>: <b>{formdata.title}</b>
                </h2>
                <p>{formdata.login_body}</p>
              </div>
              <div className="login-form">
                {switchCase(formdata.showMsg, formdata.forgetPass, formdata.email, formdata.showError, formdata.invalemail)}
                <form name="form" onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      {!formdata.validUsername && !formdata.validpassword ? <div className="help-block">{api_error_message}</div> : <></>}
                      {}
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    <Col md="5">
                      {
                        handleSubmitWithShowmsgAndLoder() // Main submit button handle function
                      }
                    </Col>
                    <Col md="7" className="text-center text-md-right mt-2 mt-md-0">
                      <button className="btn-link mt-2" type="button" onClick={toggleForm}>
                        {handleTextLinkOnLogin()}
                      </button>
                      <></>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center text-md-right mt-2 mt-md-0">
                      <button
                        className="btn-link mt-2"
                        type="button"
                        onClick={() => {
                          navigate("/signup");
                        }}
                      >
                        {"Create Account?"}
                      </button>
                      <></>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        )}
      </Suspense>

      {formdata.show_alert && api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success bottom" />}
      {formdata.show_error && api_error_message && <CustomContentAlert delay={4000} message={api_error_message} className="toast-error" />}
    </>
  );
};

export default LoginPage;
