import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import { Button, Col, Row, Card, Spinner } from "react-bootstrap";
import { getUserProfileDetails } from "../../selectors/LoginDetails";

import _ from "lodash";
import { DeleteCreditCardDetails, getDriverCreditCardList, saveCreditCardDetails } from "../../Actions/ParkingPermitActions/actions";
import { getAPIResponse } from "../../selectors/APIResponseReducer";
import DeleteCardModal from "../Modal/DeleteCardModal";
import CustomContentAlert from "../Helper/DesignHelper";
import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";
import moment from "moment";

const initialFormData = {
  user_id: "",

  card_number: null,
  card_expiry: "",
  card_cvv: "",
  card_name: "",

  is_form_valid: "",
  errors: {},
  disable_card_options: false,
  isCardEdit: false,

  card_details_id: "",
  submitLoader: false,
};

let initialObj = {};

const UpdateCreditCard = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    ...initialFormData,
  });

  const [show_delete_card_model, setshow_delete_card_model] = useState(false);
  const [isDeleteCard, setisDeleteCard] = useState(false);

  const mainBody = useSelector((state) => state.Logindetails?.backgroundColor);

  const userProfileDetail = useSelector(getUserProfileDetails);
  const { api_success_message, api_error_message } = useSelector(getAPIResponse);

  const user_id = userProfileDetail?.id;
  const username = userProfileDetail?.username;
  const userType = userProfileDetail?.user_type;
  const property_id = userProfileDetail?.property_id;
  const { driver_credit_card } = useSelector(getParkingPermitDetail);

  useEffect(() => {
    if (api_success_message === "Card Details deleted successfully!" || api_success_message === "Card Details Saved successfully!") {
      setshow_delete_card_model(false);
      dispatch(getDriverCreditCardList(user_id, property_id));
      setFormData((prevState) => ({
        ...prevState,
        submitLoader: false,
      }));
    }
    if (api_error_message) {
      setFormData({
        ...formData,
        submitLoader: false,
      });
    }
  }, [api_success_message, api_error_message]);

  useEffect(() => {
    if (user_id && property_id) {
      dispatch(getDriverCreditCardList(user_id, property_id));
    }
  }, [user_id, property_id]);

  useEffect(() => {
    console.log("driver_credit_card:111111111111", driver_credit_card);
    if (driver_credit_card && Object.keys(driver_credit_card).length > 0) {
      initialObj = {
        isCardEdit: true,
        disable_card_options: true,
        card_details_id: driver_credit_card.id,
        card_name: driver_credit_card.name,
        card_number: driver_credit_card.card_number, // `************${driver_credit_card.last_four_card_number}`,
        card_expiry: driver_credit_card.expiry_date,
      };
    } else {
      initialObj = {
        isCardEdit: false,
        // disable_card_options: false,
        card_name: "",
        card_number: "",
        disable_card_options: !formData.disable_card_options,
        card_expiry: "",
        card_cvv: "",
      };
    }
    setFormData({ ...formData, ...initialObj });
  }, [driver_credit_card]);

  const handleChange = async ({ target }) => {
    let { name, value } = target;

    let newValue = value;

    let Obj = { ...formData };

    if (["card_number", "card_cvv"].includes(name)) {
      newValue = newValue.replace(/[^0-9.]/g, "");
    }

    Obj = { ...Obj, [name]: newValue };

    const mainObj = { ...formData, ...Obj };
    setFormData(mainObj);
    checkValidationsOnChange(target);
  };

  const handleCalender = (date) => {
    const errors = {};
    errors.card_expiry = "";
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: date,
        errors: { ...prevState.errors, ...errors },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        card_expiry: "",
      }));
    }
  };

  const handleDeleteCard = () => {
    setisDeleteCard(true);
    dispatch(DeleteCreditCardDetails(formData.card_details_id));
  };

  const showError = (type) => {
    const { isCardEdit } = formData;

    let valid = true;
    let errorMsg = "";

    if (type === "card_name" && formData.errors?.card_name) {
      valid = false;
      errorMsg = formData.errors.card_name;
    }

    if (type === "card_number" && formData.errors?.card_number) {
      valid = false;
      errorMsg = formData.errors.card_number;
    }
    if (type === "card_expiry" && formData.errors?.card_expiry) {
      valid = false;
      errorMsg = formData.errors.card_expiry;
    }
    if (!isCardEdit) {
      if (type === "card_cvv" && formData.errors?.card_cvv) {
        valid = false;
        errorMsg = formData.errors.card_cvv;
      }
    }
    if (!valid) {
      return <div className="help-block">{errorMsg}</div>;
    }
    return true;
  };

  const checkValidationsOnChange = useCallback(
    async (target) => {
      let is_form_valid = true;
      let errors = {};
      if (!target.value.toString().length) {
        is_form_valid = false;
        errors[target.name] = `${_.startCase(target.name).split("_").join(" ")} can't be empty`;
      } else {
        is_form_valid = true;
        errors[target.name] = "";
      }

      if (target.name === "card_number") {
        const { error, valid } = await card_number_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      if (target.name === "card_cvv") {
        const { error, valid } = await card_cvv_validation(target);
        errors = {
          ...errors,
          ...error,
        };
        is_form_valid = valid;
      }

      setFormData((prevState) => ({
        ...prevState,
        is_form_valid: is_form_valid,
        errors: { ...prevState.errors, ...errors },
      }));
      return is_form_valid;
    },
    [formData],
  );

  const editCardDetails = () => {
    setFormData((prevState) => ({
      ...prevState,
      disable_card_options: !formData.disable_card_options,
      card_name: formData.card_name,
      card_number: null,
      card_expiry: "",
      card_cvv: "",
      isCardEdit: false,
    }));
  };

  const deleteCardDetails = () => {
    setshow_delete_card_model(true);
    setisDeleteCard(false);
  };

  const handleShowDeleteCardModal = () => {
    setisDeleteCard(false);
    setshow_delete_card_model(!show_delete_card_model);
  };

  const saveCardDetails = () => {
    const { card_name, card_number, card_expiry, isCardEdit, card_cvv } = formData;

    const card_expiry_ = moment(new Date(card_expiry)).format("YYYY-MM-DD hh:mm:ss");

    if (checkValidations()) {
      setFormData((prevState) => ({
        ...prevState,
        submitLoader: true,
      }));
      console.log("sssss");
      const data = {
        user_id: user_id,
        email: username,
        user_type: userType,
        property_id: property_id,
        card_number: card_number,
        card_expiry: card_expiry_,
        card_cvv: card_cvv,
        card_name: card_name,
        is_card_edit: !isCardEdit,
        language: "en",
      };

      dispatch(saveCreditCardDetails(data));

      console.log("data on card save::::1", data);
    }
  };

  const checkValidations = () => {
    const { card_number, card_expiry, card_cvv, card_name, isCardEdit } = formData;
    let is_form_valid = true;
    let errors = {};

    const { error, valid } = cardValidation(card_number, card_expiry, card_cvv, card_name, isCardEdit);
    errors = {
      ...errors,
      ...error,
    };
    is_form_valid = valid;

    setFormData({
      ...formData,
      is_form_valid: is_form_valid,
      errors: errors,
    });
    return is_form_valid;
  };

  const cardValidation = (card_number, card_expiry, card_cvv, card_name, isCardEdit) => {
    const errors = {};
    let is_form_valid = true;
    if (!card_name?.toString().length) {
      is_form_valid = false;
      errors.card_name = `Card Holder Name can't be empty `;
    }

    if (!card_number?.toString().length) {
      is_form_valid = false;
      errors.card_number = `Card Number can't be empty `;
    }
    if (!card_expiry.toString().length) {
      is_form_valid = false;
      errors.card_expiry = `Card Expiry date can't be empty `;
    }
    if (!isCardEdit) {
      if (!card_cvv.toString().length) {
        is_form_valid = false;
        errors.card_cvv = `CVV Number can't be empty `;
      }
    }

    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const card_number_validation = async (target) => {
    let is_form_valid = true;
    const errors = {};
    const cardData = !/^\d+$/.test(target.value);
    if (!cardData && target.value.length <= 16) {
      if (target.value.length > 14) {
        const validCard = await validateCardNumber(target.value);
        if (validCard) {
          errors.card_number = "";
        } else {
          is_form_valid = false;
          errors.card_number = "Invalid Card Number";
        }
      } else {
        is_form_valid = false;
        errors.card_number = "Invalid Card Number. Enter at least 16 digits.";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const card_cvv_validation = async (target) => {
    const cvvdata = !/^\d+$/.test(target.value);
    let is_form_valid = true;
    const errors = {};
    if ((!cvvdata && target.value.length <= 4) || target.value.length === 0) {
      if (target.value.length >= 3) {
        errors.card_cvv = "";
      } else if (target.value.length > 1) {
        errors.card_cvv = "Invalid CVV. Enter at least 3 digits.";
        is_form_valid = false;
      } else {
        errors.card_cvv = "";
      }
    }
    return {
      error: errors,
      valid: is_form_valid,
    };
  };

  const validateCardNumber = async (number) => {
    // Check if the number contains only numeric value
    // and is of between 13 to 19 digits
    const regex = /^\d{13,19}$/;
    if (!regex.test(number)) {
      return false;
    }

    return numberCheck(number);
  };

  const numberCheck = (val) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j === 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    // Check if it is divisible by 10 or not.
    return checksum % 10 === 0;
  };

  const showSubmitButton = () => {
    let buttonName = "Save Card";

    if (!formData.submitLoader) {
      return (
        <Button className="btn-fill" type="button" variant="info" onClick={() => saveCardDetails()} disabled={!formData.is_form_valid}>
          {buttonName}
        </Button>
      );
    } else {
      return <Spinner animation="border" variant="info" />;
    }
  };

  return (
    <>
      <Card className={window.location.href.includes("user-profile") ? "" : "change-profile-card"}>
        <Card.Header style={{ backgroundColor: mainBody }}>
          <Card.Title as="h4">Credit Card Details</Card.Title>
        </Card.Header>
        {formData.isCardEdit ? (
          <>
            <Row className="mx-0 px-2">
              <div className="mb-2 mt-3 change-credit-card-box">
                <div className="mt-2">
                  <Row>
                    <Col lg="12">
                      <div className="form-group mb-3">
                        <label>
                          Card Holder Name
                          <span class="asterisk">*</span>
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <input
                            required={true}
                            type="text"
                            className="form-control pe-5"
                            name="card_name"
                            value={formData.card_name || ""}
                            onChange={handleChange}
                            disabled={formData.disable_card_options}
                          />
                          <div className="payment-formicon">
                            <span className="fa fa-user" aria-hidden="true" />
                          </div>
                        </div>
                        {showError("card_name")}
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group mb-3">
                    <label>
                      Credit Card number
                      <span class="asterisk">*</span>
                    </label>
                    <div className="d-flex align-items-center position-relative">
                      <input
                        type="text"
                        required={true}
                        className="form-control pe-5"
                        name="card_number"
                        value={formData.card_number}
                        onChange={handleChange}
                        maxLength={16}
                        disabled={formData.disable_card_options}
                      />
                      <div className="payment-formicon">
                        <span className="fa fa-credit-card" aria-hidden="true" />
                      </div>
                    </div>
                    {showError("card_number")}
                  </div>

                  <div className="form-group mb-2">
                    <Row>
                      <Col lg="12">
                        <div className="form-group mb-3">
                          <label>
                            Expiry Date
                            <span class="asterisk">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              type="text"
                              required={true}
                              className="form-control"
                              name="card_expiry"
                              value={formData.card_expiry}
                              onChange={handleChange}
                              disabled={formData.disable_card_options}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-calendar" style={{ cursor: "pointer" }} aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_expiry")}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class="d-flex justify-content-end gap-3">
                  <div>
                    <Button className="btn-fill btn-sm mb-2" type="button" variant="info" onClick={() => editCardDetails()}>
                      <span className="pe-2">
                        <i class="far fa-edit"></i>
                      </span>
                      Edit Card
                    </Button>
                  </div>
                  <div>
                    <Button className="btn-fill btn-sm mb-2" type="button" variant="danger" onClick={() => deleteCardDetails()}>
                      <span className="pe-2">
                        <i class="far fa-trash-alt"></i>
                      </span>
                      Delete Card
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </>
        ) : (
          <>
            <Row className="mx-0 px-2">
              <Col>
                <div className="mb-2 mt-3 change-credit-card-box">
                  <div className="mt-2">
                    <Row>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label>
                            Card Holder Name
                            <span class="asterisk">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              required={true}
                              type="text"
                              className="form-control pe-5"
                              name="card_name"
                              value={formData.card_name}
                              onChange={handleChange}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-user" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_name")}
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="form-group mb-3">
                          <label>
                            Credit Card number
                            <span class="asterisk">*</span>
                          </label>
                          <div className="d-flex align-items-center position-relative">
                            <input
                              type="text"
                              required={true}
                              className="form-control pe-5"
                              name="card_number"
                              value={formData.card_number}
                              onChange={handleChange}
                              maxLength={16}
                            />
                            <div className="payment-formicon">
                              <span className="fa fa-credit-card" aria-hidden="true" />
                            </div>
                          </div>
                          {showError("card_number")}
                        </div>
                      </Col>
                    </Row>

                    <div className="form-group mb-4">
                      <Row>
                        <Col md="6" lg="6">
                          <div className="form-group mb-3">
                            <label>
                              Expiry Date
                              <span class="asterisk">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <DatePicker
                                required
                                autoComplete="new-password"
                                placeholderText="MM/YY"
                                // className="form-control datePickerPayment pe-5"
                                name="card_expiry"
                                selected={formData?.card_expiry}
                                onChange={handleCalender}
                                dateFormat="MM/yy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                minDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                                customInput={
                                  <InputMask
                                    mask="99/99"
                                    maskChar={null}
                                    // placeholder="MM/YY"
                                    className="form-control datePickerPayment pe-5"
                                    name="card_expiry"
                                    value={formData?.card_expiry}
                                    onChange={handleCalender}
                                    // onChangeRaw
                                    showMask
                                  />
                                }
                              ></DatePicker>
                              <div className="payment-formicon">
                                <span className="fa fa-calendar" style={{ cursor: "pointer" }} aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_expiry")}
                          </div>
                        </Col>
                        <Col md="6" lg="6">
                          <div className="form-group mb-3">
                            <label>
                              CVV
                              <span class="asterisk">*</span>
                            </label>
                            <div className="d-flex align-items-center position-relative">
                              <input
                                required={true}
                                type="password"
                                className="form-control pe-5"
                                name="card_cvv"
                                value={formData.card_cvv}
                                onChange={handleChange}
                                maxLength={4}
                              />
                              <div className="payment-formicon">
                                <span className="fa fa-key" aria-hidden="true" />
                              </div>
                            </div>
                            {showError("card_cvv")}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end gap-3">{showSubmitButton()}</div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Card>
      {show_delete_card_model ? (
        <DeleteCardModal
          onOpen={show_delete_card_model}
          onConfirm={handleDeleteCard}
          message="Are You sure you want to Delete this card? It will not auto renew permits."
          onClose={handleShowDeleteCardModal}
          title={"Delete Card"}
          isDeleteCarddisabled={isDeleteCard}
          loading={false}
        />
      ) : null}

      {api_success_message && <CustomContentAlert delay={4000} message={api_success_message} className="toast-success" />}
      {api_error_message && <CustomContentAlert delay={10000} message={api_error_message} className="toast-error" />}
    </>
  );
};
export default UpdateCreditCard;
