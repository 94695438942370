import { Translation } from "react-i18next";

export const isEmailValid = (email) => {
  const regExForEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regExForEmail.test(String(email).toLowerCase());
};

export const removeAccent = (data) => {
  return data?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const removeSpecialChar = (data) => {
  return data?.replace(/[^a-zA-Z0-9 ]/g, "");
};

export const regExForPhone = /^([+]?[ \d]+)?(\d{3}|[(]?\d+[)])?([- ]?\d)+$/i;

export const validations = (targetName, fieldNames, fieldValue) => {
  if (targetName === fieldNames && fieldValue.trim().length === 0) {
    return { value: "", validator: false };
  } else {
    return {
      value: fieldValue,
      validator: true,
    };
  }
};

export const getFinalUrlForParkingPermitAndCarwallet = () => {
  return window.location.origin
    .replace(/^https?:\/\//, "")
    .split(".")
    .slice(1)
    .join(".");
};

export const validation_message = (constName, msgName, validName, driverSide) => {
  if (driverSide) {
    const errorMsg = msgName + "Required.";
    return !constName && !validName ? (
      <div className="help-block">
        <Translation>{(t) => <>{t(errorMsg)}</>}</Translation>
      </div>
    ) : (
      <div></div>
    );
  } else {
    return !constName && !validName ? <div className="help-block">{`${msgName} is required`}</div> : <div></div>;
  }
};

// Check for an uppercase character
const checkUppercase = (password) => {
  const pattern = /[A-Z]/;
  return pattern.test(password);
};

const checkLowercase = (password) => {
  const pattern = /[a-z]/;
  return pattern.test(password);
};

const checkNumber = (password) => {
  const pattern = /\d/;
  return pattern.test(password);
};

const checkPasswordLength = (password) => {
  return password.length >= 8;
};

const checkSpecialCharacters = (password) => {
  const pattern = /[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]/;
  return pattern.test(password);
};

export const PasswordValidation = (password) => {
  let lengthValue = false;
  let special = false;
  let uppercase = false;
  let lowercase = false;
  let number = false;

  lengthValue = checkPasswordLength(password);
  special = checkSpecialCharacters(password);
  uppercase = checkUppercase(password);
  lowercase = checkLowercase(password);
  number = checkNumber(password);

  if (lengthValue && special && uppercase && lowercase && number) {
    return "";
  }
  return `Password Requirement  </br>
      <i ${lengthValue ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${lengthValue ? "color:green" : "color:red"}></i>
    minimum 8 characters </br>

    <i ${uppercase ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${uppercase ? "color:green" : "color:red"}></i>
    one uppercase </br>

    <i ${lowercase ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${lowercase ? "color:green" : "color:red"}></i>
    one lowercase </br>

    <i ${number ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${number ? "color:green" : "color:red"}></i>
    one number </br>

    <i ${special ? `class="fa fa-check-circle"` : `class="fa fa-times"`} aria-hidden="true" style=${special ? "color:green" : "color:red"}></i>
    one special character </br>`;
};
