import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { userLogin } from "../Actions/UserProfileActions/actions";
import { getLoginDetails } from "../selectors/LoginDetails";

const PrivateRoute = ({ children }) => {
  const basicAuth = JSON.parse(localStorage.getItem("driverbasicAuth"));
  const [auth, setAuth] = useState(true);
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      document.body.classList.remove("screen-zoomin-out");
    }
  });

  const dispatch = useDispatch();
  const getUserDetail = useSelector(getLoginDetails);
  useEffect(() => {
    if (
      getUserDetail?.login_status === false &&
      window.location.pathname !== "/login"
    ) {
      dispatch(userLogin(null, basicAuth, true));
    }
  }, [getUserDetail?.login_status]);

  useEffect(() => {
    if (
      (getUserDetail?.login_status === true &&
        !getUserDetail?.userProfileDetails &&
        !Object.keys(getUserDetail?.userProfileDetails).length &&
        !getUserDetail?.auth &&
        getUserDetail.userProfileDetails.user_type !== "driver") ||
      basicAuth === null
    ) {
      setAuth(false);
    } else {
      setAuth(true);
    }
  }, [getUserDetail?.login_status]);
  return auth && basicAuth ? children : <Navigate to="/signup" />;
};
export default PrivateRoute;
