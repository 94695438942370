import t from "../../Actions/ParkingPermitActions/types";
import moment from "moment";

const initialState = {
  currentPermits: [],
  driver_data: {},
  driver_stall_data: {},
  driver_last_permit: {},
  driver_credit_card: {},
  historyPermit: [],
  waitListPermit: [],
  userWaitPermitData: [],
  loader: false,
  ParkingPermitSetupDetail: {},
  getAllProperties: {},
  message: {
    isSuccess: false,
    message: "",
  },
};

const ParkingPermitReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_CURRENT_PERMIT: {
      return {
        ...state,
        currentPermits: action.payload,
      };
    }
    case t.UPDATE_CURRENT_PERMIT: {
      const oldCurrentPermits = state.currentPermits.data;
      const newCurrentPermits = oldCurrentPermits.filter((cp) => cp.id !== action.payload);
      return {
        ...state,
        currentPermits: {
          ...state.currentPermits,
          data: newCurrentPermits,
          count: state.currentPermits.count - 1,
        },
      };
    }

    case t.GETALLPROPERTY: {
      return Object.assign({}, state, {
        getAllProperties: action.payload,
      });
    }
    case t.CALL_WHEN_HERE_ACTIVATE_DEACTIVATE: {
      const datas = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload) {
          item.call_when_here = !item.call_when_here;
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
      };
    }
    case t.AUTO_RENEW_ACTIVATE_DEACTIVATE: {
      const datas = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload) {
          item.auto_renew = !item.auto_renew;
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
      };
    }
    case t.AUTO_CANCEL_ACTIVATE_DEACTIVATE: {
      const datas = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload) {
          item.auto_cancel = Number(!item.auto_cancel);
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
      };
    }
    case t.UPDATE_PERMIT: {
      const datas = JSON.parse(JSON.stringify(state.currentPermits.data));
      const newMappedArray = datas.map((item) => {
        if (item.id === action.payload.id) {
          item.card_number = action.payload.card_number;
          item.phone = action.payload.phone;
          item.email = action.payload.email;
          item.first_name = action.payload.first_name;
          item.last_name = action.payload.last_name;
          item.expire_time = moment(action.payload.expire_time).format("lll");
        }
        return item;
      });
      return {
        ...state,
        currentPermits: { ...state.currentPermits, data: newMappedArray },
        updatedPermit: action.payload,
      };
    }
    case t.UPDATE_PERMIT_SUCCESS: {
      return {
        ...state,
        message: {
          isSuccess: true,
          message: action.payload,
        },
      };
    }
    case t.UPDATE_PERMIT_ERROR: {
      return {
        ...state,
        message: {
          isSuccess: false,
          message: action.payload,
        },
      };
    }

    case t.FETCH_DRIVER_LOT: {
      return {
        ...state,
        driver_data: action.payload,
      };
    }
    case t.FETCH_DRIVER_STALL: {
      return {
        ...state,
        driver_stall_data: action.payload.data,
      };
    }
    case t.FETCH_DRIVER_PACKAGE: {
      return {
        ...state,
        driver_package: action.payload,
      };
    }
    case t.FETCH_PARKING_PERMIT_SETTING_DETAILS: {
      return {
        ...state,
        ParkingPermitSetupDetail: action.payload,
      };
    }
    case t.FETCH_PARKING_PERMIT_SETTING_DETAILS_BLANK: {
      return {
        ...state,
        ParkingPermitSetupDetail: {},
      };
    }
    case t.FETCH_DRIVER_CARD_DETAIL: {
      return {
        ...state,
        driver_credit_card: action.payload,
      };
    }
    case t.USER_LOGIN: {
      return {
        ...state,
        driver_last_permit: action.payload,
      };
    }
    case t.GET_PAID_PARMIT_HISTORY: {
      return { ...state, historyPermit: action.payload, loader: false };
    }
    case t.GET_WAITLIST_PERMITS: {
      return { ...state, waitListPermit: action.payload, loader: false };
    }
    case t.GET_USER_WAITLIST_PRIORITY: {
      return { ...state, userWaitPermitData: action.payload, loader: false };
    }
    case t.SHOW_HIDE_LOADER: {
      return { ...state, loader: action.payload };
    }

    default:
      return state;
  }
};

export default ParkingPermitReducer;
