import React from "react";
import { Button, Modal, Form, ModalBody, Card, Row, Col } from "react-bootstrap";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import CustomContentAlert from "../Helper/DesignHelper";
import t from "../../Actions/ParkingPermitActions/types";
import moment from "moment";

import { updatePermit } from "../../Actions/ParkingPermitActions/actions";
import { getUserProfileDetails } from "../../selectors/LoginDetails";
import { getParkingPermitDetail } from "../../selectors/ParkingPermitDetails";
const EditCurrentPermit = ({ closeModal, permitData, modelOpen, status, PageObject }) => {
  console.log("EditCurrentPermit::::::::::", closeModal, permitData, modelOpen, status, PageObject);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [parkingPermitDisbaleFields, setParkingPermitDisbaleFields] = React.useState(false);
  const [lpn_btn_status, setLpn_btn_status] = React.useState(true);

  const [state, setState] = React.useState({
    email: "",
    lpn: "",
    lot: "",
    FirstName: "",
    LastName: "",
    country_code: "",
    phone: "",
    permit_end_date: "",
    card_number: "",
    employee_reference: "",
    stall_id: "",
    country_code_list: [
      { id: 1, text: "+1", value: "+1" },
      { id: 2, text: "+91", value: "+91" },
      { id: 3, text: "+44", value: "+44" },
      { id: 4, text: "+61", value: "+61" },
    ],
    phone_options: [],
    deleted_phone_id: [],
    permit_class: "",
    lpn_options: [],
    groupid: "",
    group_type: "",
    primary_permit: 0,
    group_permit_count: 0,
  });

  const dispatch = useDispatch();
  const [validation1, setValidation1] = React.useState({
    isemail: false,
    isfirstName: false,
    islastName: false,
    isLPN: false,
    isphone: false,
    disabledDate: true,
  });
  const [validation, setValidation] = React.useState({
    email: "",
    FirstName: "",
    LastName: "",
    phone: "",
    userType: "",
    lpn: "",
  });

  const { message: apiMessage } = useSelector(getParkingPermitDetail);
  console.log("apiMessage:::::::::::", apiMessage);

  const UserProfileDetails = useSelector(getUserProfileDetails);
  console.log("UserProfileDetails::::111111111", UserProfileDetails);
  React.useEffect(() => {
    let timeout = null;
    if (apiMessage?.isSuccess && apiMessage?.message !== "") {
      timeout = setTimeout(() => {
        closeModal(false);
      }, 1000);
    }
    return () => {
      if (timeout !== null) clearTimeout(timeout);
    };
  }, [dispatch, apiMessage]);

  React.useEffect(() => {
    if (modelOpen) {
      dispatch({
        type: t.UPDATE_PERMIT_ERROR,
        payload: "",
      });
    }
  }, [modelOpen]);

  React.useEffect(() => {
    if (permitData.paid_permit_option_id || permitData.paid_permit_type) {
      setParkingPermitDisbaleFields(true);
    }
  }, []);

  React.useEffect(() => {
    if (!validation1.isemail && !validation1.isfirstName && !validation1.islastName && !validation1.isLPN && !validation1.isphone && isMultiplePhoneValid()) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [validation1, state.phone_options]);

  React.useEffect(() => {
    if ((!state.primary_permit && state.groupid && state.group_type === "system") || state?.group_permit_count >= 2) {
      setLpn_btn_status(true);
    } else if (state.lpn.toString().length > 0 && state.lpn_options.length === 0) {
      setLpn_btn_status(false);
    } else if (state.lpn_options.length === 1) {
      if (state.lpn_options[0]?.lpn?.toString().length > 0) {
        if (state?.group_permit_count === 1 && state.group_type === "system") {
          setLpn_btn_status(true);
        } else {
          setLpn_btn_status(false);
        }
      } else {
        setLpn_btn_status(true);
      }
    } else {
      setLpn_btn_status(true);
    }
  }, [state.lpn, state.lpn_options]);

  React.useEffect(() => {
    if (permitData) {
      const {
        email,
        lot_name,
        country_code,
        phone,
        first_name,
        last_name,
        lpn,
        begin_time,
        expire_time,
        card_number,
        owner_reference,
        stall_id,
        phone_options,
        permit_class,
        groupid,
        permit_share_group,
        primary_permit,
        lpn_options,
      } = permitData;
      setState({
        ...state,
        email: email,
        stall_id: stall_id,
        lpn: lpn,
        lot: lot_name,
        FirstName: first_name,
        LastName: last_name,
        card_number: card_number,
        employee_reference: owner_reference,
        country_code: country_code,
        phone: phone,
        permit_start_date: begin_time,
        permit_end_date: expire_time,
        country_code_list: [
          { id: 1, text: "+1", value: "+1" },
          { id: 2, text: "+91", value: "+91" },
          { id: 3, text: "+44", value: "+44" },
          { id: 4, text: "+61", value: "+61" },
        ],
        phone_options: phone_options,
        permit_class: permit_class,
        groupid: groupid,
        group_type: permit_share_group?.group_type,
        primary_permit: primary_permit,
        group_permit_count: lpn_options?.length,
      });
      const expire_date = moment(expire_time).format("YYYY-MM-DD HH:mm:SS");
      const convertedExpireTime = moment.tz(expire_date, UserProfileDetails.timezone).format();
      const currentDate = new Date().toLocaleString("en-US", {
        timeZone: UserProfileDetails.timezone,
      });
      const formatedCurrentTime = moment(currentDate).format("YYYY-MM-DD HH:mm:SS");
      const convertedCurrentTime = moment.tz(formatedCurrentTime, UserProfileDetails.timezone).format();
      if (convertedExpireTime > convertedCurrentTime) {
        setState((prevState) => ({
          ...prevState,
          disabledDate: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          disabledDate: false,
        }));
      }
    }
  }, [permitData]);

  const PhoneValidation = (phone) => {
    if (phone?.length >= 11 || phone?.length < 10) {
      setIsFormValid(true);
      return "Please  enter valid Phone Number";
    } else if (!/^\d+$/.test(phone)) {
      return "Please  enter valid Phone Number";
    } else {
      setIsFormValid(false);
      return "";
    }
  };

  const isMultiplePhoneValid = () => {
    // WORLD-1128
    const phoneListCount = {};
    const phoneList = state.phone_options.map((item) => {
      return item.country_code + item.phone;
    });
    phoneList.push(state.country_code + state.phone);
    phoneList.forEach(function (x) {
      phoneListCount[x] = (phoneListCount[x] || 0) + 1;
    });
    const phoneListStatus = Object.keys(phoneListCount).filter((item) => phoneListCount[item] > 1);
    const validPhone = state.phone_options.map((options) => {
      if (options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length) {
        return !(options.phone.trim().length > 10 || options.phone.trim().length < 10 || options.phone.trim().length === 0 || phoneListStatus.length);
      }
      return true;
    });
    // WORLD-1128
    console.log("validPhone:::", validPhone);
    return !validPhone.includes(false);
  };

  const permitUpdate = () => {
    const {
      email,
      country_code,
      phone,
      FirstName,
      LastName,
      permit_end_date,
      card_number,
      employee_reference,
      stall_id,
      phone_options,
      deleted_phone_id,
      lpn,
      lpn_options,
      groupid,
    } = state;
    permitData.lpn_options = [];
    const expire_date = moment(permit_end_date).format("YYYY-MM-DD 23:59:59");
    const convertedExpireTime = moment.tz(expire_date, UserProfileDetails.timezone).format();
    const UTCET = moment.tz(convertedExpireTime, "UTC").format();
    const data = {
      email,
      country_code,
      phone,
      first_name: FirstName,
      last_name: LastName,
      expire_time: UTCET,
      card_number: card_number,
      owner_reference: employee_reference,
      stall_id: stall_id,
      old_data: permitData,
      status,
      phone_options,
      deleted_phone_id,
      lpn,
      lpn_options,
      groupid,
    };
    dispatch(updatePermit(permitData.id, data, PageObject));
  };

  const handleAddPhoneOptions = () => {
    setState({
      ...state,
      phone_options: [...state.phone_options, { country_code: "+1", phone: "" }],
    });

    console.log("state.phone_options:::", state.phone_options);
  };

  const handlePhoneOptions = (index, value, type) => {
    const { phone_options } = state;
    let phone_values;
    let country_code_values;

    setState({
      ...state,
      phone_options: state.phone_options.map((item, i) => {
        const obj = { ...item };
        if (i === index) {
          if (type === "phone_number_options") {
            phone_values = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
            obj.phone = phone_values;
          }
          if (type === "country_code_options") {
            country_code_values = value;
            obj.country_code = country_code_values;
          }
        }
        return obj;
      }),
    });

    console.log("phone_options::::", phone_options);
  };

  const removePhoneOption = (index) => {
    const deleted_ID = state.phone_options[index];
    setState({
      ...state,
      phone_options: [...state.phone_options.filter((_x, i) => i !== index)],
      deleted_phone_id: [...state.deleted_phone_id, deleted_ID],
    });
  };

  const handleChange = React.useCallback(
    (e) => {
      let {
        target: { name, value },
      } = e;
      console.log("e.target.name:::", name, value, state);

      let Obj = {};

      if (name === "lpn") {
        value = value.toUpperCase().replace(/[^a-zA-Z0-9]/g, "");
      }
      if (name === "country_code" || name === "phone" || name === "card_number") {
        value = value.replace(/[a-zA-Z]/, "");
      } else {
        setValidations(e);
      }

      Obj = { ...state, [name]: value };
      setState(Obj);
      checkLpnValidation(Obj);
    },
    [state],
  );

  const setValidations = React.useCallback(
    (e) => {
      if (!e.target.value) {
        setValidation((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.name + " is required",
        }));
        setIsFormValid(false);
        if (e.target.name === "card_number" || e.target.name === "employee_reference") {
          setIsFormValid(true);
        }
      } else {
        if (e.target.name === "lpn") {
          setValidation1((prevState) => ({
            ...prevState,
            isLPN: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "LastName") {
          setValidation1((prevState) => ({
            ...prevState,
            islastName: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "FirstName") {
          setValidation1((prevState) => ({
            ...prevState,
            isfirstName: false,
          }));
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        } else if (e.target.name === "email") {
          const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value && !regex.test(e.target.value) ? "Please enter valid email" : "",
          }));
          if (e.target.value && regex.test(e.target.value)) {
            setValidation1((prevState) => ({
              ...prevState,
              isemail: false,
            }));
          } else {
            setValidation1((prevState) => ({
              ...prevState,
              isemail: true,
            }));
          }
        } else if (e.target.name === "phone") {
          const errMsg = PhoneValidation(e.target.value);
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: errMsg,
          }));

          if (e.target.value && errMsg === "") {
            setValidation1((prevState) => ({
              ...prevState,
              isphone: false,
            }));
          } else {
            setValidation1((prevState) => ({
              ...prevState,
              isphone: true,
            }));
          }
        } else {
          setValidation((prevState) => ({
            ...prevState,
            [e.target.name]: "",
          }));
        }
      }
    },
    [validation],
  );

  const handleAddLpnCarDescription = () => {
    const mainObj = {
      ...state,
      lpn_options: [...state.lpn_options, { lpn: "" }],
    };
    setState(mainObj);
    checkLpnValidation(mainObj);
  };

  const handleLpnCarOptions = (index, e, type) => {
    const {
      target: { value },
      //  target,
    } = e;
    const { lpn_options } = state;
    if (lpn_options.length < 2) {
      setLpn_btn_status(false);
    }
    let lpn_values;
    const mainObj = {
      ...state,
      lpn_options: lpn_options.map((item, i) => {
        const obj = { ...item };
        if (i === index && type === "lpn_options") {
          lpn_values = value.toUpperCase().replace(/\s/g, "");
          obj.lpn = lpn_values;
        }
        return obj;
      }),
    };

    const obj = { ...state, ...mainObj };

    setState(obj);

    checkLpnValidation(obj);
  };

  const removeLpnCarOption = (index) => {
    const mainObj = {
      ...state,
      lpn_options: [...state.lpn_options.filter((_x, i) => i !== index)],
    };
    setState(mainObj);
    checkLpnValidation(mainObj);
  };

  const checkLpnValidation = React.useCallback(
    (mainObj) => {
      const allLpn = [mainObj.lpn, ...mainObj.lpn_options.map((item) => item.lpn)];

      const checkValueBlank = allLpn.filter((item) => item === "");
      if (checkValueBlank.length === 0 && allLpn.length <= 2) {
        setLpn_btn_status(false);
      } else {
        setLpn_btn_status(true);
      }

      const duplicateLPN = allLpn.filter((item, index) => allLpn.indexOf(item) !== index);
      if (duplicateLPN.length >= 1) {
        setValidation((prevState) => ({
          ...prevState,
          lpn: "Duplicate Plate Number Exists",
        }));
      } else {
        setValidation((prevState) => ({
          ...prevState,
          lpn: "",
        }));
      }
    },
    [state.lpn, state.lpn_options],
  );

  const handleChangeEndingDates = (date) => {
    if (date) {
      date = moment(date).format("L");
    } else {
      date = moment().format("L");
    }
    setState((prevState) => ({
      ...prevState,
      permit_end_date: date,
      disabledDate: true,
    }));
  };

  return (
    <Modal id="ImportPermits" lg={"medium"} backdrop={"static"} show={true} onHide={closeModal} dialogClassName="modal-90w" fullscreen={"sm-down"}>
      <Modal.Header className="d-flex justify-content-between bg-white align-items-center">
        <h4 className="m-0 font-weight-bold">
          <Card.Title className="m-0">Edit Permits</Card.Title>
        </h4>
        <Button title={"Deactivate"} type="button" onClick={closeModal} className="close-btn py-0" data-dismiss="modal">
          <i className="nc-icon nc-simple-remove text-danger" />
        </Button>
      </Modal.Header>

      <ModalBody>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Licence Plate No.</Form.Label>
              <Form.Control
                placeholder="LPN"
                type="text"
                name="lpn"
                value={state?.lpn}
                autoComplete="off"
                onChange={handleChange}
                maxLength="11"
                disabled={!(state && state.permit_class === "PermitPackage")}
              />
              <div className="help-block">{!_.isEmpty(validation) ? validation.lpn : ""}</div>
            </Col>
            <Col className="col-auto pl-0">
              <div>
                <label>&nbsp;</label>
              </div>
              <Button size="sm" className="h-40px px-1" disabled={lpn_btn_status} onClick={handleAddLpnCarDescription}>
                Add Plate
              </Button>
            </Col>
          </Row>
          {state.lpn_options &&
            typeof state.lpn_options === "object" &&
            Array.isArray(state.lpn_options) &&
            state.lpn_options.map((option, index) => {
              return (
                <Row key={option?.id} className="">
                  <Col className="">
                    <Form.Group>
                      <Form.Label>Licence Plate No. {index + 1}</Form.Label>
                      <span className="asterisk">*</span>
                      <Form.Control
                        type="text"
                        name="lpn_options"
                        onChange={(e) => handleLpnCarOptions(index, e, "lpn_options")}
                        value={option?.lpn}
                        maxLength="11"
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col-auto pl-0">
                    <div>
                      <label>&nbsp;</label>
                    </div>
                    <Button size="sm" className="h-40px px-1" onClick={() => removeLpnCarOption(index)}>
                      Remove Plate
                    </Button>
                  </Col>
                  <br />
                </Row>
              );
            })}
          <div className="mb-2">
            <label className="mb-1">Lot</label>
            <Form.Control placeholder="phone" type="text" name="lot" value={state?.lot} autoComplete="off" disabled />
          </div>
          <div className="mb-2">
            <label className="mb-1">
              Email <span className="asterisk">*</span>
            </label>
            <Form.Control
              placeholder="email"
              type="email"
              name="email"
              value={state?.email}
              onChange={handleChange}
              autoComplete="off"
              required
              disabled={parkingPermitDisbaleFields}
            />
            <div className="help-block">{!_.isEmpty(validation) ? validation.email : ""}</div>
          </div>
          <div className="mb-2">
            <label className="mb-1">
              First Name <span className="asterisk">*</span>
            </label>
            <Form.Control placeholder="First Name" type="text" name="FirstName" value={state?.FirstName} onChange={handleChange} autoComplete="off" required />
            <div className="help-block">{!_.isEmpty(validation) ? validation.FirstName : ""}</div>
          </div>
          <div className="mb-2">
            <label className="mb-1">
              Last Name <span className="asterisk">*</span>
            </label>
            <Form.Control placeholder="Last Name" type="text" name="LastName" value={state?.LastName} onChange={handleChange} autoComplete="off" required />
            <div className="help-block">{!_.isEmpty(validation) ? validation.LastName : ""}</div>
          </div>
          <div className="mb-2">
            <label className="mb-1">Access Card/ Tag</label>
            <Form.Control
              placeholder="Card Number"
              type="text"
              name="card_number"
              value={state?.card_number}
              onChange={handleChange}
              autoComplete="off"
              required
            />
          </div>
          <div className="mb-2">
            <label className="mb-1">Employee Reference</label>
            <Form.Control
              placeholder="Employee Reference"
              type="text"
              name="employee_reference"
              value={state?.employee_reference}
              onChange={handleChange}
              autoComplete="off"
              required
            />
          </div>
          <div className="mb-2">
            <div className="custom-time d-flex align-items-center">
              <div className="d-flex align-items-center sm-flex-column">
                <div className="">
                  <label className="ml-2 mr-1">
                    End Date {state.disabledDate}
                    <span className="asterisk">*</span>
                  </label>
                  <DatePicker
                    placeholder="04/05/16"
                    minDate={new Date()}
                    selected={state.permit_end_date ? new Date(state.permit_end_date) : new Date()}
                    disabled={!state.disabledDate || parkingPermitDisbaleFields}
                    onChange={handleChangeEndingDates}
                  />

                  <div className="help-block">{state.disabledDate === false ? "This date is expired so you can not edit." : ""}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto mb-3">
            <label className="mb-1">Country Code</label>
            <Form.Control className="w-85px" as="select" name="country_code" onChange={handleChange} value={state?.country_code} required>
              {state.country_code_list ? (
                <>
                  {state.country_code_list.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </>
              ) : null}
            </Form.Control>
          </div>
          <div className="col p-0 mb-3">
            {" "}
            <label className="mb-1">
              Phone <span className="asterisk">*</span>
            </label>
            <Form.Control
              placeholder="phone"
              type="text"
              name="phone"
              value={state?.phone}
              onChange={handleChange}
              autoComplete="off"
              maxLength="10"
              required
            />
            <div className="help-block">{!_.isEmpty(validation) ? validation.phone : ""}</div>
          </div>
          <div className="col-auto mb-3">
            <Button
              size="sm"
              className="h-40px"
              disabled={state.phone_options?.length > 9 || state?.phone?.trim()?.length === 0 || !isMultiplePhoneValid()}
              onClick={handleAddPhoneOptions}
            >
              <i className="fas fa-plus-circle"></i>
            </Button>
          </div>
          {state.phone_options &&
            typeof state.phone_options === "object" &&
            Array.isArray(state.phone_options) &&
            state.phone_options.map((option, index) => {
              return (
                <Row key={option?.country_code} className="my-0 mt-2">
                  <Col className="col-auto">
                    <Form.Group>
                      <Form.Control
                        className="w-85px"
                        as="select"
                        name="country_code_options"
                        onChange={(e) => handlePhoneOptions(index, e.target.value, "country_code_options")}
                        value={option?.country_code}
                        required
                      >
                        {state.country_code_list ? (
                          <>
                            {state.country_code_list.map((item) => (
                              <option key={item.id} value={item.value}>
                                {item.text}
                              </option>
                            ))}
                          </>
                        ) : null}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col p-0">
                    <Form.Group className="p-0">
                      <Form.Control
                        placeholder="Phone Number"
                        type="text"
                        name="phone_number_options"
                        value={option?.phone}
                        onChange={(e) => handlePhoneOptions(index, e.target.value, "phone_number_options")}
                        maxLength="10"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-auto">
                    <Button size="sm" className="h-40px" onClick={() => removePhoneOption(index)}>
                      <i className="fas fa-minus"></i>
                    </Button>
                  </Col>
                </Row>
              );
            })}
        </Form.Group>
      </ModalBody>

      <Modal.Footer className="justify-content-between">
        <Button onClick={closeModal}>Cancel</Button>
        <Button variant="primary" disabled={!isFormValid} onClick={permitUpdate}>
          Update
        </Button>
      </Modal.Footer>
      {apiMessage?.message !== "" && (
        <CustomContentAlert delay={4000} message={apiMessage.message} className={apiMessage.isSuccess ? "toast-success" : "toast-error"} />
      )}
    </Modal>
  );
};

export default EditCurrentPermit;
